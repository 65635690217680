export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect class="cls-1" width="1000" height="1000"/>
  <path class="cls-2" d="M319.11,83.68h20.38c13.86,0,18.92,6.87,18.92,18.34v17.24c0,11.47-5.06,18.46-18.92,18.46h-6.63v27.74H319.11Zm13.75,11.21V127.1h5.05c4.71,0,6.74-2.29,6.74-6.76V101.53c0-4.45-2-6.64-6.74-6.64Z"/>
  <path class="cls-2" d="M360.1,165.46l15.44-81.78H391.2l15.54,81.78H392.65l-2.53-15H375.54l-2.42,15Zm17.25-25.94h11l-5.43-32.81h-.12Z"/>
  <path class="cls-2" d="M427.38,128v37.51H413.64V83.68h20c13.86,0,18.92,6.87,18.92,18.34v10.49c0,8.8-3.13,14-11,15.8l13.14,37.15H440.25Zm0-33.06v25.45H432c4.71,0,6.76-2.41,6.76-6.75V101.53c0-4.45-2-6.64-6.76-6.64Z"/>
  <polygon class="cls-2" points="457.46 83.68 493.99 83.68 493.99 94.89 482.65 94.89 482.65 165.46 468.79 165.46 468.79 94.89 457.46 94.89 457.46 83.68"/>
  <polygon class="cls-2" points="514.27 114.08 514.27 165.46 502.7 165.46 502.7 83.68 516.18 83.68 531.03 130.59 531.03 83.68 542.47 83.68 542.47 165.46 530.42 165.46 514.27 114.08"/>
  <polygon class="cls-2" points="553.76 83.68 586.54 83.68 586.54 94.89 567.51 94.89 567.51 118.29 581.13 118.29 581.13 129.4 567.51 129.4 567.51 154.24 586.54 154.24 586.54 165.46 553.76 165.46 553.76 83.68"/>
  <path class="cls-2" d="M608.85,128v37.51H595.11V83.68h20c13.88,0,18.93,6.87,18.93,18.34v10.49c0,8.8-3.14,14-11,15.8l13.14,37.15H621.74Zm0-33.06v25.45h4.59c4.7,0,6.75-2.41,6.75-6.75V101.53c0-4.45-2.05-6.64-6.75-6.64Z"/>
  <path class="cls-2" d="M640.9,147.84v-11h12.79v11.21c0,4.59,2,7.24,6.73,7.24,4.35,0,6.27-2.9,6.27-7.24V145c0-4.71-1.92-7.61-6.16-11.59l-8.06-8.09c-7.84-7.7-11.45-12.42-11.45-21.81v-2.78c0-10.14,5.91-18,19.28-18,13.74,0,19.18,6.75,19.18,18.58v6.63H666.93v-7.11c0-4.72-2.16-7-6.51-7-3.86,0-6.5,2.18-6.5,6.76v1.69c0,4.59,2.54,7.23,6.5,11.09l8.8,8.68c7.48,7.6,11.08,12.18,11.08,21.23v4c0,11.23-6,19.17-20,19.17s-19.4-7.83-19.4-18.57"/>
  <polygon class="cls-2" points="715.77 129.4 702.52 129.4 702.52 165.46 688.76 165.46 688.76 83.68 702.52 83.68 702.52 118.31 715.77 118.31 715.77 83.68 729.62 83.68 729.62 165.46 715.77 165.46 715.77 129.4"/>
  <rect class="cls-2" x="738.68" y="83.68" width="13.74" height="81.77"/>
  <path class="cls-2" d="M761.58,83.68H782c13.87,0,18.93,6.87,18.93,18.34v17.24c0,11.47-5.06,18.46-18.93,18.46h-6.62v27.74H761.58Zm13.75,11.21V127.1h5c4.71,0,6.76-2.29,6.76-6.76V101.53c0-4.45-2-6.64-6.76-6.64Z"/>
  <path class="cls-2" d="M806.25,147.84v-11H819v11.21c0,4.59,2.05,7.24,6.76,7.24,4.33,0,6.27-2.9,6.27-7.24V145c0-4.71-1.94-7.61-6.15-11.59l-8.08-8.09c-7.83-7.7-11.45-12.42-11.45-21.81v-2.78c0-10.14,5.92-18,19.28-18,13.75,0,19.16,6.75,19.16,18.58v6.63H832.29v-7.11c0-4.72-2.18-7-6.51-7-3.86,0-6.5,2.18-6.5,6.76v1.69c0,4.59,2.51,7.23,6.5,11.09l8.8,8.68c7.47,7.6,11.08,12.18,11.08,21.23v4c0,11.23-6,19.17-20,19.17s-19.4-7.83-19.4-18.57"/>
  <polygon class="cls-2" points="149.63 83.81 149.63 285.42 115.73 285.42 115.73 131.09 115.43 131.09 91.64 155.77 91.35 155.77 91.35 120.38 122.58 83.81 149.63 83.81"/>
  <polygon class="cls-2" points="165.1 83.81 250.16 83.81 250.16 115.03 205.84 285.42 172.22 285.42 217.43 111.46 165.1 111.46 165.1 83.81"/>
  <polygon class="cls-2" points="319.13 202.46 351.83 202.46 351.83 213.84 333.09 213.84 333.09 237.6 347.05 237.6 347.05 248.85 333.09 248.85 333.09 285.46 319.13 285.46 319.13 202.46"/>
  <path class="cls-2" d="M356.72,264V223.88c0-13,6.36-22.4,21-22.4S399,210.91,399,223.88V264c0,12.85-6.36,22.4-21.18,22.4s-21-9.55-21-22.4m28.27,2.2V221.68c0-5-2.2-8.57-7.22-8.57s-7.09,3.55-7.09,8.57v44.56c0,5,2.19,8.57,7.09,8.57s7.22-3.55,7.22-8.57"/>
  <path class="cls-2" d="M422,247.39v38.07H408v-83h20.32c14.08,0,19.23,7,19.23,18.61v10.65c0,8.93-3.19,14.2-11.14,16l13.33,37.71H435.07Zm0-33.55v25.84h4.65c4.77,0,6.86-2.45,6.86-6.86V220.57c0-4.53-2.09-6.73-6.86-6.73Z"/>
  <polygon class="cls-2" points="469.63 202.46 506.72 202.46 506.72 213.84 495.22 213.84 495.22 285.46 481.14 285.46 481.14 213.84 469.63 213.84 469.63 202.46"/>
  <polygon class="cls-2" points="540.63 248.85 527.16 248.85 527.16 285.46 513.21 285.46 513.21 202.46 527.16 202.46 527.16 237.6 540.63 237.6 540.63 202.46 554.71 202.46 554.71 285.46 540.63 285.46 540.63 248.85"/>
  <polygon class="cls-2" points="563.79 202.46 597.09 202.46 597.09 213.84 577.75 213.84 577.75 237.6 591.58 237.6 591.58 248.85 577.75 248.85 577.75 274.08 597.09 274.08 597.09 285.46 563.79 285.46 563.79 202.46"/>
  <path class="cls-2" d="M641.68,241.14h19.83v44.32h-10V276.4c-1.83,6.25-5.75,10-13.34,10-12,0-17.51-9.55-17.51-22.4V223.88c0-13,6.24-22.4,20.94-22.4,15.42,0,20,8.57,20,20.81v7.34H648.78v-8.44c0-5.14-2-8.08-7-8.08s-7.22,3.55-7.22,8.57v44.56c0,5,2.07,8.57,6.86,8.57,4.52,0,6.85-2.58,6.85-8V252.16h-6.61Z"/>
  <path class="cls-2" d="M669.47,264V223.88c0-13,6.36-22.4,21-22.4s21.19,9.43,21.19,22.4V264c0,12.85-6.37,22.4-21.19,22.4s-21-9.55-21-22.4m28.28,2.2V221.68c0-5-2.21-8.57-7.23-8.57s-7.09,3.55-7.09,8.57v44.56c0,5,2.2,8.57,7.09,8.57s7.23-3.55,7.23-8.57"/>
  <path class="cls-2" d="M715.62,285.46l15.67-83h15.92l15.79,83H748.67l-2.56-15.19H731.29l-2.44,15.19Zm17.5-26.32h11.15l-5.52-33.3h-.11Z"/>
  <polygon class="cls-2" points="770.99 202.46 784.95 202.46 784.95 274.08 801.59 274.08 801.59 285.46 770.99 285.46 770.99 202.46"/>
  <path class="cls-2" d="M806.6,267.59V256.45h13v11.38c0,4.65,2.09,7.35,6.85,7.35,4.42,0,6.38-2.95,6.38-7.35v-3.18c0-4.78-2-7.71-6.25-11.76l-8.19-8.19c-8-7.84-11.63-12.61-11.63-22.17v-2.81c0-10.28,6-18.24,19.58-18.24,14,0,19.47,6.85,19.47,18.85v6.73H833.05v-7.21c0-4.78-2.21-7.11-6.63-7.11-3.91,0-6.6,2.21-6.6,6.86v1.71c0,4.65,2.58,7.35,6.6,11.27l8.94,8.81c7.6,7.71,11.28,12.36,11.28,21.54v4c0,11.39-6.13,19.47-20.33,19.47s-19.71-8-19.71-18.85"/>
  <path class="cls-2" d="M748.26,606.48c0-70.23-55.87-127.6-125.47-130.14a130.23,130.23,0,0,0-245.61,0c-69.59,2.54-125.44,59.92-125.44,130.14A130,130,0,0,0,302,709.14a130.4,130.4,0,0,0-5.14,36.16c0,71.78,58.43,130.21,130.26,130.21a129.47,129.47,0,0,0,73.5-22.8,129.52,129.52,0,0,0,72.26,21.94c71.82,0,130.25-58.43,130.25-130.22A129.92,129.92,0,0,0,698.2,709a130.08,130.08,0,0,0,50.06-102.51M604,519.7c0,52.14-38.59,95.3-88.68,102.76a102.74,102.74,0,0,1-1.36-16,104.06,104.06,0,0,1,88.68-102.76,103.77,103.77,0,0,1,1.36,16M500.47,818.93a103.73,103.73,0,0,1-1-148.17,103.7,103.7,0,0,1,1,148.17M397.33,503.71C447.42,511.16,486,554.33,486,606.48a102.74,102.74,0,0,1-1.37,16C434.56,615,396,571.84,396,519.7a104,104,0,0,1,1.36-16M474.89,653a103.63,103.63,0,0,1-140.68,45.76A103.63,103.63,0,0,1,474.89,653m49.87-.7a103.67,103.67,0,0,1,141.37,46.3,103.67,103.67,0,0,1-141.37-46.3M500,415.69a104.13,104.13,0,0,1,95.35,62.54A130.71,130.71,0,0,0,500,551.42a130.61,130.61,0,0,0-95.35-73.19A104.14,104.14,0,0,1,500,415.69M278,606.48a104.17,104.17,0,0,1,92.87-103.42A129.57,129.57,0,0,0,412.3,615.92a130.49,130.49,0,0,0-99.87,67.67A103.68,103.68,0,0,1,278,606.48M323.09,745.3a103.41,103.41,0,0,1,2.27-21.56,130,130,0,0,0,119.52-3.27A129.64,129.64,0,0,0,479.45,835,103.83,103.83,0,0,1,323.09,745.3m353.82-.87a103.85,103.85,0,0,1-155.32,90.35,129.67,129.67,0,0,0,33.34-114.41,129.92,129.92,0,0,0,119.87,3.27,103.39,103.39,0,0,1,2.11,20.79M588.49,615.15a129.9,129.9,0,0,0,41.75-95.45,132.61,132.61,0,0,0-1.09-16.64,103.83,103.83,0,0,1,58.7,180.26,130.5,130.5,0,0,0-99.36-68.17"/>
</svg>`;
