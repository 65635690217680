export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect id="_Path_" data-name="&lt;Path&gt;" class="cls-1" y="-0.02" width="1000" height="1000.04"/>
  <path id="_Path_2" data-name="&lt;Path&gt;" class="cls-2" d="M187,257.76H131.41v-9.52c0-15.75,5.65-21.7,14.27-30.92l21.7-22.9c17.55-18.73,21.42-30.63,21.42-50.86V123.94c0-28.84-14-42.53-44.31-42.53-29.73,0-44.91,15.76-44.91,44V149.8h30.94V124.52c0-12.18,5.94-15.76,13.09-15.76,6.53,0,12.18,2.69,12.18,15.18v16.65c0,16.66-1.49,23.2-12.49,34.79l-19,20.22C105.84,215.24,99,228.33,99,248.24v37.18h88Z"/>
  <g>
    <path id="_Path_3" data-name="&lt;Path&gt;" class="cls-2" d="M413.12,849.72v14.07a1.54,1.54,0,0,0-.05.31,1.63,1.63,0,0,0,.05.33,6.6,6.6,0,0,0,6.48,6.32s.08,0,.13,0l.16,0h165s.09,0,.14,0l.15,0a6.63,6.63,0,0,0,6.5-6.6s0,0,0,0a.07.07,0,0,1,0-.05V848.14c92.86-30.44,162.43-105.7,177.73-197.42,0-.2.06-.38.09-.55a5.85,5.85,0,0,0,.09-.92,6.66,6.66,0,0,0-6.67-6.67H237.14a6.67,6.67,0,0,0-6.69,6.67l.35,3c16.07,92.59,87.54,168.27,182.32,197.47"/>
    <path id="_Path_4" data-name="&lt;Path&gt;" class="cls-2" d="M591.49,418.2c-2.17,3.08-53,76.21,1.13,135.34,24.81,27.14,22.66,51.08,15.58,67.25h34.7c7-23.87,4.12-53.82-24-84.6-39.86-43.58.51-102.47,1.12-103.38,4.76-6.72,2.21-15.38-5.63-19.42s-18.07-1.87-22.85,4.81"/>
    <path id="_Path_5" data-name="&lt;Path&gt;" class="cls-2" d="M503.61,620.79h34.71c7-23.88,4.11-53.82-24.06-84.6-39.88-43.58.46-102.45,1.1-103.41,4.73-6.69,2.23-15.34-5.64-19.39s-18.06-1.88-22.82,4.81c-2.18,3.07-53,76.21,1.13,135.34,24.81,27.14,22.64,51.09,15.58,67.25"/>
    <path id="_Path_6" data-name="&lt;Path&gt;" class="cls-2" d="M382.29,418.2c-2.2,3.08-53,76.21,1.12,135.34,24.82,27.15,22.67,51.09,15.6,67.25h34.7c7-23.86,4.12-53.81-24.08-84.6-39.87-43.58.5-102.47,1.14-103.4,4.71-6.7,2.2-15.36-5.67-19.4s-18.05-1.88-22.81,4.81"/>
  </g>
  <g id="_Group_" data-name="&lt;Group&gt;">
    <path id="_Compound_Path_" data-name="&lt;Compound Path&gt;" class="cls-2" d="M299.06,154.31h20.85v11.21h-35.2V154.31L306.29,95H286.88V83.79h33.88V95Z"/>
    <path id="_Compound_Path_2" data-name="&lt;Compound Path&gt;" class="cls-2" d="M329.2,83.79H362V95H342.94v23.39h13.62v11.09H342.94v24.83H362v11.21H329.2Z"/>
    <path id="_Compound_Path_3" data-name="&lt;Compound Path&gt;" class="cls-2" d="M384.29,128v37.49H370.54V83.79h20c13.86,0,18.93,6.87,18.93,18.32V112.6c0,8.8-3.14,14-11,15.79l13.15,37.13H397.19Zm0-33v25.44h4.58c4.7,0,6.75-2.42,6.75-6.76V101.63c0-4.46-2.05-6.63-6.75-6.63Z"/>
    <path id="_Compound_Path_4" data-name="&lt;Compound Path&gt;" class="cls-2" d="M418.16,144.43V104.88c0-12.78,6.27-22.06,20.74-22.06s20.86,9.28,20.86,22.06v39.55c0,12.66-6.27,22.06-20.86,22.06S418.16,157.09,418.16,144.43ZM446,146.6V102.71c0-4.94-2.17-8.44-7.11-8.44s-7,3.5-7,8.44V146.6c0,4.94,2.17,8.44,7,8.44S446,151.54,446,146.6Z"/>
  </g>
  <g id="_Group_2" data-name="&lt;Group&gt;">
    <path id="_Compound_Path_5" data-name="&lt;Compound Path&gt;" class="cls-2" d="M312.49,249.23H299.22v36H285.48V203.54h13.74v34.6h13.27v-34.6h13.86v81.74H312.49Z"/>
    <path id="_Compound_Path_6" data-name="&lt;Compound Path&gt;" class="cls-2" d="M375.29,203.54v61.84c0,12.66-5.42,20.86-19.41,20.86-14.46,0-20.49-8.2-20.49-20.86V203.54h13.74v63.17c0,4.82,1.93,8.08,6.75,8.08s6.76-3.26,6.76-8.08V203.54Z"/>
    <path id="_Compound_Path_7" data-name="&lt;Compound Path&gt;" class="cls-2" d="M395.91,233.92v51.36H384.33V203.54h13.51l14.83,46.9v-46.9h11.45v81.74H412.06Z"/>
    <path id="_Compound_Path_8" data-name="&lt;Compound Path&gt;" class="cls-2" d="M453.89,241.63h19.54v43.65h-9.89v-8.92c-1.81,6.14-5.67,9.88-13.14,9.88-11.82,0-17.24-9.4-17.24-22.06V224.64c0-12.78,6.15-22.07,20.61-22.07,15.19,0,19.66,8.44,19.66,20.5v7.23H460.89V222c0-5.06-1.93-7.95-6.87-7.95s-7.12,3.49-7.12,8.44v43.88c0,4.94,2.05,8.44,6.75,8.44,4.46,0,6.76-2.53,6.76-7.84V252.49h-6.52Z"/>
    <path id="_Compound_Path_9" data-name="&lt;Compound Path&gt;" class="cls-2" d="M482.46,203.54h32.8v11.21H496.21v23.39h13.62v11.09H496.21v24.84h19.05v11.21h-32.8Z"/>
    <path id="_Compound_Path_10" data-name="&lt;Compound Path&gt;" class="cls-2" d="M537.56,247.78v37.5H523.81V203.54h20c13.86,0,18.92,6.87,18.92,18.32v10.49c0,8.8-3.13,14-11,15.79l13.14,37.14H550.46Zm0-33v25.44h4.58c4.7,0,6.75-2.41,6.75-6.75V221.38c0-4.46-2-6.63-6.75-6.63Z"/>
  </g>
</svg>`;
