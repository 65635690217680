<template>
  <div id="giz-domain-modal" :class="['modal-content', visibilityClass, modalDomain.feid ]" v-if="modalDomain !== null">
    <div class="header">
      <img :src="domainBanner[modalDomain.feid]" alt="banner"/>
    </div>
    <article>
      <button class="close-btn btn-reset" @click="vissibleModalDomain()" ref="closeBtn">
        <span class="giz-icon cross" aria-hidden="true"></span>
      </button>
      <b-container>
        <b-row>
          <b-col class="col-12 col-md-8 col-lg-7">
            <h1>{{ modalDomain.genericName }}</h1>
            <h2>{{ modalDomain.name }}</h2>
            <div v-html="modalDomain.description" />
          </b-col>
        </b-row>
      </b-container>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { domainBanner } from '../config.js';
import { modalVisibility } from '../mixins/modal.js';

Object.freeze(domainBanner);

export default {
  name: "GizDomainModal",
  data: function() {
    return {
      domainBanner: domainBanner
    }
  },
  computed: {
    ...mapGetters([
      'modalDomain',
      'isModalDomainVisible',
    ])
  },
  methods: {
    ...mapMutations([
      'vissibleModalDomain'
    ])
  },
  watch: {
    isModalDomainVisible: function (visibility) {
      this.modalVisibility(visibility);
    },
  },
  mixins: [ modalVisibility ]
}
</script>

<style lang="scss">
  @import "../assets/scss/functions";
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  #giz-domain-modal {
    padding: 0 0 52px 0;

    // backgrounds
    @each $domain in $domains {
      $background: map-get($colors, "domain-background-" + $domain);
      @if $background {
        &.fe-#{$domain} {
          background: #fff $background;
        }
      }
    }

    button.close-btn {
      right: 22px + $grid-gutter-width / 2;
      top: 20px;
    }

    article {
      @include media-breakpoint-up(lg) {
        margin: 0 (-1 * $grid-gutter-width / 2);
      }
      position: relative;

      & > .container > .row > .col {
        @include media-breakpoint-up(lg) {
          padding-left: 87px + $grid-gutter-width / 2;
        }
      }

      h1 {
        margin-bottom: 0;
        font-weight: 400;
        margin-top: 30px;
      }
      h2 {
        margin-bottom: 20px;
      }
      p {
        line-height: 1.2;
      }
    }
  }
</style>