export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect class="cls-1" width="1000" height="1000"/>
  <path class="cls-2" d="M333.15,128.52l13.11,38.09H361l-13.34-37.73c7.95-1.82,11.14-7.1,11.14-16V102.19c0-11.64-5.15-18.62-19.24-18.62H319.18v83h14Zm0-33.56h4.65c4.79,0,6.87,2.2,6.87,6.75V114c0,4.4-2.08,6.86-6.87,6.86h-4.65Z"/>
  <polygon class="cls-2" points="400.89 155.22 381.53 155.22 381.53 130 395.37 130 395.37 118.72 381.53 118.72 381.53 94.96 400.89 94.96 400.89 83.57 367.56 83.57 367.56 166.61 400.89 166.61 400.89 155.22"/>
  <path class="cls-2" d="M431.13,83.57H409.59v83h21.54c14.94,0,20.21-9.55,20.21-22.29V105.87c0-12.87-5.27-22.3-20.21-22.3m6.25,63.2c0,5-2.19,8.45-8,8.45h-5.87V95h5.87c5.77,0,8,3.42,8,8.32Z"/>
  <path class="cls-2" d="M474.5,83.57h-14V146.4c0,12.86,6.12,21.18,20.82,21.18,14.21,0,19.72-8.32,19.72-21.18V83.57H488.2v64.18c0,4.89-1.95,8.21-6.85,8.21s-6.85-3.32-6.85-8.21Z"/>
  <path class="cls-2" d="M510.26,105v40.16c0,12.86,6.13,22.41,20.81,22.41s19.71-8.81,19.71-20.69V132.56H538.06v15.31c0,4.9-1.72,8.07-6.73,8.07s-7.12-3.54-7.12-8.56V102.8c0-5,2.09-8.58,7.12-8.58s6.73,2.94,6.73,8.09v10.9h12.72v-9.79c0-12.26-4.27-20.82-19.71-20.82-14.68,0-20.81,9.43-20.81,22.41"/>
  <polygon class="cls-2" points="559.62 166.6 592.93 166.6 592.93 155.22 573.59 155.22 573.59 129.99 587.42 129.99 587.42 118.72 573.59 118.72 573.59 94.96 592.93 94.96 592.93 83.57 559.62 83.57 559.62 166.6"/>
  <path class="cls-2" d="M601.62,83.57v83h21.56c14.94,0,20.21-9.55,20.21-22.29V105.87c0-12.87-5.27-22.3-20.21-22.3Zm27.81,19.71v43.49c0,5-2.21,8.45-8,8.45h-5.88V95h5.88c5.75,0,8,3.43,8,8.32"/>
  <rect class="cls-2" x="319.19" y="202.38" width="13.96" height="83.03"/>
  <polygon class="cls-2" points="382.65 202.37 371.01 202.37 371.01 250.02 355.94 202.37 342.23 202.37 342.23 285.41 353.98 285.41 353.98 233.24 370.39 285.41 382.65 285.41 382.65 202.37"/>
  <polygon class="cls-2" points="423.8 213.77 423.8 202.38 390.5 202.38 390.5 285.41 423.8 285.41 423.8 274.01 404.46 274.01 404.46 248.79 418.29 248.79 418.29 237.53 404.46 237.53 404.46 213.77 423.8 213.77"/>
  <path class="cls-2" d="M450,201.4c-14.69,0-21.07,9.42-21.07,22.4V264c0,11.27,4.77,19.46,15.93,21.44v5.63l26.33,4.9V285.54l-10.29-2c7.1-3.42,10.29-10.53,10.29-19.6V223.8c0-13-6.37-22.4-21.19-22.4m7.22,65.53c0,4.89-2.2,8.56-7.22,8.56s-7.11-3.67-7.11-8.56V221.61c0-5,2.22-8.58,7.11-8.58s7.22,3.55,7.22,8.58Z"/>
  <path class="cls-2" d="M479,202.38v62.83c0,12.86,6.13,21.18,20.83,21.18,14.21,0,19.72-8.32,19.72-21.18V202.38H506.69v64.17c0,4.9-2,8.21-6.86,8.21s-6.86-3.31-6.86-8.21V202.38Z"/>
  <path class="cls-2" d="M539.74,202.38l-15.67,83H537.3l2.44-15.2h14.83l2.57,15.2h14.32l-15.79-83Zm1.84,56.71,5.51-33.32h.12l5.51,33.32Z"/>
  <polygon class="cls-2" points="577.1 202.38 577.1 285.4 607.72 285.4 607.72 274.02 591.06 274.02 591.06 202.38 577.1 202.38"/>
  <rect class="cls-2" x="613.97" y="202.38" width="13.96" height="83.03"/>
  <polygon class="cls-2" points="659.91 285.41 659.91 213.77 671.41 213.77 671.41 202.38 634.3 202.38 634.3 213.77 645.82 213.77 645.82 285.41 659.91 285.41"/>
  <rect class="cls-2" x="677.78" y="202.38" width="13.96" height="83.04"/>
  <polygon class="cls-2" points="733.02 274.02 713.67 274.02 713.67 248.79 727.51 248.79 727.51 237.53 713.67 237.53 713.67 213.77 733.02 213.77 733.02 202.38 699.7 202.38 699.7 285.4 733.02 285.4 733.02 274.02"/>
  <path class="cls-2" d="M738.51,256.39v11.14c0,10.91,5.51,18.86,19.72,18.86s20.33-8.09,20.33-19.48v-4c0-9.17-3.68-13.83-11.26-21.55l-8.95-8.82c-4-3.93-6.61-6.61-6.61-11.26v-1.72c0-4.66,2.69-6.85,6.61-6.85,4.42,0,6.61,2.31,6.61,7.09V227h12.75v-6.75c0-12-5.51-18.85-19.48-18.85-13.59,0-19.59,8-19.59,18.24v2.82c0,9.55,3.66,14.33,11.64,22.16l8.19,8.22c4.3,4,6.25,7,6.25,11.76v3.18c0,4.41-2,7.35-6.37,7.35-4.77,0-6.86-2.69-6.86-7.35V256.39Z"/>
  <polygon class="cls-2" points="96.48 131.03 96.48 285.52 130.41 285.52 130.41 83.69 103.32 83.69 72.07 120.31 72.07 155.74 72.36 155.74 96.17 131.03 96.48 131.03"/>
  <path class="cls-2" d="M254.25,233.43V135.78c0-31.55-15.77-54.47-50.31-54.47-34.23,0-50,22.92-50,54.47v97.65c0,31.25,15.79,54.47,50,54.47,34.54,0,50.31-23.22,50.31-54.47m-66.39,5.36V130.43c0-12.21,4.46-20.84,16.08-20.84,11.91,0,16.37,8.63,16.37,20.84V238.79c0,12.21-4.46,20.83-16.37,20.83-11.62,0-16.08-8.62-16.08-20.83"/>
  <g>
    <g>
      <path class="cls-2" d="M592.3,657.55H407.68a2.14,2.14,0,0,0-2,2.24v55.49a2.14,2.14,0,0,0,2,2.25H592.3a2.14,2.14,0,0,0,2-2.25V659.79a2.14,2.14,0,0,0-2-2.24"/>
      <path class="cls-2" d="M592.3,539.48H407.68a2.14,2.14,0,0,0-2,2.24v55.49a2.13,2.13,0,0,0,2,2.25H592.3a2.13,2.13,0,0,0,2-2.25V541.72a2.14,2.14,0,0,0-2-2.24"/>
    </g>
    <g>
      <path class="cls-2" d="M589.7,455.61,501.08,367a1.54,1.54,0,0,0-2.16,0L410.3,455.61a1.53,1.53,0,0,0,1.08,2.61H588.62A1.53,1.53,0,0,0,589.7,455.61Z"/>
      <path class="cls-2" d="M672.9,718.21l88.62-88.62a1.53,1.53,0,0,0,0-2.17L672.9,538.8a1.53,1.53,0,0,0-2.61,1.08V717.13A1.53,1.53,0,0,0,672.9,718.21Z"/>
      <path class="cls-2" d="M410.3,801.41,498.92,890a1.52,1.52,0,0,0,2.16,0l88.62-88.62a1.53,1.53,0,0,0-1.08-2.62H411.38A1.53,1.53,0,0,0,410.3,801.41Z"/>
      <path class="cls-2" d="M327.1,538.8l-88.62,88.62a1.53,1.53,0,0,0,0,2.17l88.62,88.62a1.53,1.53,0,0,0,2.61-1.08V539.88A1.53,1.53,0,0,0,327.1,538.8Z"/>
    </g>
  </g>
</svg>`;
