<template>
  <div id="giz-context-modal" :class="['modal-content', visibilityClass ]">
    <button class="close-btn btn-reset" @click="vissibleModalContext()" ref="closeBtn">
      <span class="giz-icon cross" aria-hidden="true"></span>
    </button>
    <article>
      <b-container>
        <b-row>
          <b-col class="col-12 col-lg-7">
            <h1>General Context Information</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 col-lg-7">
            <ul>
              <li><a href="https://sticerd.lse.ac.uk/inequality/drivers/default.asp#section1">What causes inequality?</a></li>
              <li><a href="https://sticerd.lse.ac.uk/inequality/drivers/global-and-regional.asp">Global and regional drivers of multidimensional inequality</a></li>
              <li><a href="https://sticerd.lse.ac.uk/inequality/drivers/country-level.asp">Country-level drivers</a></li>
              <li><a href="https://www.poverty.ac.uk/report-inequality-income-distribution-wealth-europe/what-drives-growing-inequalities#:~:text=The%20main%20drivers%20of%20growing,changes%20in%20taxation%20and%20transfers.">What drives growing inequalities?</a></li>
              <li><a href="https://inequalitytoolkit.org/intermon/public/guides/global_drivers_of_inequalities.pdf">Global Drivers of Inequalities:</a></li>
              <li><a href="https://www.un.org/development/desa/dspd/wp-content/uploads/sites/22/2020/01/World-Social-Report-2020-FullReport.pdf">World Social Report 2020: Inequality in a rapidly changing world</a></li>
              <li><a href="https://www.oxfamblogs.org/eastafrica/?p=7430">Ten drivers of inequality in Africa (2016) by Oxfam:</a></li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { modalVisibility } from '../mixins/modal.js';
import { mixesIcons, domainWheel, goalIcons } from '../config.js';

Object.freeze(mixesIcons);
Object.freeze(goalIcons);

export default {
  name: "GizContextModal",
  data: function() {
    return {
      mixesIcons: mixesIcons,
      domainWheel: domainWheel,
      goalIcons: goalIcons
    }
  },
  computed: {
    ...mapGetters([
      'domain',
      'sdg',
      'policies',
      'mixes',
      'isModalContextVisible',
    ]),
    firstPolicy: function() {
      if (this.policies.length > 0) {
        return this.policies[0];
      }
      return null;
    },
    firstPolicyDomain: function() {
      return this.domain(this.firstPolicy.domain[0]);
    },
    firstPolicySdg: function() {
      if (this.firstPolicy.sdgs.length > 0) {
        return this.sdg(this.firstPolicy.sdgs[0]);
      }
      return null;
    },
    firstPolicyClass: function() {
      return this.firstPolicyDomain ? this.firstPolicyDomain.feid : '';
    }
  },
  methods: {
    ...mapMutations([
      'vissibleModalContext'
    ])
  },
  watch: {
    isModalContextVisible: function (visibility) {
      this.modalVisibility(visibility);
    },
  },
  mixins: [ modalVisibility ]
}
</script>

<style lang="scss">
  @import "../assets/scss/functions";
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  #giz-context-modal {
    background: map-get($colors, modal-how-background);
    padding: 47px 0 52px 0;

    button.close-btn {
      top: 19px;
      right: 18px;
    }

    article {
      @include media-breakpoint-up(lg) {
        margin: 0 (-1 * $grid-gutter-width / 2);
      }
      & > .container > .row > .col {
        @include media-breakpoint-up(lg) {
          padding-left: 86px + $grid-gutter-width / 2;
        }

        & + .col {
          // right col
          padding-top: 30px;

          @include media-breakpoint-up(lg) {
            padding-left: $grid-gutter-width / 2;
            padding-right: 83px + $grid-gutter-width / 2;
            padding-top: 0;
          }

          h4 {
            font-size: 1.13rem;
            font-weight: 700;
            margin-bottom: 16px;
          }
          & > div {
            // right col box
            margin-bottom: 44px;
          }
        }
      }

      h1 {
        font-size: 1.688rem;
        line-height: 1.2;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 37px;
      }
      .btn-outline {
        margin-top: 50px;
        width: 100%;
        @include media-breakpoint-up(lg) {
          width: auto;
        }
      }
      .btn-mix {
        box-shadow: none;
        font-size: .83rem;
        line-height: 1.2;
        padding-left: 44px;
        display: inline-block;
        width: auto;
        margin: 0 0 10px 0;

        @include media-breakpoint-up(lg) {
          width: 100%;  
        }
        img {
          top: 6px;
          left: 6px;
          width: 30px;
        }
      }
      .giz-policy-teaser {
        font-size: .7rem;
        line-height: 1.1;
        max-width: 130px;
        cursor: auto;
        flex: 0 1 162px;
        min-height: 162px;
        text-transform: uppercase;
        padding: 13px 13px 13px 15px;

        &:after {
          width: 8px;
        }

        .giz-policy-download,
        .domain {
          font-size: .7rem;
        }
        .giz-policy-download {
          margin-top: 7px;
        }
        .domain {
          padding-right: 40px;
        }

        &:focus,
        &:hover {
          & > .info,
          & > .title {
            text-decoration: none;
          }
        }
        
        .btn.sdg {
          cursor: auto;
          width: 60px;
          height: 60px;
          &:focus,
          &:hover {
            svg .cls-2 {
              fill: #fff;
            }
          }
        }
      }
      .domain-wheel {
        position: relative;
        max-width: 200px;
        
        img {
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, .25));
        }
        & > div {
          font-size: .9rem;
          font-weight: 700;
          text-transform: uppercase;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
</style>