export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect id="_Path_" data-name="&lt;Path&gt;" class="cls-1" y="-0.03" width="1000" height="1000.06"/>
  <polygon id="_Path_2" data-name="&lt;Path&gt;" class="cls-2" points="99.82 131.38 100.13 131.38 100.13 285.73 134.03 285.73 134.03 84.09 106.94 84.09 75.73 120.68 75.73 156.06 76.02 156.06 99.82 131.38"/>
  <path id="_Path_3" data-name="&lt;Path&gt;" class="cls-2" d="M154.24,125.74v24.37h30.92V124.83c0-12.18,6-15.75,13.09-15.75,6.55,0,12.19,2.67,12.19,15.17v16.64c0,16.67-1.48,23.2-12.5,34.8l-19,20.23c-18.43,19.63-25.29,32.71-25.29,52.64v37.18h88V258.07H186.05v-9.51c0-15.77,5.65-21.71,14.27-30.93L222,194.73c17.55-18.73,21.42-30.64,21.42-50.86V124.25c0-28.86-14-42.53-44.32-42.53-29.74,0-44.9,15.76-44.9,44"/>
  <path id="_Path_4" data-name="&lt;Path&gt;" class="cls-2" d="M778.55,535.68C737.77,503.47,688.87,500,669.74,500c-10.09,0-19.43.9-26.27,2.53l-2.68.62c-25.83,6.1-69.06,16.31-119.39,78.77l-1.48,1.83a6.54,6.54,0,0,0-1.26,3.78,6.69,6.69,0,0,0,.89,3.31l1.14,1.87q12.46,20.85,20.7,36.21a6.65,6.65,0,0,0,10.76,1.51c.35-.46.71-.95,1-1.32C596.57,569,630.63,561,653.17,555.67L656,555a66,66,0,0,1,13.8-1.17c10.48,0,46.91,1.74,75.33,24.18,25.29,20,38.11,51.84,38.11,94.75,0,71.92-34.79,100-64,110.84a101.61,101.61,0,0,1-35.68,6.28A126.64,126.64,0,0,1,630,777.69l-2.09-1c-25.11-10.9-62.6-33.63-98.05-104.81-72.66-146-133.7-159.78-174.12-168.93l-2.12-.47c-3.1-.71-11.84-2.34-26-2.34-23.4,0-68.42,4.6-107,35.4-26.34,21-57.76,61.83-57.76,137,0,45.77,9.43,76,33.6,107.79,4.54,6.2,46.65,60.58,127.5,60.58a196.78,196.78,0,0,0,36.92-3.62c14.76-2.82,37.54-10.12,63-28.78l21.25,19.18a6.36,6.36,0,0,0,8.63-.4,5.6,5.6,0,0,0,1.53-4l16.44-101.28,0-1.18a6,6,0,0,0-2-4.42,5.92,5.92,0,0,0-4.26-1.57h0L366.81,742l-1.88.31a5.74,5.74,0,0,0-3.13,1.81,6.1,6.1,0,0,0,.42,8.67l20.43,18.48c-10.74,6.59-21.48,11-31.92,13a139.84,139.84,0,0,1-26.16,2.56c-55.87,0-83.36-36.67-84.49-38.22l-.55-.76c-17-22.33-22.74-41.34-22.74-75.34,0-43.06,12.6-75,37.45-94.8,27.65-22.06,63.23-23.78,73.49-23.78a69.38,69.38,0,0,1,14,1.17l2.18.49c32.26,7.31,76.44,17.31,137.7,140.35,32.35,65,72.92,107.44,123.91,129.84,5.93,3,37.56,18.11,78.1,18.11a155.58,155.58,0,0,0,54.5-9.67c29.78-11.11,99.08-49.1,99.08-161.42C837.17,597.72,805.29,556.8,778.55,535.68Z"/>
  <g id="_Group_" data-name="&lt;Group&gt;">
    <path id="_Compound_Path_" data-name="&lt;Compound Path&gt;" class="cls-2" d="M332.84,127.9v37.49H319.1V83.65h20C353,83.65,358,90.52,358,102v10.49c0,8.8-3.14,14-11,15.79l13.14,37.13H345.74Zm0-33V120.3h4.58c4.71,0,6.76-2.41,6.76-6.75v-12c0-4.46-2.05-6.63-6.76-6.63Z"/>
    <path id="_Compound_Path_2" data-name="&lt;Compound Path&gt;" class="cls-2" d="M365.39,83.65h32.79V94.87h-19v23.38h13.62v11.1H379.14v24.83h19v11.21H365.39Z"/>
    <path id="_Compound_Path_3" data-name="&lt;Compound Path&gt;" class="cls-2" d="M404.69,147.79v-11h12.78V148c0,4.58,2,7.24,6.75,7.24,4.34,0,6.27-2.9,6.27-7.24V144.9c0-4.7-1.93-7.6-6.15-11.58l-8.07-8.07c-7.84-7.72-11.46-12.42-11.46-21.83v-2.77c0-10.13,5.91-18,19.29-18,13.75,0,19.17,6.75,19.17,18.56v6.64H430.73v-7.12c0-4.7-2.17-7-6.51-7-3.85,0-6.51,2.17-6.51,6.75v1.69c0,4.58,2.53,7.23,6.51,11.09L433,122c7.48,7.6,11.1,12.18,11.1,21.22v4c0,11.21-6,19.17-20,19.17S404.69,158.52,404.69,147.79Z"/>
    <path id="_Compound_Path_4" data-name="&lt;Compound Path&gt;" class="cls-2" d="M451,83.65h20.37c13.86,0,18.93,6.87,18.93,18.33v17.24c0,11.45-5.07,18.44-18.93,18.44h-6.63v27.73H451Zm13.74,11.22v32.18h5.06c4.7,0,6.75-2.29,6.75-6.75V101.5c0-4.46-2.05-6.63-6.75-6.63Z"/>
    <path id="_Compound_Path_5" data-name="&lt;Compound Path&gt;" class="cls-2" d="M497.28,144.29V104.75c0-12.78,6.27-22.06,20.73-22.06s20.86,9.28,20.86,22.06v39.54c0,12.66-6.27,22.07-20.86,22.07S497.28,157,497.28,144.29Zm27.85,2.17V102.58c0-4.94-2.17-8.44-7.12-8.44s-7,3.5-7,8.44v43.88c0,4.95,2.17,8.44,7,8.44S525.13,151.41,525.13,146.46Z"/>
    <path id="_Compound_Path_6" data-name="&lt;Compound Path&gt;" class="cls-2" d="M558.16,114v51.36H546.59V83.65h13.5l14.83,46.9V83.65h11.45v81.74H574.31Z"/>
    <path id="_Compound_Path_7" data-name="&lt;Compound Path&gt;" class="cls-2" d="M593.48,147.79v-11h12.78V148c0,4.58,2,7.24,6.75,7.24,4.34,0,6.27-2.9,6.27-7.24V144.9c0-4.7-1.93-7.6-6.15-11.58l-8.08-8.07c-7.83-7.72-11.45-12.42-11.45-21.83v-2.77c0-10.13,5.91-18,19.29-18,13.74,0,19.17,6.75,19.17,18.56v6.64H619.52v-7.12c0-4.7-2.17-7-6.51-7-3.86,0-6.51,2.17-6.51,6.75v1.69c0,4.58,2.53,7.23,6.51,11.09l8.8,8.68c7.48,7.6,11.09,12.18,11.09,21.22v4c0,11.21-6,19.17-20,19.17S593.48,158.52,593.48,147.79Z"/>
    <path id="_Compound_Path_8" data-name="&lt;Compound Path&gt;" class="cls-2" d="M639.77,83.65h13.75v81.74H639.77Z"/>
    <path id="_Compound_Path_9" data-name="&lt;Compound Path&gt;" class="cls-2" d="M661.35,83.65h19.9c13.74,0,18.32,6.39,18.32,18v8.32c0,6.87-2.53,11.21-8.2,13,6.87,1.56,10.25,6.14,10.25,13.62v10.85c0,11.57-5.18,18-18.93,18H661.35Zm13.75,34.48h4.82c4.46,0,6.51-2.29,6.51-6.63v-10c0-4.46-2-6.63-6.63-6.63h-4.7Zm0,10.49v25.56h6c4.59,0,6.76-2.29,6.76-6.63v-12.3c0-4.34-2-6.63-6.88-6.63Z"/>
    <path id="_Compound_Path_10" data-name="&lt;Compound Path&gt;" class="cls-2" d="M708.37,83.65h13.74v70.53h16.4v11.21H708.37Z"/>
    <path id="_Compound_Path_11" data-name="&lt;Compound Path&gt;" class="cls-2" d="M744.66,83.65h32.79V94.87H758.4v23.38H772v11.1H758.4v24.83h19.05v11.21H744.66Z"/>
  </g>
  <g id="_Group_2" data-name="&lt;Group&gt;">
    <path id="_Compound_Path_12" data-name="&lt;Compound Path&gt;" class="cls-2" d="M319.1,264.31V224.77c0-12.78,6-22.06,20.49-22.06,15.19,0,19.41,8.44,19.41,20.49v9.65H346.47V222.12c0-5.07-1.69-8-6.64-8s-7,3.5-7,8.44v43.88c0,4.94,2.05,8.44,7,8.44s6.64-3.13,6.64-8V251.89H359V266c0,11.69-4.82,20.37-19.41,20.37S319.1,277,319.1,264.31Z"/>
    <path id="_Compound_Path_13" data-name="&lt;Compound Path&gt;" class="cls-2" d="M367.68,264.31V224.77c0-12.78,6.27-22.06,20.74-22.06s20.86,9.28,20.86,22.06v39.54c0,12.66-6.27,22.06-20.86,22.06S367.68,277,367.68,264.31Zm27.85,2.17V222.6c0-4.94-2.17-8.44-7.11-8.44s-7,3.5-7,8.44v43.88c0,4.94,2.17,8.44,7,8.44S395.53,271.42,395.53,266.48Z"/>
    <path id="_Compound_Path_14" data-name="&lt;Compound Path&gt;" class="cls-2" d="M429.89,234.05v51.36H418.31V203.67h13.51l14.83,46.9v-46.9H458.1v81.74H446Z"/>
    <path id="_Compound_Path_15" data-name="&lt;Compound Path&gt;" class="cls-2" d="M466.42,267.81v-11H479.2v11.21c0,4.58,2,7.23,6.75,7.23,4.34,0,6.27-2.89,6.27-7.23v-3.14c0-4.7-1.93-7.59-6.15-11.57L478,245.26c-7.84-7.71-11.45-12.41-11.45-21.82v-2.77c0-10.13,5.9-18,19.29-18,13.74,0,19.17,6.75,19.17,18.56v6.63H492.46v-7.11c0-4.7-2.17-7-6.51-7-3.86,0-6.51,2.17-6.51,6.75v1.69c0,4.58,2.53,7.23,6.51,11.09l8.8,8.68c7.47,7.59,11.09,12.17,11.09,21.22v4c0,11.21-6,19.16-20,19.16S466.42,278.54,466.42,267.81Z"/>
    <path id="_Compound_Path_16" data-name="&lt;Compound Path&gt;" class="cls-2" d="M554.18,203.67v61.85c0,12.66-5.42,20.85-19.41,20.85-14.47,0-20.49-8.19-20.49-20.85V203.67H528v63.17c0,4.83,1.93,8.08,6.75,8.08s6.75-3.25,6.75-8.08V203.67Z"/>
    <path id="_Compound_Path_17" data-name="&lt;Compound Path&gt;" class="cls-2" d="M601.08,232.36h-.36l-9.29,53.05H585L574.8,232.36h-.25v53.05H563.22V203.67h16l8.92,46.05h.12l8-46.05h17.37v81.74H601.08Z"/>
    <path id="_Compound_Path_18" data-name="&lt;Compound Path&gt;" class="cls-2" d="M622.66,203.67H643c13.86,0,18.93,6.87,18.93,18.33v17.24c0,11.45-5.07,18.44-18.93,18.44H636.4v27.73H622.66Zm13.74,11.21v32.19h5.06c4.71,0,6.75-2.29,6.75-6.75V221.51c0-4.46-2-6.63-6.75-6.63Z"/>
    <path id="_Compound_Path_19" data-name="&lt;Compound Path&gt;" class="cls-2" d="M666.42,203.67H703v11.21H691.61v70.53H677.75V214.88H666.42Z"/>
    <path id="_Compound_Path_20" data-name="&lt;Compound Path&gt;" class="cls-2" d="M709.46,203.67H723.2v81.74H709.46Z"/>
    <path id="_Compound_Path_21" data-name="&lt;Compound Path&gt;" class="cls-2" d="M731.15,264.31V224.77c0-12.78,6.27-22.06,20.74-22.06s20.86,9.28,20.86,22.06v39.54c0,12.66-6.27,22.06-20.86,22.06S731.15,277,731.15,264.31ZM759,266.48V222.6c0-4.94-2.17-8.44-7.11-8.44s-7,3.5-7,8.44v43.88c0,4.94,2.17,8.44,7,8.44S759,271.42,759,266.48Z"/>
    <path id="_Compound_Path_22" data-name="&lt;Compound Path&gt;" class="cls-2" d="M792.28,234.05v51.36H780.7V203.67h13.5L809,250.57v-46.9h11.46v81.74H808.43Z"/>
  </g>
  <g id="_Group_3" data-name="&lt;Group&gt;">
    <path id="_Compound_Path_23" data-name="&lt;Compound Path&gt;" class="cls-2" d="M316.93,405.44l15.43-81.74H348l15.56,81.74H349.48L347,390.49H332.36L330,405.44Zm17.24-25.92h11l-5.43-32.8h-.12Z"/>
    <path id="_Compound_Path_24" data-name="&lt;Compound Path&gt;" class="cls-2" d="M381.18,354.08v51.36H369.61V323.7h13.5l14.83,46.89V323.7H409.4v81.74H397.34Z"/>
    <path id="_Compound_Path_25" data-name="&lt;Compound Path&gt;" class="cls-2" d="M458.7,345.64v37.85c0,12.54-5.18,21.95-19.89,21.95H417.59V323.7h21.22C453.52,323.7,458.7,333,458.7,345.64Zm-21.58,48.58c5.67,0,7.84-3.37,7.84-8.32V343.11c0-4.83-2.17-8.2-7.84-8.2h-5.79v59.31Z"/>
    <path id="_Compound_Path_26" data-name="&lt;Compound Path&gt;" class="cls-2" d="M479.92,323.7h20.37c13.87,0,18.93,6.87,18.93,18.32v17.24c0,11.45-5.06,18.45-18.93,18.45h-6.63v27.73H479.92Zm13.74,11.21V367.1h5.06c4.71,0,6.76-2.29,6.76-6.75V341.54c0-4.46-2.05-6.63-6.76-6.63Z"/>
    <path id="_Compound_Path_27" data-name="&lt;Compound Path&gt;" class="cls-2" d="M540.44,367.94v37.5H526.69V323.7h20c13.86,0,18.92,6.87,18.92,18.32v10.49c0,8.8-3.13,14-11,15.79l13.14,37.14H553.34Zm0-33v25.44H545c4.7,0,6.75-2.41,6.75-6.76v-12c0-4.46-2-6.63-6.75-6.63Z"/>
    <path id="_Compound_Path_28" data-name="&lt;Compound Path&gt;" class="cls-2" d="M573.47,384.34V344.79c0-12.78,6.27-22.06,20.73-22.06s20.86,9.28,20.86,22.06v39.55c0,12.66-6.27,22.06-20.86,22.06S573.47,397,573.47,384.34Zm27.85,2.17V342.62c0-4.94-2.17-8.44-7.12-8.44s-7,3.5-7,8.44v43.89c0,4.94,2.17,8.44,7,8.44S601.32,391.45,601.32,386.51Z"/>
    <path id="_Compound_Path_29" data-name="&lt;Compound Path&gt;" class="cls-2" d="M664.37,345.64v37.85c0,12.54-5.19,21.95-19.89,21.95H623.26V323.7h21.22C659.18,323.7,664.37,333,664.37,345.64Zm-21.58,48.58c5.67,0,7.83-3.37,7.83-8.32V343.11c0-4.83-2.16-8.2-7.83-8.2H637v59.31Z"/>
    <path id="_Compound_Path_30" data-name="&lt;Compound Path&gt;" class="cls-2" d="M712.47,323.7v61.84c0,12.66-5.43,20.86-19.41,20.86-14.47,0-20.5-8.2-20.5-20.86V323.7h13.75v63.17c0,4.82,1.93,8.08,6.75,8.08s6.75-3.26,6.75-8.08V323.7Z"/>
    <path id="_Compound_Path_31" data-name="&lt;Compound Path&gt;" class="cls-2" d="M720.67,384.34V344.79c0-12.78,6-22.06,20.49-22.06,15.19,0,19.41,8.44,19.41,20.5v9.64H748V342.14c0-5.06-1.68-8-6.63-8s-7,3.5-7,8.44v43.89c0,4.94,2.05,8.44,7,8.44s6.63-3.14,6.63-8V371.92h12.54V386c0,11.69-4.82,20.37-19.41,20.37S720.67,397,720.67,384.34Z"/>
    <path id="_Compound_Path_32" data-name="&lt;Compound Path&gt;" class="cls-2" d="M766.84,323.7h36.53v11.21H792v70.53H778.17V334.91H766.84Z"/>
    <path id="_Compound_Path_33" data-name="&lt;Compound Path&gt;" class="cls-2" d="M809.88,323.7h13.74v81.74H809.88Z"/>
    <path id="_Compound_Path_34" data-name="&lt;Compound Path&gt;" class="cls-2" d="M831.58,384.34V344.79c0-12.78,6.26-22.06,20.73-22.06s20.86,9.28,20.86,22.06v39.55c0,12.66-6.27,22.06-20.86,22.06S831.58,397,831.58,384.34Zm27.84,2.17V342.62c0-4.94-2.17-8.44-7.11-8.44s-7,3.5-7,8.44v43.89c0,4.94,2.17,8.44,7,8.44S859.42,391.45,859.42,386.51Z"/>
    <path id="_Compound_Path_35" data-name="&lt;Compound Path&gt;" class="cls-2" d="M892.7,354.08v51.36H881.12V323.7h13.51l14.83,46.89V323.7h11.45v81.74H908.85Z"/>
  </g>
</svg>`;
