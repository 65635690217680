<template>
  <div id="giz-policy-modal" :class="['modal-content', visibilityClass, policyDomain.feid ]" v-if="modalPolicy !== null && policyDomain !== null">
    <button class="close-btn btn-reset" @click="vissibleModalPolicy()" ref="closeBtn">
      <span class="giz-icon cross" aria-hidden="true"></span>
    </button>
    <article>
      <b-container>
        <b-row>
          <b-col class="col-12 col-md-8 col-lg-7">
            <h2 class="first">{{ modalPolicy.genericName }}</h2>
            <h1>{{ modalPolicy.title }}</h1>

            <h3>Description</h3>
            <div v-html="modalPolicy.description" />

            <h3>Relationship to Inequality and Poverty</h3>
            <div v-html="modalPolicy.relationship" />

            <h3>Key Actors</h3>
            <div v-html="modalPolicy.keyActors" />

            <h3>Level of Intervention</h3>
            <div v-html="modalPolicy.levelOfIntervention" />
            
            <h3>Evidence of Effectiveness</h3>
            <div v-html="modalPolicy.evidence" />
          </b-col>
          <b-col class="col-12 col-md-4 col-lg-4 ml-auto">
            <div class="inner">
              <giz-sdg-link :sdg="policySdg" v-for="policySdg in policySdgs" :key="'sdg-' + policySdg.id">
                <!-- <h5>Sdg {{ policySdg.number }}:</h5> -->
                <h5>{{ policySdg.name }}</h5>
                <div v-html="goalIcons[policySdg.feid]" :class="policySdg.feid"/>
              </giz-sdg-link>
              <giz-domain-link :domain="policyDomain" >
                <h5>{{ policyDomain.genericName }}</h5>
                {{ policyDomain.name }}
                <div :class="['domain-icon', policyDomain.feid ]">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" class="background">
                    <rect class="cls-1" width="10" height="10"/>
                  </svg>
                  <div v-html="domainIcon[policyDomain.feid]" class="icon"/>
                </div>
              </giz-domain-link>
              <giz-policy-download :policy="modalPolicy" caption="Policy as PDF" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { goalIcons, domainIcon } from '../config.js';
import { modalVisibility } from '../mixins/modal.js';
import GizSdgLink from './SdgLink';
import GizDomainLink from './DomainLink';
import GizPolicyDownload from './PolicyDownload';

Object.freeze(goalIcons);
Object.freeze(domainIcon);

export default {
  name: "GizPolicyModal",
  data: function() {
    return {
      goalIcons: goalIcons,
      domainIcon: domainIcon
    }
  },
  computed: {
    ...mapGetters([
      'modalPolicy',
      'sdg',
      'domain',
      'isModalPolicyVisible',
    ]),
    policySdgs: function() {
      if(this.modalPolicy && this.modalPolicy.sdgs.length > 0) {
        return this.modalPolicy.sdgs.map(sdgId => this.sdg(sdgId));
      }
      return [];
    },
    policyDomain: function() {
      if(this.modalPolicy && this.modalPolicy.domain.length > 0) {
        return this.domain(this.modalPolicy.domain[0]);
      }
      return null;
    },
  },
  methods: {
    ...mapMutations([
      'vissibleModalPolicy'
    ])
  },
  watch: {
    isModalPolicyVisible: function (visibility) {
      this.modalVisibility(visibility);
    },
  },
  components: {
    GizSdgLink,
    GizDomainLink,
    GizPolicyDownload
  },
  mixins: [ modalVisibility ]
}
</script>

<style lang="scss">
  @import "../assets/scss/functions";
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  #giz-policy-modal {
    top: 0;
    padding: 52px 0 52px 0;

    button.close-btn {
      top: 18px;
      right: 27px;
    }

    // backgrounds
    @each $domain in $domains {
      $background: map-get($colors, "domain-background-inverse-" + $domain);
      @if $background {
        &.fe-#{$domain} {
          background: #fff $background;
        }
      }
    }

    article {
      @include media-breakpoint-up(lg) {
        margin: 0 (-1 * $grid-gutter-width / 2);
      }
      & > .container > .row > .col {
        @include media-breakpoint-up(lg) {
          padding-left: 86px + $grid-gutter-width / 2;
        }

        & + .col {
          // right col
          padding-left: $grid-gutter-width / 2;
          & > .inner {
            padding-top: 30px;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
              padding-top: 20px;
            }
            @include media-breakpoint-up(lg) {
              padding-right: 86px;
            }
            .domain-icon {
              position: relative;
              .icon {
                position: absolute;
                width: calc(100% - 2rem);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .domain-icon,
            svg {
              margin-top: 20px;
              width: 100%;
              max-width: 151px;
            }
            .domain-icon svg {
              margin-top: 0;
            }
            .link,
            button {
              margin-bottom: 35px;

              &:hover,
              &:focus {
                text-decoration: underline;

                svg .cls-2 {
                  fill: map-get($colors, font);
                }
              }

              

              & > svg {
                display: block;
              }
            }
            .link,
            button,
            h5 {
              text-transform: uppercase;
            }
            h5 {
              margin-bottom: 0;
            }
          }
        }
      }

      h2.first {
        margin-bottom: 0;
        font-weight: 400;
      }
      h1 {
        margin-bottom: 33px;
      }
    }
  }
</style>