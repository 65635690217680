export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect class="cls-1" width="1000" height="1000"/>
  <polygon class="cls-2" points="130.29 84.14 130.29 285.67 96.39 285.67 96.39 131.41 96.1 131.41 72.33 156.07 72.02 156.07 72.02 120.7 103.23 84.14 130.29 84.14"/>
  <rect class="cls-2" x="355.6" y="83.71" width="13.74" height="81.77"/>
  <polygon class="cls-2" points="377.39 165.49 391.13 165.49 391.13 129.41 404.87 129.41 404.87 118.32 391.13 118.32 391.13 94.92 409.57 94.92 409.57 83.71 377.39 83.71 377.39 165.49"/>
  <polygon class="cls-2" points="448.48 94.92 448.48 83.71 415.7 83.71 415.7 165.49 448.48 165.49 448.48 154.27 429.44 154.27 429.44 129.41 443.06 129.41 443.06 118.32 429.44 118.32 429.44 94.92 448.48 94.92"/>
  <path class="cls-2" d="M514.39,136.65c0-7.47-3.36-12.05-10.23-13.62,5.65-1.81,8.2-6.15,8.2-13v-8.33c0-11.56-4.59-18-18.33-18H474.14v81.77h21.33c13.75,0,18.92-6.39,18.92-18ZM487.88,94.92h4.7c4.59,0,6.64,2.19,6.64,6.64v10c0,4.35-2.05,6.64-6.52,6.64h-4.82Zm12.78,52.71c0,4.34-2.18,6.63-6.75,6.63h-6V128.7h5.91c4.82,0,6.87,2.29,6.87,6.63Z"/>
  <polygon class="cls-2" points="520.26 165.49 553.04 165.49 553.04 154.27 533.99 154.27 533.99 129.41 547.61 129.41 547.61 118.32 533.99 118.32 533.99 94.92 553.04 94.92 553.04 83.71 520.26 83.71 520.26 165.49"/>
  <polygon class="cls-2" points="561.49 83.71 561.49 165.49 591.63 165.49 591.63 154.27 575.23 154.27 575.23 83.71 561.49 83.71"/>
  <path class="cls-2" d="M596.86,104.82v39.55c0,12.68,6.27,22.07,20.73,22.07s20.86-9.39,20.86-22.07V104.82c0-12.78-6.27-22.07-20.86-22.07S596.86,92,596.86,104.82m27.85-2.18v43.91c0,4.94-2.17,8.44-7.12,8.44s-7-3.5-7-8.44V102.64c0-4.94,2.18-8.43,7-8.43s7.12,3.49,7.12,8.43"/>
  <polygon class="cls-2" points="663.11 135.57 663 135.57 656.61 83.71 643.35 83.71 654.32 165.49 667.21 165.49 674.93 114.94 675.04 114.94 683.12 165.49 696.14 165.49 706.74 83.71 695.18 83.71 688.9 135.57 688.79 135.57 681.2 83.71 669.99 83.71 663.11 135.57"/>
  <polygon class="cls-2" points="328.14 285.37 341.03 285.37 348.75 234.84 348.87 234.84 356.95 285.37 369.97 285.37 380.58 203.61 369 203.61 362.74 255.47 362.61 255.47 355.03 203.61 343.81 203.61 336.94 255.47 336.82 255.47 330.43 203.61 317.17 203.61 328.14 285.37"/>
  <path class="cls-2" d="M378.72,285.37h13l2.4-14.94h14.59l2.52,14.94h14.1L409.8,203.61H394.14Zm22.65-58.73h.13l5.43,32.8H396Z"/>
  <polygon class="cls-2" points="447.93 285.37 447.93 214.82 459.26 214.82 459.26 203.61 422.74 203.61 422.74 214.82 434.06 214.82 434.06 285.37 447.93 285.37"/>
  <polygon class="cls-2" points="498.4 214.82 498.4 203.61 465.62 203.61 465.62 285.37 498.4 285.37 498.4 274.16 479.36 274.16 479.36 249.31 492.98 249.31 492.98 238.21 479.36 238.21 479.36 214.82 498.4 214.82"/>
  <path class="cls-2" d="M544.67,232.43V221.94c0-11.46-5.06-18.32-18.92-18.32h-20v81.75h13.74V247.88l12.89,37.49h14.47l-13.13-37.15c7.83-1.8,11-7,11-15.79m-13.86,1.09c0,4.34-2,6.75-6.74,6.75h-4.59V214.82h4.59c4.69,0,6.74,2.17,6.74,6.63Z"/>
  <path class="cls-2" d="M233.49,285.72V253h13.68V226.54H233.49V84.09H200.18l-58.59,148.1V253h59.78v32.71Zm-62.16-59.18,30.33-83h.3v83Z"/>
  <g>
    <path class="cls-2" d="M769.58,729.62C757,712.62,675.7,609,561.72,609c-99,0-204.85,89.76-204.85,89.76s-39.77-81.4-121.13-89.22c-.47,0-1-.11-1.52-.11h-.51c-1.7.1-3.29,1.75-3.1,3.22a9,9,0,0,0,1.27,3C242.37,633,269.13,682.55,269.13,732c0,49.64-26.92,99.3-37.31,116.63l-.71,1.1a3.3,3.3,0,0,0-.5,1.72,3.23,3.23,0,0,0,3,3.2l.88-.07a3.14,3.14,0,0,0,.85-.08c81.61-7.6,121.53-89.25,121.53-89.25s105.88,89.77,204.85,89.77c114,0,195.27-103.64,207.86-120.61.34-.46.62-.86.87-1.16a3.36,3.36,0,0,0,.27-1.25,3.18,3.18,0,0,0-.27-1.24c-.25-.35-.53-.75-.87-1.17M625.1,745.12a33.26,33.26,0,1,1,33.3-33.27,33.28,33.28,0,0,1-33.3,33.27"/>
    <path class="cls-2" d="M233.18,414.17a3.46,3.46,0,0,0-.63-.14,3.29,3.29,0,0,0-3.27,3.31V440a3.23,3.23,0,0,0,.95,2.35c55,47.8,107.11,3,119.17-8.83a3.3,3.3,0,0,1,.86-.92,3.36,3.36,0,0,1,1.81-.59,3.17,3.17,0,0,1,2.21.88.52.52,0,0,0,.16.14c62.15,59.3,127.31,11.27,140.87,0l1.33-1.22a2.9,2.9,0,0,1,1.24-.25,3,3,0,0,1,1.29.25l1.35,1.22c13.55,11.27,78.71,59.3,140.85,0a.94.94,0,0,0,.12-.14,3.2,3.2,0,0,1,2.21-.88,3.28,3.28,0,0,1,1.81.59,3.62,3.62,0,0,1,.91.92c12.05,11.84,64.09,56.63,119.15,8.83a3.31,3.31,0,0,0,.95-2.35V417.34a3.25,3.25,0,0,0-3.23-3.31,3.47,3.47,0,0,0-.7.14c-.51.29-1,.57-1.65.86-16.92,8.47-69.4,28.63-117-16.44-.45-.41-.92-.83-1.39-1.32a3.42,3.42,0,0,0-1.39-.3,5.91,5.91,0,0,0-1,.15,3.74,3.74,0,0,0-1.66,1.29c-14.2,10.82-79.36,54.94-137.39.81l-.85-.83a3.64,3.64,0,0,0-2.36-1,3.47,3.47,0,0,0-2.27,1l-.9.83c-58,54.13-123.24,10-137.42-.81a3.54,3.54,0,0,0-1.61-1.29,6.85,6.85,0,0,0-1-.15,3.72,3.72,0,0,0-1.37.3,13.27,13.27,0,0,1-1.39,1.32c-47.63,45.07-100.1,24.91-117,16.44-.59-.29-1.16-.57-1.67-.86"/>
    <path class="cls-2" d="M349.4,516.08a3.55,3.55,0,0,1,.87-.9,3.36,3.36,0,0,1,1.8-.62,3.19,3.19,0,0,1,2.22.91.5.5,0,0,0,.15.14c62.13,59.23,127.31,11.22,140.87,0l1.33-1.26a3.2,3.2,0,0,1,1.24-.21,3.47,3.47,0,0,1,1.31.21l1.33,1.26c13.55,11.22,78.7,59.23,140.86,0,0,0,.07-.08.11-.14a3.18,3.18,0,0,1,2.21-.91,3.25,3.25,0,0,1,1.8.62,3.58,3.58,0,0,1,.92.9c12,11.85,64.08,56.65,119.15,8.8a3.18,3.18,0,0,0,1-2.29V499.84a3.24,3.24,0,0,0-3.25-3.25,4.3,4.3,0,0,0-.7.13l-1.65.83c-16.91,8.48-69.42,28.59-117-16.42l-1.38-1.3a2.64,2.64,0,0,0-1.4-.33,2,2,0,0,0-1,.25,2.79,2.79,0,0,0-1.66,1.22c-14.18,10.8-79.36,54.93-137.4.8l-.85-.85a3.35,3.35,0,0,0-2.35-.91,3.22,3.22,0,0,0-2.27.91l-.9.85c-58,54.13-123.24,10-137.42-.8a2.71,2.71,0,0,0-1.61-1.22,2.25,2.25,0,0,0-1-.25,2.6,2.6,0,0,0-1.35.33c-.45.45-.93.88-1.42,1.3-47.63,45-100.09,24.9-117,16.42l-1.67-.83a4.32,4.32,0,0,0-.62-.13,3.26,3.26,0,0,0-3.28,3.25v22.75a3.13,3.13,0,0,0,.95,2.29c55,47.85,107.12,3,119.17-8.8"/>
  </g>
  <polygon class="cls-2" points="319.1 83.71 319.1 165.49 349.25 165.49 349.25 154.27 332.85 154.27 332.85 83.71 319.1 83.71"/>
</svg>`;
