export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.0344 39.0001">
  <g>
    <path class="cls-2" d="M14.5267,13.47a2.8181,2.8181,0,1,0-2.818,2.8181A2.8133,2.8133,0,0,0,14.5267,13.47Zm-4.9819,0a2.1639,2.1639,0,1,1,2.1639,2.1639A2.1692,2.1692,0,0,1,9.5448,13.47Z"/>
    <path class="cls-2" d="M30.0094,16.0531a2.5,2.5,0,1,0,2.499-2.4994A2.5056,2.5056,0,0,0,30.0094,16.0531Zm4.344,0a1.845,1.845,0,1,1-1.845-1.8452A1.84,1.84,0,0,1,34.3534,16.0531Z"/>
    <path class="cls-2" d="M50.8754,17.7807a1.845,1.845,0,1,0,1.845-1.8452A1.84,1.84,0,0,0,50.8754,17.7807Zm3.036,0A1.191,1.191,0,1,1,52.72,16.59,1.1957,1.1957,0,0,1,53.9114,17.7807Z"/>
    <path class="cls-2" d="M59.2964,19.0892l-.302.1007a20.63,20.63,0,0,1-6.273.8387,32.2924,32.2924,0,0,1-5.435-.6374V11.4738h-.017a47.7775,47.7775,0,0,0,9.763-2.5329l-1.074-3.0361a64.0555,64.0555,0,0,1-13.704,1.8284c-3.556.1006-7.046.1509-9.713.1677-2.667-.0168-6.172-.0671-9.712-.1677A65.3676,65.3676,0,0,1,9.1251,5.9048L8.0516,8.9409a48.1035,48.1035,0,0,0,9.7961,2.5329h.05V16.12a33.0135,33.0135,0,0,1-6.2064.4362,49.01,49.01,0,0,1-10.4-1.3923L1.0065,15.08,0,17.9822.2852,18.1a37.8131,37.8131,0,0,0,7.3135,1.929V35.0247h3.1535v-7.431h1.862v7.431h3.1535V20.0286c.7213-.1174,1.4426-.2516,2.13-.4026V39h3.2874V30.8982h3.288V39h3.288V21.9408c.369.0671.738.1342,1.124.2013V35.226h2.834V31.7705h1.544V35.226h2.835V22.1421c.452-.0671.905-.1509,1.341-.2348V39h3.288v-15.03h3.288V39h3.288V21.74a23.5669,23.5669,0,0,0,2.801.6374v9.1586h2.181V29.2544h.889v2.2812h2.18V22.377a24.3559,24.3559,0,0,0,4.378-1.1574l.302-.1175Zm-41.3816-.1341c-.7716.1845-1.5936.3354-2.4323.4529H15.13V34.3537H13.2851V26.9228h-3.17v7.4309H8.2529V19.408H7.9006A37.2439,37.2439,0,0,1,.8219,17.6131l.6207-1.7445a51.4366,51.4366,0,0,0,10.249,1.3419,33.5173,33.5173,0,0,0,6.2232-.4361v2.1807Zm19.5246,2.2812c-.536.1175-1.09.2181-1.643.302H35.46V34.5718h-1.543V31.1163h-2.835v3.4555h-1.543V21.5383h-.336c-.486-.0671-.973-.151-1.442-.2516V19.3073a44.9434,44.9434,0,0,0,4.73.3858,30.0186,30.0186,0,0,0,4.948-.3019Zm0-2.5161a27.9883,27.9883,0,0,1-4.931.3187,45.3286,45.3286,0,0,1-4.747-.4026V11.4738h9.662V18.72Zm17.563,3.0529h-.336v9.1251h-.889V28.6169h-2.18v2.2813h-.889V21.7731h-.336a20.3256,20.3256,0,0,1-3.12-.7045V20.0621a31.5768,31.5768,0,0,0,5.418.6207,21.6913,21.6913,0,0,0,6.19-.7716l.285.8219A25.5786,25.5786,0,0,1,55.0024,21.7731Z"/>
    <path class="cls-2" d="M42.3714,6.525a3.2542,3.2542,0,1,0-3.254-3.2542A3.257,3.257,0,0,0,42.3714,6.525Z"/>
    <path class="cls-2" d="M22.8464,6.5251A3.2626,3.2626,0,1,0,19.5757,3.271,3.275,3.275,0,0,0,22.8464,6.5251Z"/>
  </g>
</svg>`;
