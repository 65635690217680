export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect id="_Path_" data-name="&lt;Path&gt;" class="cls-1" y="-0.02" width="1000" height="1000.03"/>
  <g>
    <path id="_Path_2" data-name="&lt;Path&gt;" class="cls-2" d="M142.7,288.14c34.21,0,49.08-18.15,49.08-46.1V212.31c0-18.44-8-30.34-25-34.2,14-4.46,23.19-15.77,23.19-32.71V127.85c0-28.55-13.68-46.09-44.9-46.09-35.1,0-47.3,20.8-47.3,50.55v17.84h30.93V129.63c0-12.49,3.88-19.62,14.58-19.62s14.56,7.13,14.56,18.13v21.71c0,10.71-7.13,16.35-18.12,16.35h-12.8v25.89h14.28c11.89,0,16.64,5.64,16.64,16.35v33.31c0,10.7-4.75,18.15-15.16,18.15-11.59,0-15.76-7.75-15.76-19.64V211.42H96v26.46c0,30.64,12.18,50.26,46.67,50.26"/>
    <g>
      <path id="_Path_3" data-name="&lt;Path&gt;" class="cls-2" d="M835.26,625c0-28.14-18-51-45.4-51-13.67,0-30.17,15-39.13,24.19-9-9.23-24.36-24.21-38-24.21-27.34,0-46.51,22.81-46.51,51a51.51,51.51,0,0,0,14.51,36h0l70.1,70.23,70-70.15-.06-.05A51.55,51.55,0,0,0,835.26,625Z"/>
      <g id="_Group_" data-name="&lt;Group&gt;">
        <path id="_Path_4" data-name="&lt;Path&gt;" class="cls-2" d="M536.81,845.88l-.72,0a11.48,11.48,0,0,1-10.63-9.54L489.85,628.93l-53.94,100a11.51,11.51,0,0,1-21.52-3.83l-22.9-160.05-41.89,94.1A11.54,11.54,0,0,1,339.08,666H176.25a11.52,11.52,0,0,1,0-23H331.6l55.11-123.77a11.51,11.51,0,0,1,21.91,3l23.61,165,53.19-98.63a11.52,11.52,0,0,1,21.48,3.52l33,192.07L660.05,385a11.52,11.52,0,0,1,21.4-1.68l63.23,131.09a11.51,11.51,0,1,1-20.73,10L673.57,420,547.82,837.68A11.5,11.5,0,0,1,536.81,845.88Z"/>
      </g>
    </g>
    <g id="_Group_2" data-name="&lt;Group&gt;">
      <path id="_Compound_Path_" data-name="&lt;Compound Path&gt;" class="cls-2" d="M306.23,121.79h19.54v43.65h-9.89v-8.93c-1.81,6.15-5.67,9.89-13.14,9.89-11.82,0-17.24-9.4-17.24-22.06V104.79c0-12.78,6.15-22.06,20.61-22.06,15.19,0,19.66,8.44,19.66,20.5v7.23H313.23v-8.32c0-5.06-1.93-8-6.87-8s-7.12,3.5-7.12,8.44v43.89c0,4.94,2.05,8.44,6.75,8.44,4.46,0,6.76-2.53,6.76-7.84V132.64h-6.52Z"/>
      <path id="_Compound_Path_2" data-name="&lt;Compound Path&gt;" class="cls-2" d="M334.8,144.34V104.79c0-12.78,6.27-22.06,20.74-22.06S376.4,92,376.4,104.79v39.55c0,12.66-6.27,22.06-20.86,22.06S334.8,157,334.8,144.34Zm27.85,2.17V102.62c0-4.94-2.17-8.44-7.11-8.44s-7,3.5-7,8.44v43.89c0,4.94,2.17,8.44,7,8.44S362.65,151.45,362.65,146.51Z"/>
      <path id="_Compound_Path_3" data-name="&lt;Compound Path&gt;" class="cls-2" d="M385.44,144.34V104.79c0-12.78,6.27-22.06,20.74-22.06S427,92,427,104.79v39.55c0,12.66-6.27,22.06-20.85,22.06S385.44,157,385.44,144.34Zm27.85,2.17V102.62c0-4.94-2.17-8.44-7.11-8.44s-7,3.5-7,8.44v43.89c0,4.94,2.17,8.44,7,8.44S413.29,151.45,413.29,146.51Z"/>
      <path id="_Compound_Path_4" data-name="&lt;Compound Path&gt;" class="cls-2" d="M477.18,105.64v37.85c0,12.54-5.18,21.95-19.89,21.95H436.07V83.7h21.22C472,83.7,477.18,93,477.18,105.64ZM455.6,154.22c5.67,0,7.84-3.37,7.84-8.32V103.11c0-4.83-2.17-8.2-7.84-8.2h-5.78v59.31Z"/>
      <path id="_Compound_Path_5" data-name="&lt;Compound Path&gt;" class="cls-2" d="M531.92,129.39H518.66v36.05H504.91V83.7h13.75v34.6h13.26V83.7h13.86v81.74H531.92Z"/>
      <path id="_Compound_Path_6" data-name="&lt;Compound Path&gt;" class="cls-2" d="M554.82,83.7h32.8V94.91h-19V118.3h13.62v11.09H568.57v24.83h19v11.22h-32.8Z"/>
      <path id="_Compound_Path_7" data-name="&lt;Compound Path&gt;" class="cls-2" d="M594,165.44,609.44,83.7h15.67l15.55,81.74H626.55L624,150.49H609.44L607,165.44Zm17.24-25.92h11l-5.42-32.8h-.12Z"/>
      <path id="_Compound_Path_8" data-name="&lt;Compound Path&gt;" class="cls-2" d="M647.53,83.7h13.74v70.52h16.4v11.22H647.53Z"/>
      <path id="_Compound_Path_9" data-name="&lt;Compound Path&gt;" class="cls-2" d="M675.74,83.7h36.53V94.91H700.94v70.53H687.07V94.91H675.74Z"/>
      <path id="_Compound_Path_10" data-name="&lt;Compound Path&gt;" class="cls-2" d="M746.87,129.39H733.61v36.05H719.87V83.7h13.74v34.6h13.26V83.7h13.86v81.74H746.87Z"/>
    </g>
    <g id="_Group_3" data-name="&lt;Group&gt;">
      <path id="_Compound_Path_11" data-name="&lt;Compound Path&gt;" class="cls-2" d="M283.43,285.29l15.43-81.74h15.67l15.55,81.74H316l-2.53-15H298.86l-2.41,15Zm17.24-25.92h11l-5.43-32.79h-.12Z"/>
      <path id="_Compound_Path_12" data-name="&lt;Compound Path&gt;" class="cls-2" d="M348.53,233.93v51.36H337V203.55h13.51l14.82,46.9v-46.9h11.46v81.74H364.68Z"/>
      <path id="_Compound_Path_13" data-name="&lt;Compound Path&gt;" class="cls-2" d="M426.89,225.5v37.85c0,12.54-5.18,21.94-19.89,21.94H385.78V203.55H407C421.71,203.55,426.89,212.84,426.89,225.5Zm-21.58,48.58c5.67,0,7.84-3.37,7.84-8.32V223c0-4.82-2.17-8.19-7.84-8.19h-5.79v59.31Z"/>
      <path id="_Compound_Path_14" data-name="&lt;Compound Path&gt;" class="cls-2" d="M479.45,234.78l-7.71,50.51h-12.9l-11-81.74h13.26l6.39,51.84h.12l6.87-51.84h11.21l7.6,51.84h.12l6.27-51.84h11.57l-10.61,81.74h-13l-8.08-50.51Z"/>
      <path id="_Compound_Path_15" data-name="&lt;Compound Path&gt;" class="cls-2" d="M518.39,203.55h32.79v11.22h-19v23.38h13.62v11.1H532.14v24.83h19v11.21H518.39Z"/>
      <path id="_Compound_Path_16" data-name="&lt;Compound Path&gt;" class="cls-2" d="M559.74,203.55h13.75v70.53h16.39v11.21H559.74Z"/>
      <path id="_Compound_Path_17" data-name="&lt;Compound Path&gt;" class="cls-2" d="M597.36,203.55H611.1v70.53h16.4v11.21H597.36Z"/>
      <path id="_Compound_Path_18" data-name="&lt;Compound Path&gt;" class="cls-2" d="M625.69,237H645V248H625.69Z"/>
      <path id="_Compound_Path_19" data-name="&lt;Compound Path&gt;" class="cls-2" d="M653.18,203.55h19.89c13.74,0,18.32,6.39,18.32,18v8.32c0,6.87-2.53,11.21-8.19,13,6.87,1.56,10.24,6.14,10.24,13.62v10.85c0,11.57-5.18,18-18.92,18H653.18ZM666.92,238h4.82c4.46,0,6.51-2.29,6.51-6.63v-10c0-4.46-2-6.63-6.63-6.63h-4.7Zm0,10.49v25.56h6c4.58,0,6.75-2.29,6.75-6.63v-12.3c0-4.34-2.05-6.63-6.87-6.63Z"/>
      <path id="_Compound_Path_20" data-name="&lt;Compound Path&gt;" class="cls-2" d="M701.52,203.55h32.79v11.22h-19v23.38h13.63v11.1H715.26v24.83h19v11.21H701.52Z"/>
      <path id="_Compound_Path_21" data-name="&lt;Compound Path&gt;" class="cls-2" d="M742.87,203.55h13.74v81.74H742.87Z"/>
      <path id="_Compound_Path_22" data-name="&lt;Compound Path&gt;" class="cls-2" d="M777.35,233.93v51.36H765.78V203.55h13.5l14.83,46.9v-46.9h11.45v81.74h-12Z"/>
      <path id="_Compound_Path_23" data-name="&lt;Compound Path&gt;" class="cls-2" d="M835.34,241.65h19.53v43.64H845v-8.92c-1.81,6.15-5.66,9.89-13.14,9.89-11.81,0-17.24-9.41-17.24-22.07V224.65c0-12.78,6.15-22.06,20.62-22.06,15.19,0,19.65,8.44,19.65,20.49v7.24H842.33V222c0-5.06-1.93-8-6.87-8s-7.12,3.5-7.12,8.44v43.89c0,4.94,2,8.43,6.76,8.43,4.46,0,6.75-2.53,6.75-7.83V252.5h-6.51Z"/>
    </g>
  </g>
</svg>`;
