<template>
  <article id="giz-mix-teaser" v-if="selectedMix !== null">
    <h3>{{ selectedMix.genericName }}</h3>
    <h2>{{ selectedMix.genericTarget }}</h2>
    <div v-html="selectedMix.description " />
  </article>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "GizMixTeaser",
  computed: {
    ...mapGetters([
      'selectedMix',
    ]),
  },
}
</script>

<style lang="scss">
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';
  
  #giz-mix-teaser {
    & > div {
      @include media-breakpoint-up(md) {
        column-count: 2;
        column-width: $font-size-base;
      }
    }
    h3 {
      font-weight: 400;
      text-transform: uppercase;
      margin: 0;
    }
    h2 {
      font-size: $h3-font-size;
      font-weight: 700;
      margin-bottom: 30px;
      text-transform: none;
    }
    h2, h3 {      
      line-height: $h3-font-size + .4rem;
    }
  }
</style>