<template>
  <div id="giz-how-to-menu">
    <giz-how-link>How to use the Toolkit</giz-how-link>
    <giz-about-link>About the Multidimensional <br>Inequality Framework</giz-about-link>
    <giz-context-link>General Context Information</giz-context-link>
  </div>
</template>

<script>
import GizAboutLink from './AboutLink';
import GizHowLink from './HowLink';
import GizContextLink from './ContextLink';

export default {
  name: "GizHowToMenu",
  components: {
    GizAboutLink,
    GizHowLink,
    GizContextLink
  }
};
</script>

<style lang="scss">
  #giz-how-to-menu {
    margin-bottom: -22px;
  }
  #giz-how-to-menu .link,
  #giz-how-to-menu a {
    font-weight: bold;
    display: block;
    line-height: 22px;
    margin-bottom: 22px;
  }
</style>
