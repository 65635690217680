<template>
  <div :class="[ 'giz-sdg-link', 'link', ...classes ]" 
    role="button"
    tabindex="0"
    @click.stop="click()"
    @keydown.stop="keyDown($event)"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: "GizSdgLink",
  methods: {
    ...mapMutations([
      'storeModalSdg',
      'toggleModalSdg'
    ]),
    click: function() {
      this.storeModalSdg(this.sdg.id);
      this.toggleModalSdg();
    },
    keyDown: function(event) {
      if(event.which === 27 || event.which === 13) {
        this.click();
      }
    }
  },
  props: {
    sdg: Object,
    classes: {
      type: Array,
      default: () => []
    }
  }
}
</script>