export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect class="cls-1" width="1000" height="1000"/>
  <path class="cls-2" d="M151.31,288.09c36,0,49.95-19.64,49.95-50.87V207.48c0-33-13.08-49.66-37.48-49.66-14.27,0-22.89,6.56-27.95,16.37h-.29V130.77c0-12.21,3.87-20.82,16.06-20.82s15.76,7.14,15.76,19.63V143h30.93V132.25c0-29.74-9.82-50.55-47.29-50.55-35.68,0-49.07,22.89-49.07,54.41v98.14c0,31.23,13.68,53.84,49.38,53.84m-15.77-84.77c.59-11.29,5.35-18.43,16.06-18.43,11.59,0,16.05,7.14,16.05,20.23v35.09c0,12.78-4.74,20.22-16.34,20.22-11.32,0-15.77-7.73-15.77-20.22Z"/>
  <path class="cls-2" d="M306.49,165.73c14.59,0,19.4-8.67,19.4-20.38V131.24H313.35v15.08c0,4.82-1.67,8-6.63,8s-7-3.49-7-8.44v-43.9c0-4.94,2.07-8.44,7-8.44s6.63,2.89,6.63,8v10.73h12.54v-9.65c0-12.05-4.21-20.5-19.4-20.5C292,82,286,91.32,286,104.11v39.55c0,12.67,6,22.07,20.5,22.07"/>
  <polygon class="cls-2" points="364.73 153.56 348.34 153.56 348.34 83 334.57 83 334.57 164.78 364.73 164.78 364.73 153.56"/>
  <polygon class="cls-2" points="404.99 153.56 385.94 153.56 385.94 128.72 399.56 128.72 399.56 117.61 385.94 117.61 385.94 94.22 404.99 94.22 404.99 83 372.2 83 372.2 164.78 404.99 164.78 404.99 153.56"/>
  <path class="cls-2" d="M426.82,149.82H441.4l2.54,15H458L442.47,83H426.82l-15.44,81.77h13ZM434.05,106h.12l5.43,32.79h-11Z"/>
  <polygon class="cls-2" points="476.49 113.4 492.64 164.78 504.69 164.78 504.69 83 493.24 83 493.24 129.92 478.42 83 464.91 83 464.91 164.78 476.49 164.78 476.49 113.4"/>
  <polygon class="cls-2" points="555.76 164.77 563.48 114.24 563.59 114.24 571.67 164.77 584.7 164.77 595.3 83.01 583.74 83.01 577.46 134.86 577.34 134.86 569.75 83.01 558.54 83.01 551.67 134.86 551.55 134.86 545.15 83.01 531.88 83.01 542.87 164.77 555.76 164.77"/>
  <path class="cls-2" d="M610.91,149.82h14.6l2.52,15h14.11L626.58,83H610.91L595.5,164.77h13ZM618.16,106h.11l5.43,32.79h-11Z"/>
  <polygon class="cls-2" points="651.75 164.77 665.62 164.77 665.62 94.22 676.95 94.22 676.95 83 640.42 83 640.42 94.22 651.75 94.22 651.75 164.77"/>
  <polygon class="cls-2" points="716.22 153.56 697.17 153.56 697.17 128.72 710.79 128.72 710.79 117.61 697.17 117.61 697.17 94.22 716.22 94.22 716.22 83 683.43 83 683.43 164.78 716.22 164.78 716.22 153.56"/>
  <path class="cls-2" d="M737.41,127.25l12.89,37.53h14.48l-13.15-37.16c7.84-1.79,11-7,11-15.79V101.34c0-11.46-5.06-18.34-18.93-18.34h-20v81.78h13.74Zm0-33H742c4.68,0,6.74,2.17,6.74,6.62V112.9c0,4.35-2.06,6.77-6.74,6.77h-4.59Z"/>
  <path class="cls-2" d="M299.82,270.38h14.59l2.53,14.95H331l-15.57-81.76H299.82l-15.43,81.76h13Zm7.23-43.78h.14l5.41,32.81h-11Z"/>
  <polygon class="cls-2" points="336.69 203.57 336.69 285.35 348.26 285.35 348.26 233.97 364.41 285.35 376.45 285.35 376.45 203.57 365.02 203.57 365.02 250.49 350.19 203.57 336.69 203.57"/>
  <path class="cls-2" d="M386.52,285.34h21.2c14.7,0,19.89-9.41,19.89-21.95V225.52c0-12.65-5.19-21.94-19.89-21.94h-21.2Zm13.73-70.56H406c5.68,0,7.83,3.38,7.83,8.21v42.82c0,4.93-2.15,8.31-7.83,8.31h-5.78Z"/>
  <path class="cls-2" d="M458.15,256.76v11c0,10.72,5.44,18.58,19.41,18.58s20-8,20-19.19v-4c0-9-3.6-13.63-11.07-21.23l-8.81-8.68c-4-3.85-6.51-6.52-6.51-11.08v-1.71c0-4.58,2.65-6.74,6.51-6.74,4.34,0,6.51,2.29,6.51,7v7.13h12.54v-6.65c0-11.82-5.42-18.57-19.17-18.57-13.38,0-19.28,7.84-19.28,18v2.76c0,9.41,3.62,14.12,11.45,21.83l8.07,8.09c4.22,4,6.14,6.87,6.14,11.56V268c0,4.34-1.92,7.24-6.26,7.24-4.69,0-6.74-2.65-6.74-7.24V256.76Z"/>
  <path class="cls-2" d="M514.52,285.34l2.41-15h14.59l2.53,15h14.1l-15.56-81.76H516.93l-15.42,81.76Zm9.65-58.72h.12l5.42,32.79h-11Z"/>
  <polygon class="cls-2" points="583.24 250.49 568.41 203.58 554.9 203.58 554.9 285.35 566.49 285.35 566.49 233.97 582.63 285.35 594.68 285.35 594.68 203.58 583.24 203.58 583.24 250.49"/>
  <rect class="cls-2" x="605.85" y="203.57" width="13.75" height="81.77"/>
  <polygon class="cls-2" points="651.02 285.35 651.02 214.79 662.36 214.79 662.36 203.59 625.84 203.59 625.84 214.79 637.16 214.79 637.16 285.35 651.02 285.35"/>
  <path class="cls-2" d="M690.31,203.58H674.66l-15.44,81.76h13l2.43-14.95h14.57l2.53,14.95h14.1Zm-13.86,55.83,5.44-32.79H682l5.43,32.79Z"/>
  <polygon class="cls-2" points="738.01 203.58 701.49 203.58 701.49 214.79 712.83 214.79 712.83 285.35 726.7 285.35 726.7 214.79 738.01 214.79 738.01 203.58"/>
  <rect class="cls-2" x="745.52" y="203.57" width="13.74" height="81.77"/>
  <path class="cls-2" d="M788.91,286.32c14.59,0,20.84-9.41,20.84-22.08V224.68c0-12.79-6.25-22.07-20.84-22.07s-20.75,9.28-20.75,22.07v39.56c0,12.67,6.29,22.08,20.75,22.08m-7-63.81c0-4.94,2.16-8.44,7-8.44s7.11,3.5,7.11,8.44v43.9c0,4.94-2.17,8.44-7.11,8.44s-7-3.5-7-8.44Z"/>
  <polygon class="cls-2" points="858.47 285.35 858.47 203.59 847.02 203.59 847.02 250.49 832.19 203.59 818.68 203.59 818.68 285.35 830.26 285.35 830.26 233.96 846.41 285.35 858.47 285.35"/>
  <path class="cls-2" d="M684.65,404.36a4.38,4.38,0,0,0-3.28-1.43H318.57a4.09,4.09,0,0,0-4.17,4.86l61,368.63a4.26,4.26,0,0,0,4.18,3.55h74.81l5.12,60-31.48,0,73.25,73.24,73.42-73.41-31.47,0L548.29,780h76.13a4.18,4.18,0,0,0,4.16-3.62l57-368.56A4.36,4.36,0,0,0,684.65,404.36ZM544.43,699.47a58.2,58.2,0,0,1-43.29,15.1,58.23,58.23,0,0,1-43.34-15.1c-27.18-27.15-17.48-60.93-8-78.39,5.84-10.93,47.6-73.64,51.2-79v-.42l.16.2.14-.2v.42c3.5,5.34,45.33,68,51.17,79C561.92,638.54,571.59,672.32,544.43,699.47ZM659.52,481.92c-9.83-4.33-22.3-2.77-34.26,2.07-45,18.25-75.66,24.81-123,10-29.82-9.34-57.72-28.88-90.69-17-21.89,7.87-42.28,15.38-67,15.19l-14.29-73.26H669.79Z"/>
</svg>`;
