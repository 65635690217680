export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect class="cls-1" width="999.95" height="1000"/>
  <path class="cls-2" d="M302.77,166.41c7.49,0,11.35-3.74,13.15-9.88v8.91h9.88V121.79H306.28v10.85h6.51v14.48c0,5.3-2.3,7.83-6.76,7.83-4.7,0-6.75-3.5-6.75-8.44V102.6c0-4.92,2.17-8.43,7.12-8.43s6.87,2.89,6.87,8v8.32H325.8v-7.24c0-12.06-4.46-20.51-19.64-20.51-14.47,0-20.61,9.29-20.61,22.07v39.56c0,12.66,5.41,22.07,17.22,22.07"/>
  <polygon class="cls-2" points="367.63 154.23 348.59 154.23 348.59 129.38 362.22 129.38 362.22 118.28 348.59 118.28 348.59 94.9 367.63 94.9 367.63 83.67 334.85 83.67 334.85 165.45 367.63 165.45 367.63 154.23"/>
  <polygon class="cls-2" points="387.78 114.07 403.92 165.45 415.98 165.45 415.98 83.67 404.54 83.67 404.54 130.59 389.7 83.67 376.21 83.67 376.21 165.45 387.78 165.45 387.78 114.07"/>
  <path class="cls-2" d="M466.13,143.49V105.63c0-12.66-5.18-22-19.89-22H425v81.78h21.2c14.71,0,19.89-9.41,19.89-22m-27.35,10.74V94.89h5.79c5.66,0,7.82,3.38,7.82,8.2v42.82c0,4.94-2.16,8.32-7.82,8.32Z"/>
  <polygon class="cls-2" points="507.97 154.23 488.94 154.23 488.94 129.38 502.56 129.38 502.56 118.28 488.94 118.28 488.94 94.9 507.97 94.9 507.97 83.67 475.19 83.67 475.19 165.45 507.97 165.45 507.97 154.23"/>
  <path class="cls-2" d="M530.17,127.93,543,165.45h14.47L544.38,128.3c7.83-1.81,11-7,11-15.8V102c0-11.46-5.06-18.34-18.92-18.34h-20v81.78h13.75Zm0-33h4.56c4.71,0,6.75,2.16,6.75,6.62v12c0,4.36-2,6.76-6.75,6.76h-4.56Z"/>
  <polygon class="cls-2" points="318.33 274.11 299.29 274.11 299.29 249.27 312.91 249.27 312.91 238.18 299.29 238.18 299.29 214.78 318.33 214.78 318.33 203.56 285.55 203.56 285.55 285.33 318.33 285.33 318.33 274.11"/>
  <path class="cls-2" d="M342.46,285.32v5.56l25.9,4.82V285.45l-10.12-1.94c7-3.37,10.12-10.36,10.12-19.29V224.66c0-12.78-6.26-22.07-20.85-22.07s-20.72,9.29-20.72,22.07v39.56c0,11.1,4.7,19.18,15.67,21.1m-1.94-62.82c0-4.94,2.17-8.45,7-8.45s7.11,3.51,7.11,8.45v44.63c0,4.82-2.16,8.43-7.11,8.43s-7-3.61-7-8.43Z"/>
  <path class="cls-2" d="M377.29,203.56v61.87c0,12.66,6,20.86,20.49,20.86,14,0,19.41-8.2,19.41-20.86V203.56H404.54v63.19c0,4.83-1.93,8.08-6.76,8.08s-6.75-3.25-6.75-8.08V203.56Z"/>
  <path class="cls-2" d="M439.38,270.37H454l2.53,15h14.1l-15.55-81.77H439.38L424,285.33h13Zm7.22-43.77h.14l5.41,32.8h-11Z"/>
  <polygon class="cls-2" points="477.36 203.56 477.36 285.33 507.49 285.33 507.49 274.12 491.11 274.12 491.11 203.56 477.36 203.56"/>
  <rect class="cls-2" x="514.85" y="203.56" width="13.75" height="81.77"/>
  <polygon class="cls-2" points="561.26 285.33 561.26 214.78 572.58 214.78 572.58 203.57 536.08 203.57 536.08 214.78 547.4 214.78 547.4 285.33 561.26 285.33"/>
  <polygon class="cls-2" points="593.08 285.33 606.83 285.33 606.83 254.22 622 203.57 608.88 203.57 600.56 234.55 600.44 234.55 592.13 203.57 577.78 203.57 593.08 254.22 593.08 285.33"/>
  <path class="cls-2" d="M159.44,147.64c-13.38,0-23.19,4.75-28.55,14h-.3V111.36h58.29V83.7H99.68V197.31h30.91v-4.48c0-11.89,5.36-18.44,14.87-18.44,10.41,0,14.59,7.15,14.59,18.16v48.77c0,10.71-3.88,18.13-14.29,18.13-11.6,0-15.75-7.73-15.75-19.61V214.55H99.06v22.9c0,30.63,12.2,50.26,46.7,50.26,34.2,0,48.17-18.14,48.17-46.1V192.25c0-31.82-16.35-44.61-34.49-44.61"/>
  <g>
    <path class="cls-2" d="M728.35,378.38H604.07a1.6,1.6,0,0,0-1.58,1.59v41.29a1.59,1.59,0,0,0,1.58,1.59h49.48l-54.95,55a161.9,161.9,0,0,0-100.35-34.76c-89.75,0-162.78,73-162.78,162.78,0,82.64,61.91,151,141.77,161.37v47.59h-49a1.57,1.57,0,0,0-1.6,1.57v44.27a1.57,1.57,0,0,0,1.6,1.58h49V909a1.57,1.57,0,0,0,1.58,1.58h44.24a1.59,1.59,0,0,0,1.6-1.58V862.21h49a1.57,1.57,0,0,0,1.57-1.58V816.36a1.57,1.57,0,0,0-1.57-1.57h-49V766.43C601.89,753.76,661,686.59,661,605.83a161.84,161.84,0,0,0-29.73-93.62L685.45,458v46.22a1.6,1.6,0,0,0,1.61,1.58h41.29a1.6,1.6,0,0,0,1.61-1.58V380a1.6,1.6,0,0,0-1.61-1.59M498.25,725.65A119.82,119.82,0,1,1,618.07,605.83,119.94,119.94,0,0,1,498.25,725.65"/>
    <path class="cls-2" d="M572.65,556.65H427.32a1.52,1.52,0,0,0-1.58,1.42v35.18a1.5,1.5,0,0,0,1.58,1.44H572.65a1.51,1.51,0,0,0,1.61-1.44V558.07a1.53,1.53,0,0,0-1.61-1.42"/>
    <path class="cls-2" d="M572.65,619.47H427.32a1.54,1.54,0,0,0-1.58,1.47v35.18a1.5,1.5,0,0,0,1.58,1.42H572.65a1.51,1.51,0,0,0,1.61-1.42V620.94a1.55,1.55,0,0,0-1.61-1.47"/>
  </g>
</svg>`;
