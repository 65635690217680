<template>
  <div id="giz-domain-filter-legend">
    <div
      type="button"
      role="button"
      tabindex="0"
      v-for="domain in visibleDomains"
      :key="domain.id"
      :class="[ 'btn', 'btn-link', { 'selected': isDomainSelected(domain.id), 'hover': isDomainHovered(domain.id), 'filtered': hasFilter } ]"
      @click="domainClick(domain.id)"
      @keydown="domainKeyDown($event, domain.id)"
      @mouseenter="storeHoveredDomains([domain.id])"
      @mouseleave="storeHoveredDomains()"
      @focus="storeHoveredDomains([domain.id]); lastFocus=domain.id"
      @blur="storeHoveredDomains()"
    >
      <img :src="domainLegendIcons[domain.feid]" alt="domain legend icon"/>
      <span class="caption">{{ domain.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { domainLegendIcons } from '../config.js';

Object.freeze(domainLegendIcons);

export default {
  name: "GizDomainFilterLegend",
  data: function() {
    return {
      domainLegendIcons: domainLegendIcons,
      // accessibility
      lastFocus: ''
    }
  },
  computed: {
    ...mapGetters([
      'domains',
      // filter actions
      'isDomainSelected',
      'isDomainHovered',
      'hasFilter',
      'hasMixFilter',
      'hasDomainFilter',
      'isModalDomainVisible'
    ]),
    visibleDomains: function() {
      return this.hasFilter ?
        this.domains.filter(domain => this.isDomainSelected(domain.id)) :
        this.domains;
    }
  },
  methods: {
    ...mapMutations([
      'storeSelectedDomains',
      'storeHoveredDomains',
      // modal
      'storeModalDomain',
      'toggleModalDomain'
    ]),
    domainClick: function (id) {
      this.storeHoveredDomains();
      this.storeModalDomain(id);
      this.toggleModalDomain();
    },
    // accessibility
    domainKeyDown: function(event, id) {
      if(event.which === 13) {
        let restoreLastFocus = this.isModalDomainVisible;
        this.domainClick(id);
        if (restoreLastFocus) {
          this.storeHoveredDomains([this.lastFocus]);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  #giz-domain-filter-legend {
    margin-bottom: -22px;
    @include media-breakpoint-up(md) {
      column-count: 2;
      column-width: $font-size-base;
    }
  }
  #giz-domain-filter-legend .btn {
    position: relative;
    font-size: 1.125rem;
    font-weight: bold;
    margin: 0 0 22px 0;
    padding: 0 0 0 17px;
    cursor: pointer;
    display: block;
    text-align: left;
    border: 0;
    line-height: 1.3;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }
    &.hover {
      text-decoration: underline;
    }
    &:focus {
      box-shadow: none !important;
      font-weight: 400;
    }

    img {
      position: absolute;
      width: 10px;
      top: 5px;
      left: 0;
    }
  }
</style>
