<template>
  <div id="giz-how-modal" :class="['modal-content', visibilityClass ]">
    <button class="close-btn btn-reset" @click="vissibleModalHow()" ref="closeBtn">
      <span class="giz-icon cross" aria-hidden="true"></span>
    </button>
    <article>
      <b-container>
        <b-row>
          <b-col class="col-12 col-lg-7">
            <h1>Introduction</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 col-lg-7">
            <p>
              The Inequality Policy Mix Toolkit (IPMT) has been designed to help users develop an informed policy approach to tackling inequalities in low- and middle-income countries. It draws on the Multidimensional Inequality Framework (MIF), developed jointly by the Centre for Analysis of Social Exclusion (CASE) at the London School of Economics, the School of Oriental and African Studies (SOAS) as well as Oxfam. This multidimensional approach was used to identify a series of inequality-reduction policies which address key quality-of-life aspects. In the toolkit, policies are organised according to the seven domains of the MIF and the Sustainable Development Goals (SDGs) of the 2030 Agenda. 
            </p>
            <p>
              An important and novel feature of the IPMT is the identification of ‘policy mixes’, which are combinations of policies likely to be more effective together than if introduced separately.
            </p>
            <h3>1. What is a policy?</h3>
            <p>
              In total, the IPMT contains 16 inequality-reduction policies (clearly this pragmatic list of policies is not exhaustive). All comprised policies summarize information on inequalities that they seek to reduce, evidence of their effectiveness (based on a literature review), potential challenges as well as facilitating factors for their introduction, along with a broad assessment of potential costs.
            </p>
            <h3>2. What is a domain?</h3>
            <p>
              In the toolkit, inequality is measured in individual wellbeing, reflecting the fact that our lives have many important dimensions: our health, relationships, safety, ability to have influence or financial security, among others. Therefore, the policies are organized around the seven key life domains of the MIF critical to enjoying a self-determined life worth living.
            </p>
            <h3>3. What is a policy mix?</h3>
            <p>
              A policy mix is a combination of policies likely to be more effective in combination than if introduced separately. In total, four policy mixes are assessed. Each policy mix consists of three to six policies belonging to different key life domains of the MIF.
            </p>
            <button class="btn-outline d-none d-lg-inline-block" @click="vissibleModalHow()">let’s get started!</button>
          </b-col>
          <b-col class="col-12 col-lg-5 col-xl-4 ml-auto">
            <div v-if="firstPolicy !== null">
              <h4>a. Policies</h4>
              <div class="giz-policy-teaser" :class="[ firstPolicyDomain.feid ]">
                <div class="info">{{ firstPolicy.genericName }}</div>
                <div class="title">{{ firstPolicy.title }}</div>
                <div class="domain">
                  <template v-if="firstPolicyDomain">
                    <div class="info">{{ firstPolicyDomain.genericName }}:</div>
                    <div class="title">{{ firstPolicyDomain.name }}</div>
                  </template>
                  <div class="giz-policy-download"><span class="giz-icon download" aria-hidden="true"></span>pdf</div>
                  <div class="giz-sdg-link btn-reset btn btn-link sdg" v-if="firstPolicySdg !== null">
                    <div v-html="goalIcons[firstPolicySdg.feid]" :class="firstPolicySdg.feid" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="mixes.length > 0">
              <h4>b. Policy mixes</h4>
              <div
                
                v-for="mix in mixes"
                :key="mix.id"
              >
                <div class="btn-mix">
                  <img :src="mixesIcons[mix.feid]" alt="policy mix icon" />
                  <span class="caption">{{ mix.genericName }}</span><br>
                  <span class="sub-caption">{{ mix.name }}</span>
                </div>
              </div>
            </div>
            <div>
              <h4>c. Domains</h4>
              <div class="domain-wheel">
                <img :src="domainWheel" alt="domain wheel"/>
                <div>domains</div>
              </div>
            </div>
            <button class="btn-outline d-block d-lg-none" @click="vissibleModalHow()">let’s get started!</button>
          </b-col>
        </b-row>
      </b-container>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { modalVisibility } from '../mixins/modal.js';
import { mixesIcons, domainWheel, goalIcons } from '../config.js';

Object.freeze(mixesIcons);
Object.freeze(goalIcons);

export default {
  name: "GizHowModal",
  data: function() {
    return {
      mixesIcons: mixesIcons,
      domainWheel: domainWheel,
      goalIcons: goalIcons
    }
  },
  mounted: function () {
    this.vissibleModalHow(true);
  },
  computed: {
    ...mapGetters([
      'domain',
      'sdg',
      'policies',
      'mixes',
      'isModalHowVisible',
    ]),
    firstPolicy: function() {
      if (this.policies.length > 0) {
        return this.policies[0];
      }
      return null;
    },
    firstPolicyDomain: function() {
      return this.domain(this.firstPolicy.domain[0]);
    },
    firstPolicySdg: function() {
      if (this.firstPolicy.sdgs.length > 0) {
        return this.sdg(this.firstPolicy.sdgs[0]);
      }
      return null;
    },
    firstPolicyClass: function() {
      return this.firstPolicyDomain ? this.firstPolicyDomain.feid : '';
    }
  },
  methods: {
    ...mapMutations([
      'vissibleModalHow'
    ])
  },
  watch: {
    isModalHowVisible: function (visibility) {
      this.modalVisibility(visibility);
    },
  },
  mixins: [ modalVisibility ]
}
</script>

<style lang="scss">
  @import "../assets/scss/functions";
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  #giz-how-modal {
    background: map-get($colors, modal-how-background);
    padding: 47px 0 52px 0;

    button.close-btn {
      top: 19px;
      right: 18px;
    }

    article {
      @include media-breakpoint-up(lg) {
        margin: 0 (-1 * $grid-gutter-width / 2);
      }
      & > .container > .row > .col {
        @include media-breakpoint-up(lg) {
          padding-left: 86px + $grid-gutter-width / 2;
        }

        & + .col {
          // right col
          padding-top: 30px;

          @include media-breakpoint-up(lg) {
            padding-left: $grid-gutter-width / 2;
            padding-right: 83px + $grid-gutter-width / 2;
            padding-top: 0;
          }

          h4 {
            font-size: 1.13rem;
            font-weight: 700;
            margin-bottom: 16px;
          }
          & > div {
            // right col box
            margin-bottom: 44px;
          }
        }
      }

      h1 {
        font-size: 1.688rem;
        line-height: 1.2;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 37px;
      }
      .btn-outline {
        margin-top: 50px;
        width: 100%;
        @include media-breakpoint-up(lg) {
          width: auto;
        }
      }
      .btn-mix {
        box-shadow: none;
        font-size: .83rem;
        line-height: 1.2;
        padding-left: 44px;
        display: inline-block;
        width: auto;
        margin: 0 0 10px 0;

        @include media-breakpoint-up(lg) {
          width: 100%;  
        }
        img {
          top: 6px;
          left: 6px;
          width: 30px;
        }
      }
      .giz-policy-teaser {
        font-size: .7rem;
        line-height: 1.1;
        max-width: 130px;
        cursor: auto;
        flex: 0 1 162px;
        min-height: 162px;
        text-transform: uppercase;
        padding: 13px 13px 13px 15px;

        &:after {
          width: 8px;
        }

        .giz-policy-download,
        .domain {
          font-size: .7rem;
        }
        .giz-policy-download {
          margin-top: 7px;
        }
        .domain {
          padding-right: 40px;
        }

        &:focus,
        &:hover {
          & > .info,
          & > .title {
            text-decoration: none;
          }
        }
        
        .btn.sdg {
          cursor: auto;
          width: 60px;
          height: 60px;
          &:focus,
          &:hover {
            svg .cls-2 {
              fill: #fff;
            }
          }
        }
      }
      .domain-wheel {
        position: relative;
        max-width: 200px;
        
        img {
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, .25));
        }
        & > div {
          font-size: .9rem;
          font-weight: 700;
          text-transform: uppercase;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
</style>