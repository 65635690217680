<template>
  <div class="giz-about-link link" 
    @click="toggleModalAbout()"
    @keydown="keyDown($event)"
    tabindex="0"
    role="button"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: "GizAboutLink",
  methods: {
    ...mapMutations([
      'toggleModalAbout'
    ]),
    keyDown: function(event) {
      if(event.which === 27 || event.which === 13) {
        this.toggleModalAbout();
      }
    }
  },
}
</script>

<style lang="scss">
  .giz-about-link {
    cursor: pointer;
  }
</style>