<template>
  <div id="giz-modal-background" :class="[ 'modal-background', visibilityClass, colorClass ]" @click="modalCloseAll()"></div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { modalVisibility } from '../mixins/modal.js';

export default {
  name: "GizModalBackground",
  computed: {
    ...mapGetters([
      'isModalPolicyVisible',
      'isModalVisible'
    ]),
    colorClass: function() {
      return this.isModalPolicyVisible ? 'white' : '';
    }
  },
  watch: {
    isModalVisible: function (visibility) {
      this.modalVisibility(visibility);
    },
  },
  methods: {
    ...mapMutations([
      'modalCloseAll'
    ])
  },
  mixins: [ modalVisibility ]
}
</script>

<style lang="scss">
</style>