<template>
  <button :class="[ 'giz-policy-link', 'btn-reset', ...classes ]" type="button" @click.stop="click()">
    <slot></slot>
  </button>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: "GizPolicyLink",
  methods: {
    ...mapMutations([
      'storeModalPolicy',
      'vissibleModalPolicy'
    ]),
    click: function() {
      this.storeModalPolicy(this.policy.id);
      this.vissibleModalPolicy(true);
    }
  },
  props: {
    policy: Object,
    classes: {
      type: Array,
      default: () => []
    }
  }
}
</script>