<template>
  <div id="app">
    <giz-loading-screen :debug="debugPreloader" v-if="showLoadingScreen"/>
    <template v-if="!loading">
      <giz-filter />
      <giz-policy-list />
      <giz-modal-background />
      <giz-domain-modal />
      <giz-sdg-modal />
      <giz-policy-modal />
      <giz-about-modal />
      <giz-how-modal />
      <giz-context-modal />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GizFilter from './components/Filter';
import GizPolicyList from './components/PolicyList';
import GizModalBackground from './components/ModalBackground';
import GizDomainModal from './components/DomainModal';
import GizSdgModal from './components/SdgModal';
import GizPolicyModal from './components/PolicyModal';
import GizAboutModal from './components/AboutModal';
import GizHowModal from './components/HowModal';
import GizContextModal from './components/ContextModal';
import GizLoadingScreen from './components/LoadingScreen';

export default {
  name: "App",
  data: function() {
    return {
      showLoadingScreen: false,
      debugPreloader: false, // activate to keep preloader open until u click on it
    }
  },
  computed: {
    ...mapState(['loading'])
  },
  created: function () {
    this.$store.dispatch('fetchMixes');
    this.$store.dispatch('fetchDomains');
    this.$store.dispatch('fetchSdgs');
    this.$store.dispatch('fetchPolicies');
    if (!this.debugPreloader) {
      setTimeout(() => this.showLoadingScreen = this.loading, 2000);
    } else {
      this.showLoadingScreen = true;
    }
  },
  components: {
    GizFilter,
    GizPolicyList,
    GizModalBackground,
    GizDomainModal,
    GizSdgModal,
    GizPolicyModal,
    GizAboutModal,
    GizHowModal,
    GizContextModal,
    GizLoadingScreen
  }
};
</script>

<style lang="scss">
</style>
