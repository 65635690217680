export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect class="cls-1" width="1000" height="1000"/>
  <path class="cls-2" d="M292.55,166.4c14,0,20-8,20-19.17v-4c0-9-3.62-13.62-11.09-21.22l-8.8-8.68c-4-3.86-6.5-6.51-6.5-11.11v-1.67c0-4.59,2.65-6.76,6.5-6.76,4.33,0,6.51,2.29,6.51,7v7.1h12.53v-6.62c0-11.81-5.41-18.57-19.16-18.57-13.39,0-19.29,7.84-19.29,18v2.79c0,9.39,3.62,14.1,11.45,21.81l8.07,8.1c4.22,4,6.15,6.86,6.15,11.56v3.13c0,4.35-1.93,7.24-6.26,7.24-4.7,0-6.75-2.65-6.75-7.24v-11.2H273.16v11c0,10.73,5.41,18.57,19.39,18.57"/>
  <path class="cls-2" d="M358.47,145.54V83.67H345.81v63.19c0,4.83-1.93,8.07-6.76,8.07s-6.74-3.24-6.74-8.07V83.67H318.56v61.87c0,12.66,6,20.86,20.49,20.86,14,0,19.42-8.2,19.42-20.86"/>
  <path class="cls-2" d="M404.88,147.23v-4c0-9-3.63-13.62-11.08-21.22L385,113.33c-4-3.85-6.52-6.51-6.52-11.08v-1.69c0-4.59,2.67-6.76,6.52-6.76,4.34,0,6.5,2.28,6.5,7v7.1H404v-6.62c0-11.83-5.41-18.57-19.16-18.57-13.38,0-19.29,7.84-19.29,18v2.77c0,9.4,3.63,14.11,11.47,21.83l8.06,8.09c4.22,4,6.14,6.86,6.14,11.56v3.15c0,4.32-1.92,7.23-6.26,7.23-4.7,0-6.75-2.66-6.75-7.23V136.85H365.47v11c0,10.73,5.41,18.56,19.4,18.56s20-8,20-19.17"/>
  <polygon class="cls-2" points="444.73 83.67 408.21 83.67 408.21 94.89 419.53 94.89 419.53 165.44 433.4 165.44 433.4 94.89 444.73 94.89 444.73 83.67"/>
  <path class="cls-2" d="M473.23,83.67H457.56l-15.43,81.76h13l2.41-14.94h14.58l2.54,14.94h14.11Zm-13.85,55.84,5.41-32.8h.13l5.43,32.8Z"/>
  <rect class="cls-2" x="494.33" y="83.68" width="13.74" height="81.77"/>
  <polygon class="cls-2" points="555.7 165.44 555.7 83.67 544.25 83.67 544.25 130.57 529.41 83.67 515.91 83.67 515.91 165.44 527.49 165.44 527.49 114.06 543.63 165.44 555.7 165.44"/>
  <path class="cls-2" d="M575.57,150.48h14.59l2.52,15h14.1L591.24,83.67H575.57l-15.43,81.76h13Zm7.22-43.78h.13l5.44,32.81h-11Z"/>
  <path class="cls-2" d="M649.43,110v-8.31c0-11.58-4.57-18-18.32-18h-19.9v81.77h21.34c13.75,0,18.92-6.39,18.92-18V136.62c0-7.48-3.36-12.07-10.24-13.63,5.67-1.81,8.2-6.15,8.2-13M625,94.89h4.71c4.57,0,6.63,2.16,6.63,6.63v10c0,4.35-2.06,6.64-6.52,6.64H625Zm12.77,52.7c0,4.34-2.16,6.64-6.74,6.64h-6V128.66h5.9c4.82,0,6.87,2.29,6.87,6.62Z"/>
  <polygon class="cls-2" points="670.87 83.67 657.13 83.67 657.13 165.44 687.25 165.44 687.25 154.22 670.87 154.22 670.87 83.67"/>
  <polygon class="cls-2" points="725.09 94.89 725.09 83.67 692.3 83.67 692.3 165.44 725.09 165.44 725.09 154.22 706.04 154.22 706.04 129.37 719.66 129.37 719.66 118.29 706.04 118.29 706.04 94.89 725.09 94.89"/>
  <path class="cls-2" d="M768.69,166.4c14.59,0,19.42-8.68,19.42-20.38V131.91H775.56V147c0,4.8-1.69,7.94-6.63,7.94s-7-3.49-7-8.44V102.61c0-5,2-8.45,7-8.45s6.63,2.89,6.63,8v10.74h12.55v-9.65c0-12.07-4.23-20.51-19.42-20.51-14.46,0-20.49,9.28-20.49,22.06v39.57c0,12.66,6,22.06,20.49,22.06"/>
  <rect class="cls-2" x="794.56" y="83.68" width="13.73" height="81.77"/>
  <polygon class="cls-2" points="838.75 165.44 838.75 94.89 850.06 94.89 850.06 83.67 813.55 83.67 813.55 94.89 824.87 94.89 824.87 165.44 838.75 165.44"/>
  <rect class="cls-2" x="855.43" y="83.68" width="13.73" height="81.77"/>
  <polygon class="cls-2" points="876.24 165.44 909.02 165.44 909.02 154.22 889.98 154.22 889.98 129.37 903.58 129.37 903.58 118.29 889.98 118.29 889.98 94.89 909.02 94.89 909.02 83.67 876.24 83.67 876.24 165.44"/>
  <path class="cls-2" d="M932.31,113.34c-4-3.87-6.49-6.51-6.49-11.11v-1.67c0-4.59,2.64-6.76,6.49-6.76,4.36,0,6.51,2.29,6.51,7v7.11h12.55v-6.63c0-11.83-5.43-18.57-19.18-18.57-13.38,0-19.27,7.84-19.27,18v2.79c0,9.39,3.6,14.1,11.44,21.81l8.07,8.1c4.24,4,6.16,6.86,6.16,11.56v3.13c0,4.35-1.92,7.24-6.28,7.24-4.7,0-6.75-2.65-6.75-7.24v-11.2H912.79v11c0,10.73,5.42,18.57,19.4,18.57s20-8,20-19.17v-4c0-9-3.65-13.62-11.1-21.22Z"/>
  <path class="cls-2" d="M288.79,203.69l-15.43,81.75h13l2.41-14.95h14.58l2.54,14.95H320l-15.55-81.75Zm1.81,55.83,5.42-32.8h.13l5.41,32.8Z"/>
  <polygon class="cls-2" points="355.22 250.59 340.39 203.69 326.89 203.69 326.89 285.45 338.47 285.45 338.47 234.06 354.61 285.45 366.67 285.45 366.67 203.69 355.22 203.69 355.22 250.59"/>
  <path class="cls-2" d="M396.94,203.67H375.72v81.78h21.22c14.71,0,19.88-9.4,19.88-21.95V225.63c0-12.66-5.17-22-19.88-22m6.14,62.24c0,4.94-2.17,8.32-7.83,8.32h-5.8V214.9h5.8c5.66,0,7.83,3.37,7.83,8.19Z"/>
  <path class="cls-2" d="M460.23,202.72c-14.47,0-20.5,9.28-20.5,22.07v39.56c0,12.65,6,22.06,20.5,22.06s19.4-8.69,19.4-20.39v-14.1H467.09V267c0,4.83-1.68,8-6.61,8s-7-3.49-7-8.44v-43.9c0-4.94,2.06-8.44,7-8.44s6.61,2.89,6.61,8v10.73h12.54v-9.65c0-12.06-4.21-20.49-19.4-20.49"/>
  <path class="cls-2" d="M509.07,202.72c-14.48,0-20.74,9.28-20.74,22.07v39.56c0,12.65,6.26,22.06,20.74,22.06s20.84-9.41,20.84-22.06V224.79c0-12.79-6.26-22.07-20.84-22.07m7.1,63.79c0,4.95-2.17,8.44-7.1,8.44s-7-3.49-7-8.44v-43.9c0-4.94,2.15-8.44,7-8.44s7.1,3.5,7.1,8.44Z"/>
  <polygon class="cls-2" points="564.03 249.75 563.91 249.75 555 203.67 538.97 203.67 538.97 285.44 550.29 285.44 550.29 232.38 550.53 232.38 560.78 285.44 567.16 285.44 576.45 232.38 576.81 232.38 576.81 285.44 589.35 285.44 589.35 203.67 571.99 203.67 564.03 249.75"/>
  <polygon class="cls-2" points="623.48 249.75 623.36 249.75 614.44 203.67 598.4 203.67 598.4 285.44 609.74 285.44 609.74 232.38 609.97 232.38 620.21 285.44 626.61 285.44 635.88 232.38 636.25 232.38 636.25 285.44 648.78 285.44 648.78 203.67 631.43 203.67 623.48 249.75"/>
  <path class="cls-2" d="M685.07,266.87c0,4.83-1.91,8.1-6.74,8.1s-6.75-3.27-6.75-8.1V203.69H657.83v61.84c0,12.68,6,20.88,20.5,20.88,14,0,19.4-8.2,19.4-20.88V203.69H685.07Z"/>
  <polygon class="cls-2" points="735.13 250.59 720.28 203.69 706.79 203.69 706.79 285.45 718.37 285.45 718.37 234.06 734.51 285.45 746.56 285.45 746.56 203.69 735.13 203.69 735.13 250.59"/>
  <polygon class="cls-2" points="71.65 120.39 71.65 155.77 71.94 155.77 95.73 131.09 96.04 131.09 96.04 285.43 129.94 285.43 129.94 83.8 102.87 83.8 71.65 120.39"/>
  <polygon class="cls-2" points="148.97 120.39 148.97 155.77 149.27 155.77 173.06 131.09 173.35 131.09 173.35 285.43 207.26 285.43 207.26 83.8 180.21 83.8 148.97 120.39"/>
  <rect class="cls-2" x="755.64" y="203.67" width="13.74" height="81.77"/>
  <polygon class="cls-2" points="776.86 214.89 788.19 214.89 788.19 285.44 802.05 285.44 802.05 214.89 813.38 214.89 813.38 203.67 776.86 203.67 776.86 214.89"/>
  <rect class="cls-2" x="820.98" y="203.67" width="13.75" height="81.77"/>
  <polygon class="cls-2" points="843.9 285.44 876.69 285.44 876.69 274.23 857.63 274.23 857.63 249.39 871.26 249.39 871.26 238.29 857.63 238.29 857.63 214.89 876.69 214.89 876.69 203.67 843.9 203.67 843.9 285.44"/>
  <path class="cls-2" d="M903.33,233.35c-4-3.87-6.51-6.52-6.51-11.09v-1.7c0-4.59,2.65-6.75,6.51-6.75,4.34,0,6.5,2.29,6.5,7v7.12h12.55v-6.64c0-11.82-5.43-18.56-19.17-18.56-13.39,0-19.29,7.84-19.29,18v2.77c0,9.4,3.62,14.11,11.46,21.82l8.08,8.09c4.21,4,6.14,6.87,6.14,11.57v3.14c0,4.34-1.93,7.24-6.27,7.24-4.7,0-6.75-2.65-6.75-7.24V256.87H883.8v11c0,10.73,5.42,18.57,19.41,18.57s20-8,20-19.16v-4c0-9-3.62-13.61-11.09-21.22Z"/>
  <g>
    <g>
      <path class="cls-2" d="M165,761.31H315.38a3.67,3.67,0,0,0,2.72-6.16l-.21-.22-74.84-96.82-.73-.81a3.67,3.67,0,0,0-2.72-1.2,3.75,3.75,0,0,0-2.42.95l-.45.52-.71.79-73,95.81-1.12,1.37a3.64,3.64,0,0,0-.63,2.06A3.71,3.71,0,0,0,165,761.31Z"/>
      <path class="cls-2" d="M314.92,775H164.2a3.69,3.69,0,0,0-3.69,3.69V896.59a3.7,3.7,0,0,0,3.69,3.71h48.51V826.09a3.68,3.68,0,0,1,3.69-3.69h45a3.68,3.68,0,0,1,3.69,3.69V900.3h49.8a3.7,3.7,0,0,0,3.69-3.71V778.65A3.69,3.69,0,0,0,314.92,775Z"/>
    </g>
    <path class="cls-2" d="M835.83,744.08H686.66a3.59,3.59,0,0,0-3.65,3.55V898.26a3.6,3.6,0,0,0,3.65,3.54H835.83a3.6,3.6,0,0,0,3.66-3.54V747.63a3.59,3.59,0,0,0-3.66-3.55m-81.62,98.34a3.6,3.6,0,0,1-3.64,3.56H706a3.61,3.61,0,0,1-3.66-3.56V815.34a3.6,3.6,0,0,1,3.66-3.56h44.57a3.59,3.59,0,0,1,3.64,3.56Zm0-50.06a3.6,3.6,0,0,1-3.64,3.56H706a3.61,3.61,0,0,1-3.66-3.56v-27.1a3.61,3.61,0,0,1,3.66-3.54h44.57a3.59,3.59,0,0,1,3.64,3.54Zm68.12,50.06a3.6,3.6,0,0,1-3.66,3.56H774.12a3.6,3.6,0,0,1-3.66-3.56V815.34a3.6,3.6,0,0,1,3.66-3.56h44.55a3.6,3.6,0,0,1,3.66,3.56Zm0-50.06a3.6,3.6,0,0,1-3.66,3.56H774.12a3.6,3.6,0,0,1-3.66-3.56v-27.1a3.6,3.6,0,0,1,3.66-3.54h44.55a3.6,3.6,0,0,1,3.66,3.54Z"/>
    <path class="cls-2" d="M489.06,394.44h-146a3.57,3.57,0,0,0-3.56,3.58v498.9a3.58,3.58,0,0,0,3.56,3.57h146a3.6,3.6,0,0,0,3.59-3.57V398a3.59,3.59,0,0,0-3.59-3.58M402.22,843.65a1.92,1.92,0,0,1-1.92,1.92H366.88a1.92,1.92,0,0,1-1.92-1.92V806.41a1.91,1.91,0,0,1,1.92-1.91H400.3a1.91,1.91,0,0,1,1.92,1.91Zm0-85.55A1.91,1.91,0,0,1,400.3,760H366.88A1.91,1.91,0,0,1,365,758.1V720.87a1.91,1.91,0,0,1,1.92-1.92H400.3a1.91,1.91,0,0,1,1.92,1.92Zm0-85.53a1.92,1.92,0,0,1-1.92,1.92H366.88a1.92,1.92,0,0,1-1.92-1.92V635.31a1.92,1.92,0,0,1,1.92-1.91H400.3a1.92,1.92,0,0,1,1.92,1.91Zm0-85.55a1.92,1.92,0,0,1-1.92,1.92H366.88A1.92,1.92,0,0,1,365,587V549.76a1.9,1.9,0,0,1,1.92-1.89H400.3a1.9,1.9,0,0,1,1.92,1.89Zm0-85.53a1.92,1.92,0,0,1-1.92,1.91H366.88a1.92,1.92,0,0,1-1.92-1.91V464.23a1.92,1.92,0,0,1,1.92-1.92H400.3a1.92,1.92,0,0,1,1.92,1.92Zm65.55,342.16a1.92,1.92,0,0,1-1.93,1.92H432.43a1.91,1.91,0,0,1-1.92-1.92V806.41a1.91,1.91,0,0,1,1.92-1.91h33.41a1.91,1.91,0,0,1,1.93,1.91Zm0-85.55a1.91,1.91,0,0,1-1.93,1.92H432.43a1.9,1.9,0,0,1-1.92-1.92V720.87a1.9,1.9,0,0,1,1.92-1.92h33.41a1.91,1.91,0,0,1,1.93,1.92Zm0-85.53a1.92,1.92,0,0,1-1.93,1.92H432.43a1.91,1.91,0,0,1-1.92-1.92V635.31a1.91,1.91,0,0,1,1.92-1.91h33.41a1.92,1.92,0,0,1,1.93,1.91Zm0-85.55a1.92,1.92,0,0,1-1.93,1.92H432.43a1.91,1.91,0,0,1-1.92-1.92V549.76a1.9,1.9,0,0,1,1.92-1.89h33.41a1.9,1.9,0,0,1,1.93,1.89Zm0-85.53a1.92,1.92,0,0,1-1.93,1.91H432.43a1.91,1.91,0,0,1-1.92-1.91V464.23a1.91,1.91,0,0,1,1.92-1.92h33.41a1.92,1.92,0,0,1,1.93,1.92Z"/>
    <path class="cls-2" d="M839.32,728.92a3,3,0,0,1-3,3H686.17a3,3,0,0,1-3-3v-30a3,3,0,0,1,3-3H836.32a3,3,0,0,1,3,3Z"/>
    <path class="cls-2" d="M665.4,488.65a3.85,3.85,0,0,0-3.67-2.79,3.81,3.81,0,0,0-2.09.69l-.27.18-1.23,1.56-143.48,182-.66.89a4.13,4.13,0,0,0-1.25,2.84V897.67a3.57,3.57,0,0,0,3.58,3.56H662.17a3.56,3.56,0,0,0,3.57-3.56V490.15A3.71,3.71,0,0,0,665.4,488.65ZM605,587.49l1-1.28c9.81-12.6,26.36-33,34.42-42.86l1.06-1.26a4.17,4.17,0,0,1,3.21-1.52,4.09,4.09,0,0,1,4,3.34V590a4.12,4.12,0,0,1-4,4.1c-.07,0-.12,0-.18,0s-.12,0-.19,0H608.51c-.07,0-.12,0-.18,0s-.12,0-.18,0a4.13,4.13,0,0,1-4-4.1A4.17,4.17,0,0,1,605,587.49Zm-22.7,254.9a3.56,3.56,0,0,1-3.57,3.57H535.21a3.56,3.56,0,0,1-3.57-3.57V815.16a3.56,3.56,0,0,1,3.57-3.56h43.51a3.56,3.56,0,0,1,3.57,3.56Zm0-50.32a3.56,3.56,0,0,1-3.57,3.57H535.21a3.56,3.56,0,0,1-3.57-3.57V764.84a3.55,3.55,0,0,1,3.57-3.57h43.51a3.55,3.55,0,0,1,3.57,3.57Zm0-50.32a3.56,3.56,0,0,1-3.57,3.56H535.21a3.56,3.56,0,0,1-3.57-3.56V714.51a3.56,3.56,0,0,1,3.57-3.57h43.51a3.56,3.56,0,0,1,3.57,3.57Zm.12-110.27v60a3.58,3.58,0,0,1-3.57,3.57h-43.5a3.57,3.57,0,0,1-3.59-3.57v-7.55a4.14,4.14,0,0,1,.27-1.33,2.92,2.92,0,0,1,.22-.42,3,3,0,0,1,.32-.47c.1-.17.27-.39.4-.58,5.56-8,30.66-38.88,41.2-51.8l1.06-1.28a4.15,4.15,0,0,1,3.21-1.51,4.1,4.1,0,0,1,4,3.33ZM648.8,842.39a3.57,3.57,0,0,1-3.57,3.57h-43.5a3.57,3.57,0,0,1-3.59-3.57V815.16a3.57,3.57,0,0,1,3.59-3.56h43.5a3.57,3.57,0,0,1,3.57,3.56Zm0-50.32a3.57,3.57,0,0,1-3.57,3.57h-43.5a3.57,3.57,0,0,1-3.59-3.57V764.84a3.56,3.56,0,0,1,3.59-3.57h43.5a3.57,3.57,0,0,1,3.57,3.57Zm0-50.32a3.58,3.58,0,0,1-3.57,3.56h-43.5a3.57,3.57,0,0,1-3.59-3.56V714.51a3.57,3.57,0,0,1,3.59-3.57h43.5a3.57,3.57,0,0,1,3.57,3.57Zm0-50.33a3.57,3.57,0,0,1-3.57,3.56h-43.5a3.57,3.57,0,0,1-3.59-3.56V664.19a3.57,3.57,0,0,1,3.59-3.57h43.5a3.57,3.57,0,0,1,3.57,3.57Zm0-50.33a3.57,3.57,0,0,1-3.57,3.57h-43.5a3.57,3.57,0,0,1-3.59-3.57V613.86a3.57,3.57,0,0,1,3.59-3.58h43.5a3.57,3.57,0,0,1,3.57,3.58Z"/>
  </g>
</svg>`;
