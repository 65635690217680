<template>
  <div class="giz-policy-download" v-if="policy !== null && policy.pdfDownload !== null">
    <a :href="policy.pdfDownload" target="_blank" @click.stop=""><span class="giz-icon download" aria-hidden="true"></span>{{ caption }}</a>
  </div>
</template>

<script>
export default {
  name: "GizPolicyDownload",
  props: {
    policy: Object,
    caption: String
  },
}
</script>

<style lang="scss">
  .giz-policy-download {
    a {
      font-weight: bold;
    }
  }
</style>