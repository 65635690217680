<template>
  <div id="giz-sdg-modal" :class="['modal-content', visibilityClass, modalSdg.feid ]" v-if="modalSdg !== null">
    <button class="close-btn btn-reset" @click="vissibleModalSdg()" ref="closeBtn">
      <span class="giz-icon cross" aria-hidden="true"></span>
    </button>
    <article>
      <b-container>
        <b-row>
          <b-col class="col-12 col-md-8 col-lg-7">
            <h1>{{ modalSdg.genericName }}</h1>
            <div v-html="modalSdg.description" />
            <a :href="modalSdg.link.uri" target="__blank" v-if="modalSdg.link">{{ modalSdg.link.title }}</a>
          </b-col>
          <b-col class="col-12 col-md-4 col-xl-3 ml-auto">
            <div v-html="goalIcons[modalSdg.feid]" :class="modalSdg.feid" />
            <div class="related" v-if="modalSdgPolicies.length > 0">
              <h4>Connected to</h4>
              <giz-policy-link
                v-for="policy in modalSdgPolicies"
                :key="policy.id"
                :policy="policy"
                :classes="['btn-link']"
              >
                Policy {{ policy.policyNumber }}
              </giz-policy-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { modalVisibility } from '../mixins/modal.js';
import { goalIcons } from '../config.js';
import GizPolicyLink from './PolicyLink';

Object.freeze(goalIcons);

export default {
  name: "GizSdgModal",
  data: function() {
    return {
      goalIcons: goalIcons
    }
  },
  computed: {
    ...mapGetters([
      'modalSdg',
      'isModalSdgVisible',
      'policiesBySdg'
    ]),
    modalSdgPolicies: function() {
      if (this.modalSdg) {
        return this.policiesBySdg(this.modalSdg.id);
      }
      return [];
    }
  },
  methods: {
    ...mapMutations([
      'vissibleModalSdg'
    ])
  },
  watch: {
    isModalSdgVisible: function (visibility) {
      this.modalVisibility(visibility);
    },
  },
  components: {
    GizPolicyLink
  },
  mixins: [ modalVisibility ]
}
</script>

<style lang="scss">
  @import "../assets/scss/functions";
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  #giz-sdg-modal {
    padding: 61px 0 52px 0;

    // backgrounds
    @each $sdg in $sdgs {
      $background: map-get($colors, "sdg-background-" + $sdg);
      @if $background {
        &.fe-#{$sdg} {
          background: #fff $background;
        }
      }
    }

    article {
      @include media-breakpoint-up(lg) {
        margin: 0 (-1 * $grid-gutter-width / 2);
      }

      & > .container > .row > .col {
        @include media-breakpoint-up(lg) {
          padding-left: 84px + $grid-gutter-width / 2;
        }

        & + .col {
          padding-top: 30px;

          @include media-breakpoint-up(lg) {
            padding-left: $grid-gutter-width / 2;
            padding-right: 84px + $grid-gutter-width / 2;
            padding-top: 0;
          }

          svg {
            width: 100%;
            max-width: 151px;
          }
        }
      }

      h1, h2 {
        font-size: 1.25rem;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 22px;
      }
      h2 {
        font-weight: 700;
      }
      p {
        line-height: 1.2;
      }
      a {
        display: inline-block;
        margin-top: 8px;
        font-weight: 700;
        line-height: 1.2222;
      }
      .related {
        margin-top: 26px;
        line-height: 1.2222;
        h4 {
          font-weight: 400;
          line-height: 1.2222;
          margin-bottom: 26px;
        }
        .giz-policy-link {
          display: block;
          font-weight: 700;
          margin-bottom: 3px;
        }
      }
    }
  }
</style>