<template>
  <div id="giz-domain-filter">
    <div class="wheel">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 321.1 321.1" class="filter-shadow domain-wheel">
        <g
          v-for="domain in domains"
          :key="domain.id"
          :class="{ 'selected': isDomainSelected(domain.id), 'hover': isDomainHovered(domain.id), 'filtered': hasFilter, 'domain': true }"
        >
          <!-- backgrounds -->
          <path
            :class="[ 'background', ('fill-' + domain.feid) ]"
            :d="domainWheelPaths[domain.feid].background"
          />
        </g>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 321.1 321.1" role="figure" class="domain-wheel" id="giz-domain-filter-wheel">
        <g
          v-for="domain in domains"
          :key="domain.id"
          :class="{ 'selected': isDomainSelected(domain.id), 'hover': isDomainHovered(domain.id), 'filtered': hasFilter, 'domain': true }"
        >
          <!-- backgrounds -->
          <path
            :class="[ 'background', ('fill-' + domain.feid) ]"
            :d="domainWheelPaths[domain.feid].background"
            @click="domainClick(domain.id)"
            @keydown="domainKeyDown($event, domain.id)"
            @mouseenter="storeHoveredDomains([domain.id])"
            @mouseleave="storeHoveredDomains()"
            @focus="storeHoveredDomains([domain.id]); lastFocus=domain.id"
            @blur="storeHoveredDomains()"
            role="button"
          />
          <!-- icons -->
          <g class="icon">
            <path v-for="(path, index) in domainWheelPaths[domain.feid].icon" :key="domain.id + '-icon-' + index" :d="path"/>
          </g>
        </g>
      </svg>
      <h3>{{ caption }}</h3>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { domainWheelPaths } from '../config.js';

Object.freeze(domainWheelPaths);

export default {
  name: "GizDomainFilter",
  data: function () {
    return {
      domainWheelPaths: domainWheelPaths,
      // accessibility
      lastFocus: ''
    }
  },
  computed: {
    ...mapGetters([
      'domains',
      // filter actions
      'hasDomainFilter',
      'hasMixFilter',
      'selectedMix',
      'selectedDomain',
      'isDomainSelected',
      'isDomainHovered',
      'hasFilter'
    ]),
    caption: function() {
      return this.hasMixFilter ?
        this.selectedMix.name :
          this.hasDomainFilter ?
            this.selectedDomain.name :
            'Domains';
    },
  },
  methods: {
    ...mapMutations([
      'storeSelectedDomains',
      'storeHoveredDomains',
    ]),
    domainClick: function(id) {
      this.storeHoveredDomains();
      this.isDomainSelected(id) && !this.hasMixFilter ?
        this.storeSelectedDomains() :
        this.storeSelectedDomains([id]);
    },
    // accessibility
    domainKeyDown: function(event, id) {
      if(event.which === 27 || event.which === 13) {
        let restoreLastFocus = this.hasDomainFilter;
        this.domainClick(id);
        if (restoreLastFocus) {
          this.storeHoveredDomains([this.lastFocus]);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  #giz-domain-filter {
    svg.domain-wheel {
      position: relative;
      z-index: 100;
      
      &.filter-shadow {
        position: absolute;
        z-index: 98;
        top: 0;
        left: 0;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, .25));

        .domain {
          opacity: 1;
          transition-property: opacity;
          transition-duration: $transition-duration;

          &.filtered {
            opacity: 0;
          }
          &.selected {
            opacity: 1;
          }
        }
      }
    }

    .wheel {
      position: relative;
      display: block;
      margin-top: 30px;

      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-top: 5px;
      }

      h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 60%;
        text-align: center;
        pointer-events: none;
        font-size: 5vw;
        @include media-breakpoint-up(sm) {
          font-size: 1.125rem;
        }
      }
    }
  }
</style>
