<template>
  <div id="giz-filter">
    <b-container>

      <b-row class="flex-column flex-md-row-reverse">
        <b-col class="align-self-end">
          <a href="/files/210419_GIZ_Policy_Mixes_Manual_Full.pdf"><span class="giz-icon download" aria-hidden="true"></span>Full PDF</a>
          <a href="/files/210419_GIZ_Policy_Mixes_Manual_Concise.pdf"><span class="giz-icon download" aria-hidden="true"></span>Concise PDF</a>
          <a href="#" class="pt-5" @click="resetFilter()"><span class="giz-icon reset" aria-hidden="true"></span>Reset Filter</a>      
        </b-col>
        <b-col class="align-self-start">
          <h1 class="text-uppercase" @click="resetFilter()">Inequality Policy Mix Toolkit</h1>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-12 col-md-6">
          <giz-mixes-filter />
        </b-col>
        <b-col class="col-12 col-md-6">
          <giz-domain-filter />
        </b-col>
      </b-row>

      <b-row class="flex-sm-row-reverse info">
        <b-col :class="[ 'col-12', 'col-md-6', { last: !hasMixFilter && hasFilter } ]">
          <giz-domain-filter-legend />
        </b-col>
        <b-col :class="[ 'col-12', 'col-md-6', { empty: hasFilter && (!hasMixFilter || selectedMix.fullPdfVersion === null )} ]">
          <giz-how-to-menu v-if="!hasFilter"/>
          <giz-mix-download v-if="hasMixFilter"/>
        </b-col>
      </b-row>
      <b-row v-if="hasMixFilter" :class="[ 'teaser', { 'top-border': hasFilter && (!hasMixFilter || selectedMix.fullPdfVersion === null )} ]">
        <b-col>
          <giz-mix-teaser />
        </b-col>
      </b-row>
      
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import GizMixesFilter from './MixesFilter';
import GizHowToMenu from './HowToMenu';
import GizDomainFilter from './DomainFilter';
import GizDomainFilterLegend from './DomainFilterLegend';
import GizMixDownload from './MixDownload';
import GizMixTeaser from './MixTeaser';

export default {
  name: "GizFilter",
  computed: {
    ...mapGetters([
      'hasFilter',
      'hasMixFilter',
      'selectedMix'
    ]),
  },
  methods: {
    ...mapMutations([
      'resetFilter'
    ])
  },
  components: {
    GizMixesFilter,
    GizHowToMenu,
    GizDomainFilter,
    GizDomainFilterLegend,
    GizMixDownload,
    GizMixTeaser,
  }
};
</script>

<style lang="scss">
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  #giz-filter {
    padding-top: 50px;
    padding-bottom: 32px;
    background: map-get($colors, filter-background);

    @include media-breakpoint-up(md) {
      padding-bottom: 40px;
    }

    & > .container:first-child {
      @include media-breakpoint-up(lg) {
        padding-left: 84px;
        padding-right: 84px;
      }
    }

    & > .container > .row:first-child {
      align-items: center;

      h1:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      & > .col:first-child {
        flex-grow: 0;

        a {
          display: block;
          white-space: nowrap;
          font-weight: bold;
          margin-bottom: 30px;
          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }
      }

      h1 {
        margin-bottom: 0;
      }
      .giz-icon.cross {
        vertical-align: middle;
        margin-top: -.25rem;
      }
    }

    .info > .col {
      padding-top: 30px;
      padding-bottom: 30px;

      & + .col {
        &:before {
          content: " ";
          height: 1px;
          position: absolute;
          top: 0;
          left: $grid-gutter-width / 2;
          right: $grid-gutter-width / 2;
          background-color: map-get($colors, mix-filter-border);
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
        padding-bottom: 0;
        @include media-breakpoint-up(md) {
          padding-bottom: 30px;
        }
      }

      @include media-breakpoint-up(md) {
        padding-bottom: 0;
      }
    }
    .teaser {
      padding-top: 30px;
      margin-bottom: -1rem;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        padding-top: 30px;
      }
      &.top-border {
        position: relative;
        &:before {
          content: " ";
          height: 1px;
          position: absolute;
          top: 0;
          left: $grid-gutter-width / 2;
          right: $grid-gutter-width / 2;
          background-color: map-get($colors, mix-filter-border);
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }

    .col.empty {
      padding: 0 !important;
      &:before {
        display: none;
      }
    }

    .col.last {
      padding-bottom: 0 !important;
    }
  }
</style>
