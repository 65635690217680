export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect class="cls-1" width="1000" height="1000"/>
  <polygon class="cls-2" points="319.56 83.7 319.56 165.48 349.71 165.48 349.71 154.26 333.31 154.26 333.31 83.7 319.56 83.7"/>
  <rect class="cls-2" x="357.19" y="83.7" width="13.74" height="81.77"/>
  <polygon class="cls-2" points="379.97 165.48 393.71 165.48 393.71 129.41 407.46 129.41 407.46 118.32 393.71 118.32 393.71 94.92 412.14 94.92 412.14 83.7 379.97 83.7 379.97 165.48"/>
  <polygon class="cls-2" points="419.39 165.48 452.17 165.48 452.17 154.26 433.13 154.26 433.13 129.41 446.76 129.41 446.76 118.32 433.13 118.32 433.13 94.92 452.17 94.92 452.17 83.7 419.39 83.7 419.39 165.48"/>
  <polygon class="cls-2" points="73.22 120.29 73.22 155.67 73.52 155.67 97.3 130.99 97.61 130.99 97.61 285.33 131.5 285.33 131.5 83.7 104.44 83.7 73.22 120.29"/>
  <path class="cls-2" d="M212.1,147.65c-13.38,0-23.19,4.75-28.55,14h-.3V111.36h58.29V83.72H152.33v113.6h30.92v-4.48c0-11.88,5.35-18.43,14.86-18.43,10.42,0,14.59,7.14,14.59,18.15v48.77c0,10.69-3.87,18.13-14.28,18.13-11.59,0-15.74-7.73-15.74-19.63V214.56h-31v22.91c0,30.62,12.19,50.24,46.69,50.24,34.2,0,48.17-18.14,48.17-46.09V192.25c0-31.82-16.35-44.6-34.49-44.6"/>
  <path class="cls-2" d="M319.5,264.32V224.93c0-12.74,6.25-22,20.67-22s20.78,9.25,20.78,22v39.39c0,12.62-6.24,22-20.78,22s-20.67-9.37-20.67-22m27.75,2.16V222.76c0-4.93-2.16-8.41-7.08-8.41s-7,3.48-7,8.41v43.72c0,4.93,2.16,8.42,7,8.42s7.08-3.49,7.08-8.42"/>
  <polygon class="cls-2" points="380.24 234.18 380.24 285.34 368.71 285.34 368.71 203.9 382.17 203.9 396.94 250.62 396.94 203.9 408.35 203.9 408.35 285.34 396.35 285.34 380.24 234.18"/>
  <polygon class="cls-2" points="438.04 203.9 451.73 203.9 451.73 274.17 468.08 274.17 468.08 285.34 438.04 285.34 438.04 203.9"/>
  <path class="cls-2" d="M472.11,285.34l15.38-81.44h15.62l15.5,81.44H504.55L502,270.45H487.49l-2.39,14.89Zm17.19-25.82h10.93l-5.41-32.67h-.12Z"/>
  <polygon class="cls-2" points="535.74 234.18 535.74 285.34 524.2 285.34 524.2 203.9 537.66 203.9 552.43 250.62 552.43 203.9 563.84 203.9 563.84 285.34 551.84 285.34 535.74 234.18"/>
  <path class="cls-2" d="M613.69,225.77v37.72c0,12.48-5.16,21.85-19.82,21.85H572.73V203.9h21.14c14.66,0,19.82,9.25,19.82,21.87m-21.5,48.4c5.63,0,7.81-3.36,7.81-8.28V223.24c0-4.81-2.18-8.17-7.81-8.17h-5.77v59.1Z"/>
  <g>
    <path class="cls-2" d="M419.19,676.12a2.21,2.21,0,0,1,2.21,2.21v72.82a2.2,2.2,0,0,1-2.21,2.21H389.73a2.21,2.21,0,0,1-2.22-2.21V678.33a2.21,2.21,0,0,1,2.22-2.21Z"/>
    <path class="cls-2" d="M742.8,784.62H257.19a2.28,2.28,0,0,0-2.22,2.29v29.68a2.21,2.21,0,0,0,2.22,2.18H742.8a2.24,2.24,0,0,0,2.23-2.18V786.91a2.3,2.3,0,0,0-2.23-2.29"/>
    <path class="cls-2" d="M742.8,843.61H257.19A2.22,2.22,0,0,0,255,845.8v29.77a2.23,2.23,0,0,0,2.22,2.19H742.8a2.25,2.25,0,0,0,2.23-2.19V845.8a2.24,2.24,0,0,0-2.23-2.19"/>
    <path class="cls-2" d="M650.39,595.52c-6.06-4.75-22.26-15.83-42-15.83-14.12,0-27.25,5.56-39,16.57a4.19,4.19,0,0,0-1.3,3V602a4.19,4.19,0,0,0,1.39,3.32,3.88,3.88,0,0,0,3.42.8,5.28,5.28,0,0,0,.91-.23,6.7,6.7,0,0,0,2.43-1.54c2.54-1.71,9.5-4,19.45-4,10.28,0,30.5,2.76,50,21l.45.4a5.87,5.87,0,0,0,4.22,1.76,5.72,5.72,0,0,0,4.11-1.72l.4-.41c19.64-18.24,39.82-21,50.1-21,10,0,17,2.34,19.5,4a7.21,7.21,0,0,0,2.69,1.64A5.93,5.93,0,0,0,730,606a4.16,4.16,0,0,0,3.06-4v-2.34a4.22,4.22,0,0,0-1.3-3c-11.86-11.3-25.13-17-39.47-17-19.57,0-35.69,10.91-41.93,15.85"/>
    <path class="cls-2" d="M620.26,402a4.07,4.07,0,0,0-2.12,2.56l-.49,1.62a4.11,4.11,0,0,0,.64,3.65,4,4,0,0,0,3,1.64,1.59,1.59,0,0,0,.61,0,5.15,5.15,0,0,0,2.34-.6,12.21,12.21,0,0,1,3-.33c2.17,0,21.75.66,34.69,22.66l.17.32a4.94,4.94,0,0,0,3.1,2.48,5.91,5.91,0,0,0,1.55.24,5.25,5.25,0,0,0,2.45-.6l.32-.16a48.39,48.39,0,0,1,21.66-5.36c12.27,0,20.28,5,22.15,7.09A5.6,5.6,0,0,0,714.9,439a11.43,11.43,0,0,0,1.58.76,3.29,3.29,0,0,0,.72.1,2,2,0,0,0,.43,0,4.21,4.21,0,0,0,1.93-.48,4.1,4.1,0,0,0,2.06-2.49l.41-1.43a3.74,3.74,0,0,0,.17-1.18,4,4,0,0,0-.57-2.09c-9.43-16.37-23.11-19.79-32.94-19.79a51.65,51.65,0,0,0-17.48,3.28c-4.44-6-15.69-18.35-32.75-18.35a40.46,40.46,0,0,0-18.2,4.6"/>
    <path class="cls-2" d="M517.65,476.69l.14,2.19a4.15,4.15,0,0,0,5.21,3.76,5.33,5.33,0,0,0,.71-.22,5.41,5.41,0,0,0,2.1-1.55c2.06-1.49,8.71-4.21,18.52-4.21,9.43,0,23.77,2.52,38.39,14.53l.34.35a5.78,5.78,0,0,0,3.75,1.41h.36a5.69,5.69,0,0,0,3.81-1.83l.32-.37c16.19-17.13,33.62-19.67,42.57-19.67,6.38,0,10.9,1.31,12.66,2.31a6.46,6.46,0,0,0,2.53,1.31,4.86,4.86,0,0,0,2.8-.13,4.14,4.14,0,0,0,2.75-4.17l-.11-1.9a4.11,4.11,0,0,0-1.46-2.89c-9.66-8.13-20.09-12.22-31.06-12.22-17.08,0-30.71,10.08-36.35,15-5.16-3.52-17.7-10.76-32.4-10.76-12.92,0-24.5,5.37-34.45,15.92a4.3,4.3,0,0,0-1.13,3.11"/>
    <path class="cls-2" d="M271.91,515.67A52.65,52.65,0,0,1,324.56,463c1.14,0,2.25.1,3.37.17a71.19,71.19,0,1,1,135,36.44A79.58,79.58,0,0,1,445.65,656.9c-3.21,0-106.23-.62-106.23-.62h0a56.29,56.29,0,0,1-41-94.95A52.59,52.59,0,0,1,271.91,515.67Z"/>
  </g>
</svg>`;
