const defaultTaxonomyData = (data, index) => {
  return {
    id: data.id,
    number: index + 1,
    name: data.attributes.name,
    description: rte(data.attributes.description),
    feid: data.attributes.field_ipmt_frontend_css_style
  }
}

const relation = data => {
  if (data !== null) {
    if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'id')) {
      return [data.id];
    } else if (Array.isArray(data)) {
      return data.map(obj => obj.id);
    }
  }
  return null;
}

const fileInclude = (relationship, included) => {
  if (relationship.data !== null) {
    const includedRelation = included.find(include => include.id === relationship.data.id);
    if (includedRelation) {
      return '//' + process.env.VUE_APP_API_HOST + includedRelation.attributes.uri.url;
    }
  }
  return null;
}

const rte = fieldData => {
  return fieldData !== null ? fieldData.processed : '';
}

export default {
  mix (data, index, included) {
    return {
      genericName: "Policy mix " + (index + 1),
      genericTarget: "Targeting " + data.attributes.name,
      ...defaultTaxonomyData(data, index),
      additionalPolicies: rte(data.attributes.field_ipmt_additional_policies),
      relevantSdgs: rte(data.attributes.field_ipmt_relevant_sdgs),
      fullPdfVersion: fileInclude(data.relationships['field_ipmt_full_pdf_version'], included),
    }
  },
  domain (data, index) {
    return {
      genericName: "Domain " + (index + 1),
      ...defaultTaxonomyData(data, index),
      subtitle: data.attributes.field_ipmt_subtitle,
    }
  },
  sdg (data, index) {
    return {
      genericName: "Sustainable Development Goal " + (index + 1),
      ...defaultTaxonomyData(data, index),
      link: data.attributes.field_ipmt_link,
    }
  },
  policy (data, index, included) {
    return {
      id: data.id,
      title: data.attributes.title,
      genericName: 'Policy ' + data.attributes.field_ipmt_policy_number,
      policyNumber: data.attributes.field_ipmt_policy_number,
      description: rte(data.attributes.field_ipmt_description),
      evidence: rte(data.attributes.field_ipmt_evidence),
      relationship: rte(data.attributes.field_ipmt_relationship),
      keyActors: rte(data.attributes.field_ipmt_key_actors),
      levelOfIntervention: rte(data.attributes.field_ipmt_level_of_intervention),

      pdfDownload: fileInclude(data.relationships['field_ipmt_pdf_download'], included),

      domain: relation(data.relationships.field_ipmt_domain.data),
      policyMixes: relation(data.relationships.field_ipmt_policy_mixes.data),
      sdgs: relation(data.relationships.field_ipmt_sdg.data),
    }
  }
}