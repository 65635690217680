export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect class="cls-1" width="999.96" height="1000"/>
  <path class="cls-2" d="M301.21,165.45V171l25.92,4.83V165.57L317,163.63c7-3.37,10.13-10.36,10.13-19.29V104.78c0-12.77-6.27-22.06-20.84-22.06S285.55,92,285.55,104.78v39.56c0,11.09,4.7,19.18,15.66,21.11m-1.91-62.83c0-4.94,2.16-8.43,7-8.43s7.1,3.49,7.1,8.43v44.62c0,4.82-2.16,8.45-7.1,8.45s-7-3.63-7-8.45Z"/>
  <path class="cls-2" d="M356.68,166.42c14,0,19.42-8.21,19.42-20.87V83.68H363.43v63.2c0,4.82-1.93,8.08-6.75,8.08s-6.75-3.26-6.75-8.08V83.68H336.18v61.87c0,12.66,6,20.87,20.5,20.87"/>
  <path class="cls-2" d="M396.46,150.49H411l2.54,15h14.11L412.13,83.68H396.46L381,165.45h13Zm7.22-43.77h.14l5.42,32.8h-11Z"/>
  <polygon class="cls-2" points="464.69 154.23 448.31 154.23 448.31 83.68 434.56 83.68 434.56 165.45 464.69 165.45 464.69 154.23"/>
  <rect class="cls-2" x="471.07" y="83.68" width="13.74" height="81.76"/>
  <polygon class="cls-2" points="502.61 165.45 516.46 165.45 516.46 94.9 527.8 94.9 527.8 83.68 491.29 83.68 491.29 94.9 502.61 94.9 502.61 165.45"/>
  <polygon class="cls-2" points="547.31 165.45 561.06 165.45 561.06 134.33 576.24 83.68 563.1 83.68 554.78 114.68 554.66 114.68 546.35 83.68 532 83.68 547.31 134.33 547.31 165.45"/>
  <polygon class="cls-2" points="318.31 274.13 299.27 274.13 299.27 249.27 312.88 249.27 312.88 238.19 299.27 238.19 299.27 214.79 318.31 214.79 318.31 203.57 285.53 203.57 285.53 285.33 318.31 285.33 318.31 274.13"/>
  <path class="cls-2" d="M367.85,263.38V225.52c0-12.66-5.17-22-19.88-22H326.74v81.77H348c14.71,0,19.88-9.41,19.88-22m-27.36,10.74V214.78h5.79c5.66,0,7.82,3.38,7.82,8.2V265.8c0,4.94-2.16,8.32-7.82,8.32Z"/>
  <path class="cls-2" d="M376.9,203.57v61.87c0,12.67,6,20.86,20.49,20.86,14,0,19.41-8.19,19.41-20.86V203.57H404.15v63.19c0,4.83-1.94,8.08-6.76,8.08s-6.75-3.25-6.75-8.08V203.57Z"/>
  <path class="cls-2" d="M425.84,264.23c0,12.66,6,22.07,20.5,22.07s19.4-8.68,19.4-20.39v-14.1H453.21v15.07c0,4.83-1.69,8-6.63,8s-7-3.49-7-8.45V222.51c0-4.95,2-8.45,7-8.45s6.63,2.89,6.63,8v10.73h12.53V223.1c0-12-4.22-20.5-19.4-20.5-14.47,0-20.5,9.29-20.5,22.07Z"/>
  <path class="cls-2" d="M485.38,270.37H500l2.53,15H516.6l-15.55-81.76H485.38l-15.44,81.76h13Zm7.22-43.77h.12l5.43,32.8h-11Z"/>
  <polygon class="cls-2" points="514.89 214.79 526.21 214.79 526.21 285.33 540.08 285.33 540.08 214.79 551.4 214.79 551.4 203.57 514.89 203.57 514.89 214.79"/>
  <rect class="cls-2" x="559" y="203.57" width="13.75" height="81.77"/>
  <path class="cls-2" d="M602.65,286.3c14.57,0,20.83-9.4,20.83-22.08V224.67c0-12.78-6.26-22.06-20.83-22.06s-20.73,9.28-20.73,22.06v39.55c0,12.68,6.26,22.08,20.73,22.08m-7-63.8c0-4.94,2.17-8.43,7-8.43s7.11,3.49,7.11,8.43v43.89c0,5-2.17,8.45-7.11,8.45s-7-3.49-7-8.45Z"/>
  <polygon class="cls-2" points="644.12 233.95 660.28 285.35 672.34 285.35 672.34 203.56 660.88 203.56 660.88 250.48 646.05 203.56 632.56 203.56 632.56 285.35 644.12 285.35 644.12 233.95"/>
  <path class="cls-2" d="M156.65,285.43h32.11V252.72h13.69V226.26H188.76V83.8H155.45L96.87,231.9v20.82h59.78Zm-30-59.17,30.33-83h.3v83Z"/>
  <g>
    <polygon class="cls-2" points="438.3 536.19 258.96 432.63 258.96 767.63 438.3 821.74 438.3 536.19"/>
    <polygon class="cls-2" points="458.03 536.87 458.03 821.86 638.53 767.59 638.53 432.64 458.03 536.87"/>
    <path class="cls-2" d="M779.32,424.15a21.22,21.22,0,1,0-42.43,0V447h42.43Z"/>
    <polygon class="cls-2" points="742.16 761.69 736.89 761.69 758.12 835.85 779.31 761.69 774.04 761.69 779.31 761.69 779.31 463.64 774.04 463.64 768.77 463.64 763.19 463.64 752.63 463.64 747.42 463.64 742.16 463.64 736.88 463.64 736.88 761.69 742.16 761.69"/>
    <polygon class="cls-2" points="677.06 447.02 657.83 431.83 657.83 776.72 458.56 841.11 449.34 841.11 447.51 841.11 438.3 841.11 239.92 785.68 239.92 431.83 220.68 447.02 220.68 799.17 437.39 859.17 447.51 859.17 449.34 859.17 459.46 859.17 659.4 795.69 677.06 790.49 677.06 447.02"/>
  </g>
</svg>`;
