<template>
  <div id="giz-loading-screen" :class="[ visibilityClass ]" @click="show()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 321.1 321.1" :class="[ 'domain-wheel', { animate: animating } ]" ref="wheel">
      <g
        v-for="(path, domain) in domainWheelPaths"
        :key="'loading-wheel-' + domain"
        class="domain"
      >
        <!-- backgrounds -->
        <path
          :class="[ 'background', ('fill-' + domain) ]"
          :d="path.background"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { domainWheelPaths } from '../config.js'

Object.freeze(domainWheelPaths);

export default {
  name: "GizLoadingScreen",
  data: function() {
    return {
      visibilityClass: 'show',
      domainWheelPaths: domainWheelPaths,
      animating: true,
    }
  },
  props: {
    debug: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters(['isLoading'])
  },
  created: function () {
    if (this.debug) {
      this.setLoading(true);
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    show: function() {
      if (this.debug) {
        this.setLoading(false);
      }
    }
  },
  watch: {
    isLoading: function(value) {
      if (!value) {
        this.visibilityClass = '';
        this.$nextTick(() => {
          const rect = document.getElementById('giz-domain-filter-wheel').getBoundingClientRect(),
            width = rect.right - rect.left,
            height = rect.bottom - rect.top;
          this.$refs.wheel.style.left = rect.left + (width / 2) + 'px';
          this.$refs.wheel.style.top = rect.top + (height / 2) + 'px';
          this.$refs.wheel.style.width = width + "px";
          this.$refs.wheel.style.height = height + "px";
          this.animating = false;
          setTimeout(() => this.visibilityClass = 'd-none', 450); // sync with sass $hide-duration
        });
      } else {
        this.$refs.wheel.style.left = null;
        this.$refs.wheel.style.top = null;
        this.$refs.wheel.style.width = null;
        this.$refs.wheel.style.height = null;
        this.animating = true;
        this.visibilityClass = 'show';
      }
    }
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/custom-vars";

  @keyframes preloader {
    0% { opacity: .05; }
    50% { opacity: .9; }
  }
  @keyframes preloaderRotaions {
    0% { transform: translate(-50%, -50%) rotate(0deg) }
    100% { transform: translate(-50%, -50%) rotate(360deg) }
  }

  #giz-loading-screen {
    $hide-duration: .45s;
    $loading-background-duration: .4s;
    $loading-background-duration-delayed: .8s;

    z-index: 2000;
    opacity: 0;
    transition-property: opacity;
    transition-duration: $hide-duration;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f1f1f1;

    &.show {
      opacity: 1;
      svg.domain-wheel .domain .background {
        opacity: .1;
      }
    }

    svg.domain-wheel {
      position: absolute;
      top: 40%;
      left: 50%;
      width: 80px;
      height: 80px;
      transform: translate(-50%, -50%);

      // hide transition
      transition-property: width, height, top, left;
      transition-duration: $hide-duration;

      .domain .background {
        cursor: auto;
        opacity: 1;

        // hide transition 
        transition-property: opacity;
        transition-duration: $hide-duration;
      }

      // loading animation
      &.animate {
        animation-name: preloaderRotaions;
        animation-duration: $loading-background-duration-delayed * length($domains);
        animation-iteration-count: infinite;
        animation-timing-function: steps(length($domains), end);

        .domain .background {
          animation-name: preloader;
          animation-duration: $loading-background-duration-delayed;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;

          $delay: 0;
          $dd: $loading-background-duration / length($domains);
          @each $domain in $domains {
            &.fill-fe-#{$domain} {
              animation-delay: $delay;
            }
            $delay: $delay + $dd;
          }
        }
      }
      
    }

    & > .container {
      &,
      & > .row,
      & > .row > .col {
        height: 100%;
      }
    }
  }
</style>