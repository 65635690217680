<template>
  <div id="giz-policy-list">
    <b-container>
      <b-row>
        <b-col class="col col-md-6">
          <div class="caption">
            <span class="label">Results: </span>
            <span class="filter-info">{{ filterInfo }}</span>
            <span class="filter-name">{{ filterName }}</span>
          </div>
        </b-col>
      </b-row>
      <b-row class="result">
        <b-col v-for="policy in filteredPolicies" :key="policy.id" class="col-11 col-sm-6 col-md-4 col-lg-3">
          <giz-policy-teaser :policy="policy" />
        </b-col>
      </b-row>
        <footer class="footer text-right">
          <small class="text-muted"><a href="https://www.poverty-inequality.com/impressum/">Impressum</a></small>
        </footer>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GizPolicyTeaser from './PolicyTeaser';

export default {
  name: "GizPolicyList",
  computed: {
    ...mapGetters([
      'poliycies',
      'filteredPolicies',
      'hasFilter',
      'hasDomainFilter',
      'hasMixFilter',
      'selectedMix',
      'selectedDomain',
    ]),
    filterInfo: function () {
      return 'All Policies' + (
        this.hasMixFilter ? ' for ' + this.selectedMix.genericName + ': ' :
        this.hasDomainFilter ? ' for ' + this.selectedDomain.genericName + ': ' :
        ''
      );
    },
    filterName: function () {
      return this.hasMixFilter ? this.selectedMix.genericTarget :
        this.hasDomainFilter ? this.selectedDomain.name :
        '';
    },
  },
  components: {
    GizPolicyTeaser
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';

  #giz-policy-list {
    padding-bottom: 40px;

    .caption {
      font-size: $h3-font-size;
      margin: 36px 0 5px 0;
      line-height: 1.5rem;

      .label {
        text-transform: uppercase;
        font-weight: 700;
      }
      span {
        display: inline-block;
        padding-right: .3rem;
      }
    }
    .result {
      & > .col {
        padding-top: $grid-gutter-width / 4;
        padding-bottom: $grid-gutter-width / 4;
      }
    }
  }
</style>