export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect class="cls-1" x="0.3" y="0.15" width="999.81" height="999.85"/>
  <polygon class="cls-2" points="77.22 120.59 77.22 155.97 77.53 155.97 101.31 131.29 101.61 131.29 101.61 285.6 135.51 285.6 135.51 84.01 108.46 84.01 77.22 120.59"/>
  <path class="cls-2" d="M254.15,237.15V207.41c0-33-13.08-49.66-37.46-49.66-14.28,0-22.9,6.54-27.95,16.36h-.29V130.7c0-12.2,3.85-20.82,16.05-20.82s15.76,7.14,15.76,19.62v13.4h30.92V132.18c0-29.73-9.81-50.54-47.28-50.54-35.68,0-49.06,22.9-49.06,54.42v98.11c0,31.22,13.69,53.82,49.36,53.82,36,0,50-19.62,50-50.84m-33.6,3c0,12.78-4.76,20.21-16.35,20.21-11.3,0-15.75-7.72-15.75-20.21V203.25c.58-11.3,5.34-18.44,16.05-18.44,11.58,0,16.05,7.14,16.05,20.23Z"/>
  <g>
    <path class="cls-2" d="M646.14,491s-8.41,11.17-6,22.06,14.88,17.36,14.88,17.36,8.41-11.17,6-22.05S646.14,491,646.14,491"/>
    <path class="cls-2" d="M632.43,554.84s8.41-11.18,6-22.06-14.87-17.36-14.87-17.36-8.42,11.17-6,22.06,14.88,17.36,14.88,17.36"/>
    <path class="cls-2" d="M667,542.88s8.88,9,19,8.06,17.61-11.31,17.61-11.31-8.87-9-19-8.07S667,542.88,667,542.88"/>
    <path class="cls-2" d="M681.56,495c-8.23,8.35-7.07,23.13-7.07,23.13s14.51,1.26,22.76-7.08,7.07-23.13,7.07-23.13-14.52-1.26-22.76,7.08"/>
    <path class="cls-2" d="M663.4,576.09c10.08,1.2,19.56-7.46,19.56-7.46S676.09,558,666,556.85s-19.57,7.47-19.57,7.47,6.87,10.58,16.95,11.77"/>
    <path class="cls-2" d="M672.4,751.23H597.05a10.66,10.66,0,1,0,0,21.32H672.4a10.66,10.66,0,0,0,0-21.32"/>
    <path class="cls-2" d="M672.4,868.74H597.05a10.66,10.66,0,1,0,0,21.32H672.4a10.66,10.66,0,0,0,0-21.32"/>
    <path class="cls-2" d="M333.51,725.35a49.66,49.66,0,0,0-5.23-1.34s1.93.6,5.23,1.34"/>
    <path class="cls-2" d="M669.63,780H599.8a6.07,6.07,0,0,0-6.07,6.06v21.15l-45.58,5-66.59-1.52V770a183,183,0,0,0,26.49-5.38c64.11-18.08,78.09-71.08,76.69-107.75-1.39-37.35,10.56-51.85,27.73-58.3a77.47,77.47,0,0,1,14.23-3.77c.19,9.17,2,19.84,6.15,32.54,1.53-.08,3-.21,4.55-.37-4.33-13-6.18-23.69-6.28-32.81a79.89,79.89,0,0,1,15.2-.46,96.54,96.54,0,0,1-14.47-10.3c2.88-17.68,13.42-28.54,24.27-39.65,6-6.11,12.13-12.42,17-19.94l-3.78-2.44c-4.6,7.14-10.63,13.29-16.44,19.24-10.58,10.83-21.27,21.89-24.89,39.45A145.4,145.4,0,0,1,617,569c-15.81-17.72-36.16-4.71-65.17,22.31s-65.54,27.93-101.2-8.55c-47-48.16-88.08-47.88-116.22-45.15,19.25,8.2,26,26.19,35.73,71.91,10.21,48,36,69.68,71.19,72.67-19.59,6.93-20.56,18-46.81,36.12-20.51,14.14-49.35,9.67-61,7,21.46,6.7,46.89,29.21,69.64,37a168.17,168.17,0,0,0,41.51,8.51v38.94l-119.3-2.72v27.16l222.79-5.1,45.58,5v21.13a6.09,6.09,0,0,0,6.07,6.08h69.83a6.1,6.1,0,0,0,6.08-6.08V786.06A6.08,6.08,0,0,0,669.63,780Zm-214-8.52c5,.08,9.95-.07,14.94-.41v39.37l-14.94-.34Z"/>
    <path class="cls-2" d="M318.84,829.8c0,5.73-4,10.37-8.92,10.37h-5.37c-4.92,0-8.93-4.64-8.93-10.37V811c0-5.73,4-10.37,8.93-10.37h5.37c4.92,0,8.92,4.64,8.92,10.37Z"/>
  </g>
  <g>
    <path class="cls-2" d="M319.38,83.89h20.37c13.86,0,18.93,6.87,18.93,18.32v17.23c0,11.45-5.07,18.44-18.93,18.44h-6.63v27.73H319.38ZM333.12,95.1v32.18h5.07c4.7,0,6.75-2.29,6.75-6.75v-18.8c0-4.46-2.05-6.63-6.75-6.63Z"/>
    <path class="cls-2" d="M364.7,83.89h32.79V95.1H378.44v23.38h13.62v11.09H378.44V154.4h19.05v11.21H364.7Z"/>
    <path class="cls-2" d="M402.79,165.61l15.43-81.72h15.67l15.54,81.72h-14.1l-2.53-15H418.22l-2.41,15ZM420,139.69h11l-5.42-32.78h-.12Z"/>
    <path class="cls-2" d="M454,144.51V105c0-12.78,6-22.06,20.49-22.06,15.19,0,19.41,8.44,19.41,20.49v9.64H481.37V102.33c0-5.06-1.68-8-6.63-8s-7,3.5-7,8.44v43.87c0,4.94,2.05,8.44,7,8.44s6.63-3.13,6.63-8V132.1h12.54v14.1c0,11.69-4.82,20.37-19.41,20.37S454,157.17,454,144.51Z"/>
    <path class="cls-2" d="M501.38,83.89h32.79V95.1h-19v23.38h13.62v11.09H515.12V154.4h19v11.21H501.38Z"/>
    <path class="cls-2" d="M556.22,153.55l-7,18.44h-7.71l1.69-19.52h13Z"/>
    <path class="cls-2" d="M614.44,83.89V147c0,11.82-5.19,19.53-18.93,19.53-14.22,0-18.07-7.83-18.07-18.2V132.1h13v16.51c0,4.1,1.45,6.51,5.06,6.51s5.19-2.41,5.19-6.63V83.89Z"/>
    <path class="cls-2" d="M662.65,83.89v61.83c0,12.65-5.43,20.85-19.41,20.85-14.46,0-20.49-8.2-20.49-20.85V83.89h13.74V147c0,4.83,1.93,8.08,6.75,8.08S650,151.87,650,147V83.89Z"/>
    <path class="cls-2" d="M670.36,148V137h12.78v11.21c0,4.58,2.05,7.23,6.75,7.23,4.34,0,6.27-2.89,6.27-7.23v-3.13c0-4.7-1.93-7.6-6.15-11.58l-8.08-8.07c-7.83-7.71-11.45-12.42-11.45-21.82v-2.77c0-10.12,5.91-18,19.29-18,13.74,0,19.16,6.75,19.16,18.56v6.63H696.4V101c0-4.7-2.17-7-6.51-7-3.86,0-6.51,2.17-6.51,6.75v1.69c0,4.58,2.53,7.23,6.51,11.09l8.8,8.67c7.47,7.6,11.09,12.18,11.09,21.22v4c0,11.2-6,19.16-20,19.16S670.36,158.74,670.36,148Z"/>
    <path class="cls-2" d="M716,83.89h36.53V95.1H741.24v70.51H727.38V95.1H716Z"/>
    <path class="cls-2" d="M759.56,83.89H773.3v81.72H759.56Z"/>
    <path class="cls-2" d="M781.85,144.51V105c0-12.78,6-22.06,20.49-22.06,15.19,0,19.41,8.44,19.41,20.49v9.64H809.21V102.33c0-5.06-1.68-8-6.63-8s-7,3.5-7,8.44v43.87c0,4.94,2,8.44,7,8.44s6.63-3.13,6.63-8V132.1h12.54v14.1c0,11.69-4.82,20.37-19.41,20.37S781.85,157.17,781.85,144.51Z"/>
    <path class="cls-2" d="M829.82,83.89h32.79V95.1H843.56v23.38h13.62v11.09H843.56V154.4h19.05v11.21H829.82Z"/>
  </g>
  <g>
    <path class="cls-2" d="M317.7,285.46l15.43-81.72H348.8l15.54,81.72h-14.1l-2.53-14.94H333.13l-2.41,14.94Zm17.24-25.91h11l-5.42-32.78h-.12Z"/>
    <path class="cls-2" d="M382.78,234.12v51.34H371.21V203.74h13.5l14.83,46.89V203.74H411v81.72H398.94Z"/>
    <path class="cls-2" d="M461.13,225.68v37.85c0,12.53-5.18,21.93-19.89,21.93H420V203.74h21.21C456,203.74,461.13,213,461.13,225.68Zm-21.58,48.57c5.67,0,7.84-3.37,7.84-8.31V223.15c0-4.82-2.17-8.2-7.84-8.2h-5.78v59.3Z"/>
    <path class="cls-2" d="M483.31,267.87v-11h12.77v11.21c0,4.58,2.05,7.23,6.75,7.23,4.34,0,6.27-2.89,6.27-7.23V265c0-4.7-1.93-7.59-6.15-11.57l-8.07-8.07c-7.84-7.72-11.45-12.42-11.45-21.82v-2.77c0-10.13,5.9-18,19.28-18,13.74,0,19.17,6.75,19.17,18.56V228H509.34v-7.11c0-4.7-2.17-7-6.51-7-3.85,0-6.51,2.17-6.51,6.75v1.69c0,4.58,2.53,7.23,6.51,11.08l8.8,8.68c7.47,7.6,11.09,12.18,11.09,21.22v4c0,11.21-6,19.17-20,19.17S483.31,278.59,483.31,267.87Z"/>
    <path class="cls-2" d="M527.78,203.74H564.3V215H553v70.51H539.11V215H527.78Z"/>
    <path class="cls-2" d="M585.64,248v37.48H571.9V203.74h20c13.86,0,18.93,6.87,18.93,18.32v10.49c0,8.8-3.14,14-11,15.79L613,285.46H598.53Zm0-33v25.43h4.58c4.7,0,6.74-2.41,6.74-6.74V221.58c0-4.46-2-6.63-6.74-6.63Z"/>
    <path class="cls-2" d="M619.5,264.37V224.84c0-12.78,6.27-22.06,20.74-22.06s20.85,9.28,20.85,22.06v39.53c0,12.66-6.27,22.06-20.85,22.06S619.5,277,619.5,264.37Zm27.85,2.17V222.67c0-4.94-2.17-8.44-7.11-8.44s-7,3.5-7,8.44v43.87c0,4.94,2.16,8.44,7,8.44S647.35,271.48,647.35,266.54Z"/>
    <path class="cls-2" d="M681.7,234.12v51.34H670.13V203.74h13.5l14.82,46.89V203.74H709.9v81.72h-12Z"/>
    <path class="cls-2" d="M739.67,241.83H759.2v43.63h-9.89v-8.92c-1.8,6.15-5.66,9.89-13.13,9.89-11.81,0-17.24-9.4-17.24-22.06V224.84c0-12.78,6.15-22.06,20.61-22.06,15.19,0,19.65,8.44,19.65,20.49v7.23H746.66v-8.31c0-5.07-1.93-8-6.87-8s-7.11,3.5-7.11,8.44v43.87c0,4.94,2.05,8.44,6.75,8.44,4.46,0,6.75-2.53,6.75-7.84V252.68h-6.51Z"/>
    <path class="cls-2" d="M320,324.9h13.74v81.72H320Z"/>
    <path class="cls-2" d="M354.46,355.27v51.35H342.89V324.9h13.5l14.82,46.88V324.9h11.46v81.72H370.61Z"/>
    <path class="cls-2" d="M391,389v-11h12.78v11.21c0,4.58,2.05,7.23,6.75,7.23,4.34,0,6.26-2.89,6.26-7.23v-3.13c0-4.7-1.92-7.6-6.14-11.57l-8.08-8.08c-7.83-7.71-11.45-12.41-11.45-21.82v-2.77c0-10.12,5.91-18,19.29-18,13.74,0,19.16,6.75,19.16,18.56v6.63H417V342c0-4.7-2.17-7-6.51-7-3.86,0-6.51,2.17-6.51,6.75v1.69c0,4.58,2.53,7.23,6.51,11.09l8.8,8.68c7.47,7.59,11.08,12.17,11.08,21.21v4c0,11.21-6,19.16-20,19.16S391,399.75,391,389Z"/>
    <path class="cls-2" d="M435.46,324.9H472v11.21H460.65v70.51H446.79V336.11H435.46Z"/>
    <path class="cls-2" d="M479.57,324.9h13.74v81.72H479.57Z"/>
    <path class="cls-2" d="M500.9,324.9h36.52v11.21H526.09v70.51H512.23V336.11H500.9Z"/>
    <path class="cls-2" d="M584.91,324.9v61.83c0,12.65-5.42,20.85-19.4,20.85-14.47,0-20.49-8.2-20.49-20.85V324.9h13.74v63.15c0,4.83,1.93,8.08,6.75,8.08s6.75-3.25,6.75-8.08V324.9Z"/>
    <path class="cls-2" d="M592.39,324.9h36.52v11.21H617.58v70.51H603.72V336.11H592.39Z"/>
    <path class="cls-2" d="M636.5,324.9h13.74v81.72H636.5Z"/>
    <path class="cls-2" d="M659.4,385.52V346c0-12.78,6.27-22.06,20.73-22.06S701,333.21,701,346v39.53c0,12.66-6.27,22.06-20.85,22.06S659.4,398.18,659.4,385.52Zm27.84,2.17V343.82c0-4.94-2.17-8.44-7.11-8.44s-7,3.5-7,8.44v43.87c0,4.94,2.17,8.44,7,8.44S687.24,392.63,687.24,387.69Z"/>
    <path class="cls-2" d="M721.59,355.27v51.35H710V324.9h13.5l14.83,46.88V324.9H749.8v81.72H737.74Z"/>
    <path class="cls-2" d="M758.11,389v-11h12.78v11.21c0,4.58,2.05,7.23,6.75,7.23,4.34,0,6.27-2.89,6.27-7.23v-3.13c0-4.7-1.93-7.6-6.15-11.57l-8.08-8.08c-7.83-7.71-11.45-12.41-11.45-21.82v-2.77c0-10.12,5.91-18,19.29-18,13.74,0,19.16,6.75,19.16,18.56v6.63H784.15V342c0-4.7-2.17-7-6.51-7-3.86,0-6.51,2.17-6.51,6.75v1.69c0,4.58,2.53,7.23,6.51,11.09l8.8,8.68c7.47,7.59,11.09,12.17,11.09,21.21v4c0,11.21-6,19.16-20,19.16S758.11,399.75,758.11,389Z"/>
  </g>
</svg>`;
