<template>
  <div id="giz-about-modal" :class="['modal-content', visibilityClass ]" @click="vissibleModalAbout()">
    <article class="header">
      <b-container>
        <button class="close-btn btn-reset" @click="vissibleModalAbout()" ref="closeBtn">
          <span class="giz-icon cross" aria-hidden="true"></span>
        </button>
        <b-row>
          <b-col class="col-11 col-md-9 col-lg-8">
            <h1>The Multidimensional Inequality Framework</h1>
            <a href="https://sticerd.lse.ac.uk/inequality/">To learn more about the Multidimensional Inequality Framework, please visit: https://sticerd.lse.ac.uk/inequality</a>
          </b-col>
        </b-row>
      </b-container>
      <article v-for="domain in domains" :key="domain.id" :class="domain.feid">
        <div class="header">
          <b-container>
            <b-row>
              <b-col class="col-12">
                <div class="header">
                  <img :src="domainBanner[domain.feid]" alt="banner" />
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <b-container>
          <b-row>
            <b-col class="col-12 col-md-7">
              <h1>{{ domain.genericName }}</h1>
              <h2>{{ domain.name }}</h2>
              <div v-html="domain.description" />
            </b-col>
          </b-row>
        </b-container>
      </article>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { domainBanner } from '../config.js';
import { modalVisibility } from '../mixins/modal.js';

Object.freeze(domainBanner);

export default {
  name: "GizAboutModal",
  data: function() {
    return {
      domainBanner: domainBanner
    }
  },
  computed: {
    ...mapGetters([
      'domains',
      'isModalAboutVisible',
    ])
  },
  methods: {
    ...mapMutations([
      'vissibleModalAbout'
    ])
  },
  watch: {
    isModalAboutVisible: function (visibility) {
      this.modalVisibility(visibility);
    },
  },
  mixins: [ modalVisibility ]
}
</script>

<style lang="scss">
  @import "../assets/scss/functions";
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  #giz-about-modal {
    top: 0;
    padding: 0 0 52px 0;
    
    &.modal-content {
      width: 100%;
      padding-bottom: 0;
    }
    & > .header {
      background-color: map-get($colors, modal-about-heaader-background);
      & > .container {
        position: relative;

        .col {
          padding-bottom: 28px;
        }
      }
    }

    // backgrounds
    @each $domain in $domains {
      $background: map-get($colors, "domain-background-" + $domain);
      @if $background {
        .fe-#{$domain} {
          background: #fff $background;
          .header {
            background: map-get($colors, "domain-" + $domain);
          }
        }
      }
    }

    button.close-btn {
      right: $grid-gutter-width / 2;
      top: 20px;
    }

    article {
      position: relative;

      .col {
        padding-bottom: 12px;
      }

      .header .col {
        padding-bottom: 0;
      }

      h1 {
        margin-bottom: 0;
        margin-top: 40px;
        margin-bottom: 20px;
      }
      a {
        font-weight: 700;
      }
      h2 {
        margin-bottom: 20px;
      }
      p {
        line-height: 1.2;
      }
      article {
        h1 {
          font-weight: 400;
          margin-bottom: 0;
          margin-top: 28px;
        }
        h1,
        h2 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>