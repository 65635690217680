<template>
  <article class="giz-policy-teaser" :class="[ teaserClass,  { 'hover': hover } ]" 
    @click="policyClick()"
    @keydown="policyKeyDown($event)"
    @mouseenter="setHoverPolicy(true)"
    @mouseleave="setHoverPolicy(false)"
    role="button"
    tabindex="0"
  >
    <div class="info">{{ policy.genericName }}</div>
    <div class="title">{{ policy.title }}</div>
    <div class="domain">
      <template v-if="teaserDomain">
      </template>
      <div class="sub-link" @mouseenter="setHoverSubLinks(true)" @mouseleave="setHoverSubLinks(false)">
        <giz-policy-download caption="Policy PDF" :policy="policy"/>
      </div>
      <div class="sub-link" @mouseenter="setHoverSubLinks(true)" @mouseleave="setHoverSubLinks(false)">
        <giz-sdg-link v-if="teaserSdg" :sdg="teaserSdg" :classes="['btn', 'btn-link', 'sdg']"  @mouseenter="setHoverSubLinks(true)" @mouseleave="setHoverSubLinks(false)">
          <div v-html="goalIcons[teaserSdg.feid]" :class="teaserSdg.feid" />
        </giz-sdg-link>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import GizPolicyDownload from './PolicyDownload';
import GizSdgLink from './SdgLink';
import { goalIcons } from '../config.js';

Object.freeze(goalIcons);

export default {
  name: "GizPolicyTeaser",
  data: function() {
    return {
      goalIcons: goalIcons,
      hoverPoliciy: false,
      hoverSubLinks: false
    }
  },
  computed: {
    ...mapGetters([
      'domain',
      'sdg',
    ]),
    teaserDomain: function() {
      return this.domain(this.policy.domain[0]);
    },
    teaserSdg: function() {
      if (this.policy.sdgs.length > 0) {
        return this.sdg(this.policy.sdgs[0]);
      }
      return null;
    },
    teaserClass: function() {
      return this.teaserDomain ? this.teaserDomain.feid : '';
    },
    hover: function() {
      return this.hoverPoliciy && !this.hoverSubLinks
    }
  },
  methods: {
    ...mapMutations([
      'storeModalPolicy',
      'toggleModalPolicy'
    ]),
    policyClick: function() {
      this.storeModalPolicy(this.policy.id);
      this.toggleModalPolicy();
    },
    policyKeyDown: function(event) {
      if(event.which === 27 || event.which === 13) {
        this.policyClick();
      }
    },
    setHoverPolicy: function(hover) {
      this.hoverPoliciy = hover;
    },
    setHoverSubLinks: function(hover) {
      this.hoverSubLinks = hover;
    }
  },
  props: {
    policy: Object
  },
  components: {
    GizPolicyDownload,
    GizSdgLink
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  .giz-policy-teaser {
    $sdg-img-max-width: 1 / 3; // sdg image width depend on teaser width
    $sdg-img-offset: 1 / 3; // sdg image offset depend on own width
    $format: 4 / 5; // teaser format (grow allowed)
    $format-xs: 5 / 4; // teaser format (grow allowed)
    $xs-col: 11 / $grid-columns; // col-11 factor
    $widths: (
      sm: 220px,
      md: 190px,
      lg: 190px,
      xl: 235px
    );

    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 1 calc((100vw - #{$grid-gutter-width}) * #{$xs-col}  / #{$format-xs});
    min-height: calc((100vw - #{$grid-gutter-width}) * #{$xs-col}  / #{$format-xs});
    text-transform: uppercase;
    padding: 18px 18px 17px 26px;
    font-weight: 700;
    line-height: 20px;
    background: linear-gradient(180deg, rgba(99, 99, 99, 0.3) 0%, rgba(99, 99, 99, 0.9) 100%);

    @each $bp, $width in $widths {
      @include media-breakpoint-up($bp) {
        $height: $width / $format;
        flex-basis: $height;
        min-height: $height;
      }
    }

    cursor: pointer;
    &:focus,
    &.hover {
      & > .info,
      & > .title {
        text-decoration: underline;
      }
    }

    .sub-link {
      display: inline-block;
    }

    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 12px;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: #999;
    }

    .domain {
      margin-top: auto;
      padding-top: 20px;
      padding-right: calc((100vw - #{$grid-gutter-width}) * #{$xs-col} * #{$sdg-img-max-width} * #{1 - $sdg-img-offset});
      font-size: 1rem;
      color: map-get($colors, policy-teaser-domain-color);

      @each $bp, $width in $widths {
        @include media-breakpoint-up($bp) {
          padding-right: calc(#{$width} * #{$sdg-img-max-width} * #{1 - $sdg-img-offset});
        }
      }
    }
    .giz-policy-download {
      margin-top: 10px;
      font-size: $font-size-base;
      color: map-get($colors, font);
      text-transform: none;
    }
    & > .info {
      font-weight: 400;
    }

    .btn.sdg {
      position: absolute;
      width: calc((100vw - #{$grid-gutter-width}) * #{$xs-col} * #{$sdg-img-max-width});
      height: calc((100vw - #{$grid-gutter-width}) * #{$xs-col} * #{$sdg-img-max-width});
      bottom: 25px;
      right: 0;
      transform: translate(100% * $sdg-img-offset, 0);
      padding: 0;

      @each $bp, $width in $widths {
        @include media-breakpoint-up($bp) {
          height: calc(#{$width} * #{$sdg-img-max-width});
          width: calc(#{$width} * #{$sdg-img-max-width});
        }
      }

      svg {
        width: 110%;
        height: 110%;
      }
      &:hover,
      &focus {
        svg .cls-2 {
          fill: map-get($colors, font);
        }
      }
    }

    @each $domain in $domains {
      $color: map-get($colors, 'domain-' + $domain);
      &.fe-#{$domain} {
        background: map-get($colors, 'domain-background-inverse-' + $domain);
        &:after {
          background-color: $color;
        }
      }
    }
  }
</style>