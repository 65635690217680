export default `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
  <rect class="cls-1" width="1000" height="1000"/>
  <polygon class="cls-2" points="299.13 113.19 299.13 164.56 287.55 164.56 287.55 82.8 301.04 82.8 315.89 129.72 315.89 82.8 327.35 82.8 327.35 164.56 315.29 164.56 299.13 113.19"/>
  <path class="cls-2" d="M357.12,165.54c14.61,0,20.86-9.41,20.86-22.08V103.91c0-12.8-6.25-22.07-20.86-22.07s-20.74,9.27-20.74,22.07v39.55c0,12.67,6.27,22.08,20.74,22.08m-7-63.81c0-5,2.17-8.43,7-8.43s7.12,3.48,7.12,8.43v43.9c0,5-2.18,8.44-7.12,8.44s-7-3.49-7-8.44Z"/>
  <path class="cls-2" d="M301.3,257.64h6.62c13.88,0,18.94-7,18.94-18.45V222c0-11.46-5.06-18.34-18.94-18.34H287.55v81.77H301.3Zm0-42.81h5.06c4.71,0,6.75,2.18,6.75,6.63v18.8c0,4.48-2,6.77-6.75,6.77H301.3Z"/>
  <path class="cls-2" d="M355.8,286.34c14.61,0,20.87-9.4,20.87-22.06V224.71c0-12.78-6.26-22.06-20.87-22.06s-20.74,9.28-20.74,22.06v39.57c0,12.66,6.27,22.06,20.74,22.06m-7-63.79c0-5,2.16-8.44,7-8.44s7.13,3.49,7.13,8.44v43.89c0,4.95-2.18,8.45-7.13,8.45s-7-3.5-7-8.45Z"/>
  <polygon class="cls-2" points="411.75 285.38 427.31 203.61 414.4 203.61 404.75 260.65 404.62 260.65 395.22 203.61 381.23 203.61 396.79 285.38 411.75 285.38"/>
  <polygon class="cls-2" points="466.98 274.17 447.93 274.17 447.93 249.31 461.55 249.31 461.55 238.22 447.93 238.22 447.93 214.83 466.98 214.83 466.98 203.61 434.18 203.61 434.18 285.38 466.98 285.38 466.98 274.17"/>
  <path class="cls-2" d="M489.16,247.87l12.9,37.51h14.48l-13.15-37.15c7.84-1.8,11-7,11-15.8V222c0-11.46-5.07-18.34-18.94-18.34h-20v81.77h13.76Zm0-33h4.57c4.71,0,6.76,2.18,6.76,6.63v12c0,4.35-2.05,6.75-6.76,6.75h-4.57Z"/>
  <polygon class="cls-2" points="531.48 285.38 545.35 285.38 545.35 214.83 556.69 214.83 556.69 203.61 520.14 203.61 520.14 214.83 531.48 214.83 531.48 285.38"/>
  <polygon class="cls-2" points="577.29 285.38 591.04 285.38 591.04 254.27 606.23 203.61 593.09 203.61 584.78 234.6 584.66 234.6 576.33 203.61 561.99 203.61 577.29 254.27 577.29 285.38"/>
  <polygon class="cls-2" points="139.62 131.52 139.92 131.52 139.92 285.86 173.81 285.86 173.81 84.23 146.74 84.23 115.53 120.81 115.53 156.19 115.83 156.19 139.62 131.52"/>
  <g>
    <path class="cls-2" d="M770.13,477.09a27.67,27.67,0,1,1,27.7,27.69,27.7,27.7,0,0,1-27.7-27.69"/>
    <path class="cls-2" d="M830.15,515.45a36.89,36.89,0,0,1,33.5,36.77V629a10.73,10.73,0,0,1-21.46,0V563.05c0-2.48-.71-5.82-5.23-5.82-3.13,0-3.78,3.31-3.89,5.42V760.19a15.43,15.43,0,0,1-30.86,0V646.36c0-1.26-.46-4.52-4.38-4.52h0c-3.93,0-4.41,3.26-4.45,4.52V760.19a15.42,15.42,0,1,1-30.83,0V562.65c-.1-2.11-.78-5.42-3.88-5.42-4.54,0-5.23,3.34-5.23,5.82V629A10.73,10.73,0,0,1,732,629V552.22a36.91,36.91,0,0,1,33.55-36.77,15.26,15.26,0,0,1,2-.17H828a16.4,16.4,0,0,1,2.11.17"/>
  </g>
  <g>
    <path class="cls-2" d="M605.5,491.63a26.21,26.21,0,1,0-26.19,26.22,26.21,26.21,0,0,0,26.19-26.22"/>
    <path class="cls-2" d="M526.48,551,504.69,622s-3.88,13,7.15,16.25,14.31-9.44,14.31-9.44l17.57-59.21s1-4.56,4.38-3.57,2.14,5.85,2.14,5.85l-30.58,111H548.1v79.47a13.66,13.66,0,0,0,27.31,0V682.79h7.76v79.47a13.67,13.67,0,0,0,27.33,0V682.79h28.43l-30.56-111S607.08,567,610.5,566s4.38,3.57,4.38,3.57l17.57,59.21s3.34,12.69,14.3,9.44S653.9,622,653.9,622L632.11,551s-7.78-20.51-25-20.51H551.53c-17.24,0-25,20.51-25,20.51"/>
  </g>
  <g>
    <path class="cls-2" d="M448.49,491.63a26.21,26.21,0,1,0-26.2,26.22,26.22,26.22,0,0,0,26.2-26.22"/>
    <path class="cls-2" d="M369.46,551,347.68,622s-3.9,13,7.14,16.25,14.31-9.44,14.31-9.44l17.57-59.21s.95-4.56,4.38-3.57,2.13,5.85,2.13,5.85l-30.57,111h28.44v79.47a13.66,13.66,0,0,0,27.32,0V682.79h7.76v79.47a13.66,13.66,0,0,0,27.31,0V682.79h28.45l-30.56-111s-1.3-4.87,2.11-5.85,4.39,3.57,4.39,3.57l17.56,59.21s3.34,12.69,14.31,9.44S496.87,622,496.87,622L475.08,551s-7.77-20.51-25-20.51H394.51c-17.23,0-25.05,20.51-25.05,20.51"/>
  </g>
  <g>
    <path class="cls-2" d="M693.54,638.35a21.82,21.82,0,1,0-21.82-21.84,21.8,21.8,0,0,0,21.82,21.84"/>
    <path class="cls-2" d="M641.23,706.13c-1.07,2.55-1.94,8,3.18,10.32,6,2.76,9.35-3,9.35-3l11.86-37.83s2.06-2.53,2.06.09v89.05h.05a11.2,11.2,0,0,0,22.39,0V729.57s-.65-5,3.36-5,3.38,5,3.38,5v35.15a11.22,11.22,0,0,0,22.44,0V675.67c0-2.62,1.38-.75,1.38-.75l9.3,25.9s4,11.57,5.28,12.08a8.26,8.26,0,0,0,10.81-4.47,8.16,8.16,0,0,0-.88-8l.08-.08c-.27-.84-13.9-34.26-15.5-38.95-4.92-14.37-13.88-14.24-22.5-14.48s-13.79,0-13.79,0-5.51-.22-16.9.11-15,6.19-27.51,38.46c-1.79,4.65-7.84,19.68-7.92,20.58Z"/>
  </g>
  <g>
    <path class="cls-2" d="M186.66,504.78A27.69,27.69,0,1,0,159,477.09,27.72,27.72,0,0,0,186.66,504.78Z"/>
    <path class="cls-2" d="M219.22,515.31A18.06,18.06,0,0,0,217,515h-60.5a13.66,13.66,0,0,0-2,.31A37,37,0,0,0,121,552.22V629a10.66,10.66,0,0,0,5.61,9.46,4.07,4.07,0,0,0-.36.94l-24.6,130.3a4,4,0,0,0,3.22,4.74,3.81,3.81,0,0,0,.75.07,4.05,4.05,0,0,0,4-3.31l24.58-130.3a4,4,0,0,0-.15-1.51A10.62,10.62,0,0,0,142,629V563.05c0-2.48.65-5.82,5.17-5.82,3.12,0,3.83,3.31,3.83,5.42V760.19a15.5,15.5,0,0,0,31,0V646.36a4.3,4.3,0,0,1,4.66-4.36h0c3.93,0,4.33,3.1,4.33,4.36V760.19a15.5,15.5,0,0,0,31,0V562.65c0-2.11.74-5.42,3.83-5.42,4.55,0,5.17,3.34,5.17,5.82V629a11,11,0,0,0,22,0V552.22A37.25,37.25,0,0,0,219.22,515.31Z"/>
  </g>
  <g>
    <path class="cls-2" d="M326.16,674.41l20.25-36.75s2.44-5.28,2.44-6.54a8,8,0,0,0-14.72-4.21l-.09,0c-.64.55-10.83,18.63-14.19,22-3.55,3.59-6.74,3.14-6.74,3.14H286.67s-3.2.45-6.74-3.14c-3.4-3.36-13.54-21.47-14.19-22l-.07.06A8,8,0,0,0,251,631.11a14.41,14.41,0,0,0,.78,3.49l21.95,39.81a14.62,14.62,0,0,1,1.29,5.25v7.84L263.5,730H275v35h.15a10.94,10.94,0,0,0,10.94,10.83A10.81,10.81,0,0,0,297,765.14V731.41a1.8,1.8,0,0,1-.38-1.41h6.5a3.39,3.39,0,0,1-.12,1.41v33.73a11,11,0,0,0,22,0V730h11.29L325,687.25v-7.59A15.92,15.92,0,0,1,326.16,674.41Z"/>
    <path class="cls-2" d="M299.88,638.35a21.82,21.82,0,1,0-21.81-21.84A21.8,21.8,0,0,0,299.88,638.35Z"/>
  </g>
</svg>`;
