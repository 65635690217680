import { mapMutations } from 'vuex';

export const modalVisibility = {
  data: function() {
    return {
      visibilityClass: 'd-none'
    }
  },
  methods: {
    ...mapMutations(['storeLastFocus']),
    modalVisibility: function(visibility) {
      if (visibility) {
        this.visibilityClass = '';
        setTimeout(() => {
          this.visibilityClass = 'show';
          // accessibility
          //
          // button should focus after scrolled to modal is finished 
          // therefor store button to in vuex ... and use after scrollTo finished
          //
          // storeLastFocus is wrong at this position cuase another modal might just hve been closed...
          //
          // if (this.$refs.closeBtn) {
          //   this.storeLastFocus();
          //   this.$refs.closeBtn.focus();
          // }
        }, 10);
      } else {
        this.visibilityClass = '';
        setTimeout(() => this.visibilityClass = 'd-none', 300);
      }
    }
  }
}