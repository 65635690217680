<template>
  <button :class="[ 'giz-domain-link', 'btn-reset', ...classes ]" type="button" @click.stop="click()">
    <slot></slot>
  </button>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: "GizDomainLink",
  methods: {
    ...mapMutations([
      'storeModalDomain',
      'vissibleModalDomain'
    ]),
    click: function() {
      this.storeModalDomain(this.domain.id);
      this.vissibleModalDomain(true);
    }
  },
  props: {
    domain: Object,
    classes: {
      type: Array,
      default: () => []
    }
  }
}
</script>