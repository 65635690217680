import axios from 'axios';
import Qs from 'qs';

// create axios instance
const gizAxios = axios.create({
  baseURL: process.env.VUE_APP_API_PROTOCOL + process.env.VUE_APP_API_HOST + '/api/v1/',
});

// use to format nested params
gizAxios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };

  return config;
});

// handle response 
const handleResponse = response => {
  if (!response.data) {
    console.error(response);
    throw new Error('Response is no jsonapi response!');
  } else if (response.data.errors) {
    console.error(response.data.errors);
    throw new Error(response.data.errors[0].detail);
  } else {
    return response.data;
  }
};

// create filter
const taxonomyFilter = (path, values) => {
  return {
    condition: {
      path: path,
      operator: 'IN',
      value: values
    }
  };
}

// taxonomie sorting
const taxonomySort = { params: { sort: "weight" } };


// get call with handler
async function get(path, config = {}) {
  try {
    return handleResponse(await gizAxios.get(path, config));
  } catch (error) {
    if (error.response) {
      handleResponse(error.response);
    } else {
      console.error(error);
      throw new Error('Unable to fetch data from: ' + path);
    }
  }
}

// exports
export default {

  // get domains
  async getDomains () {
    return await get('taxonomy_term/domains', taxonomySort);
  },

  // get mixes
  async getPolicyMixes () {
    let config = { params: { ...taxonomySort.params } }; 
    config.params.include = 'field_ipmt_full_pdf_version';
    config.params.fields = {
      'file--file': 'uri,url'
    };

    return await get('taxonomy_term/policy_mixes', config);
  },

  // get sdgs
  async getSdgs () {
    return await get('taxonomy_term/sdgs', taxonomySort);
  },

  // get policies
  async getPolicies (domains = [], mixes = [], sdgs = []) {
    let filter = {},
        hasFilter = false,
        config = { 
          params: {
            include: 'field_ipmt_pdf_download',
            fields: {
              'file--file': 'uri,url'
            },
            sort: 'field_ipmt_policy_number'
          } 
        };

    // filter domains
    if (domains.length > 0) {
      hasFilter = true;
      filter.domain = taxonomyFilter('field_ipmt_domain.id', domains);
    }

    // filter mixes
    if (mixes.length > 0) {
      hasFilter = true;
      filter.mixes = taxonomyFilter('field_ipmt_policy_mixes.id', mixes);
    }

    // filter sdgs
    if (sdgs.length > 0) {
      hasFilter = true;
      filter.sdgs = taxonomyFilter('field_ipmt_sdg.id', sdgs);
    }
    
    // set filter params
    if (hasFilter) {
      config.params.filter = filter;
    }

    return await get('policies', config);
  }
}