<template>
  <div id="giz-mixes-filter">
    <h3 class="text-uppercase">Key Policy Mixes<br>to Reduce Inequality</h3>
    <div
      type="button"
      role="button"
      tabindex="0"
      v-for="mix in mixes"
      :key="mix.id"
      :class="{ 'selected': isMixSelected(mix.id), 'hover': isMixHovered(mix.id), 'filtered': hasFilter, 'btn-mix': true }"
      @click="mixClick(mix.id)"
      @keydown="mixKeyDown($event, mix.id)"
      @mouseenter="storeHoveredMixes([mix.id])"
      @mouseleave="storeHoveredMixes()"
      @focus="storeHoveredMixes([mix.id]); lastFocus=mix.id"
      @blur="storeHoveredMixes();"
    >
      <img :src="mixesIcons[mix.feid]" alt="policy mix icon"/>
      <span class="caption">{{ mix.genericName }}</span><br>
      <span class="sub-caption">{{ mix.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { mixesIcons } from '../config.js';

Object.freeze(mixesIcons);

export default {
  name: "GizMixesFilter",
  data: function () {
    return {
      mixesIcons: mixesIcons,
      // accessibility
      lastFocus: '',
    }
  },
  computed: {
    ...mapGetters([
      'mixes',
      // filter actions
      'isMixSelected',
      'isMixHovered',
      'hasFilter',
      'hasMixFilter',
    ]),
  },
  methods: {
    ...mapMutations([
      'storeSelectedMixes',
      'storeHoveredMixes',
    ]),
    mixClick: function(id) {
      this.storeHoveredMixes();
      this.isMixSelected(id) ? 
        this.storeSelectedMixes() : 
        this.storeSelectedMixes([id]);
    },
    // accessibility
    mixKeyDown: function(event, id) {
      if(event.which === 27 || event.which === 13) {
        let restoreLastFocus = this.hasMixFilter;
        this.mixClick(id);
        if (restoreLastFocus) {
          this.storeHoveredMixes([this.lastFocus]);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "../assets/scss/custom-vars";
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  #giz-mixes-filter {
    border-bottom: 1px solid map-get($colors, mix-filter-border);
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
      border-bottom: none;
      padding-bottom: 10px;
    }

    @include media-breakpoint-up(lg) {
      // border-right: 1px solid map-get($colors, mix-filter-border);
    }
  }
</style>
