<template>
  <div id="giz-mix-download" v-if="selectedMix !== null && selectedMix.fullPdfVersion !== null">
    <a :href="selectedMix.fullPdfVersion" target="_blank"><span class="giz-icon download" aria-hidden="true"></span>Policy Mix as PDF</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "GizMixDownload",
  computed: {
    ...mapGetters([
      'selectedMix',
    ]),
  },
}
</script>

<style lang="scss">
  #giz-mix-download {
    a {
      font-weight: bold;
    }
  }
</style>