import mixFilter1 from './assets/img/mix-1.svg'
import mixFilter2 from './assets/img/mix-2.svg'
import mixFilter3 from './assets/img/mix-3.svg'
import mixFilter4 from './assets/img/mix-4.svg'

import domainLegendHealth from './assets/img/domain-health.svg'
import domainLegendSecurity from './assets/img/domain-security.svg'
import domainLegendLearning from './assets/img/domain-learning.svg'
import domainLegendWork from './assets/img/domain-work.svg'
import domainLegendLiving from './assets/img/domain-living.svg'
import domainLegendInfluence from './assets/img/domain-influence.svg'
import domainLegendLife from './assets/img/domain-life.svg'

import domainBannerHealth from './assets/img/domain-banner-health.svg'
import domainBannerSecurity from './assets/img/domain-banner-security.svg'
import domainBannerLearning from './assets/img/domain-banner-learning.svg'
import domainBannerWork from './assets/img/domain-banner-work.svg'
import domainBannerLiving from './assets/img/domain-banner-living.svg'
import domainBannerInfluence from './assets/img/domain-banner-influence.svg'
import domainBannerLife from './assets/img/domain-banner-life.svg'

import { default as domainIconHealth } from './assets/img/domain-icon-health.js'
import { default as domainIconSecurity } from './assets/img/domain-icon-security.js'
import { default as domainIconLearning } from './assets/img/domain-icon-learning.js'
import { default as domainIconWork } from './assets/img/domain-icon-work.js'
import { default as domainIconLiving } from './assets/img/domain-icon-living.js'
import { default as domainIconInfluence } from './assets/img/domain-icon-influence.js'
import { default as domainIconLife } from './assets/img/domain-icon-life.js'

import { default as goal1 } from './assets/img/goal-1.js'
import { default as goal2 } from './assets/img/goal-2.js'
import { default as goal3 } from './assets/img/goal-3.js'
import { default as goal4 } from './assets/img/goal-4.js'
import { default as goal5 } from './assets/img/goal-5.js'
import { default as goal6 } from './assets/img/goal-6.js'
import { default as goal7 } from './assets/img/goal-7.js'
import { default as goal8 } from './assets/img/goal-8.js'
import { default as goal9 } from './assets/img/goal-9.js'
import { default as goal10 } from './assets/img/goal-10.js'
import { default as goal11 } from './assets/img/goal-11.js'
import { default as goal12 } from './assets/img/goal-12.js'
import { default as goal13 } from './assets/img/goal-13.js'
import { default as goal14 } from './assets/img/goal-14.js'
import { default as goal15 } from './assets/img/goal-15.js'
import { default as goal16 } from './assets/img/goal-16.js'
import { default as goal17 } from './assets/img/goal-17.js'

import domainWheel from './assets/img/domain-wheel.svg'

export const mixesIcons = {
  'fe-mix1': mixFilter1,
  'fe-mix2': mixFilter2,
  'fe-mix3': mixFilter3,
  'fe-mix4': mixFilter4,
}

export const mixesDomainRelations = {
  'fe-mix1': ['fe-influence', 'fe-work'],
  'fe-mix2': ['fe-health', 'fe-learning', 'fe-work', 'fe-living'],
  'fe-mix3': ['fe-health', 'fe-learning', 'fe-living'],
  'fe-mix4': ['fe-influence', 'fe-work', 'fe-learning'],
}

export const domainLegendIcons = {
  'fe-health': domainLegendHealth,
  'fe-security': domainLegendSecurity,
  'fe-learning': domainLegendLearning,
  'fe-work': domainLegendWork,
  'fe-living': domainLegendLiving,
  'fe-influence': domainLegendInfluence,
  'fe-life': domainLegendLife,
}

export const domainBanner = {
  'fe-health': domainBannerHealth,
  'fe-security': domainBannerSecurity,
  'fe-learning': domainBannerLearning,
  'fe-work': domainBannerWork,
  'fe-living': domainBannerLiving,
  'fe-influence': domainBannerInfluence,
  'fe-life': domainBannerLife,
}

export const domainIcon = {
  'fe-health': domainIconHealth,
  'fe-security': domainIconSecurity,
  'fe-learning': domainIconLearning,
  'fe-work': domainIconWork,
  'fe-living': domainIconLiving,
  'fe-influence': domainIconInfluence,
  'fe-life': domainIconLife,
}

export const goalIcons = {
  'fe-goal1': goal1,
  'fe-goal2': goal2,
  'fe-goal3': goal3,
  'fe-goal4': goal4,
  'fe-goal5': goal5,
  'fe-goal6': goal6,
  'fe-goal7': goal7,
  'fe-goal8': goal8,
  'fe-goal9': goal9,
  'fe-goal10': goal10,
  'fe-goal11': goal11,
  'fe-goal12': goal12,
  'fe-goal13': goal13,
  'fe-goal14': goal14,
  'fe-goal15': goal15,
  'fe-goal16': goal16,
  'fe-goal17': goal17
}

export { domainWheel };

export const domainWheelPaths = {
  'fe-health': {
    icon: [
      "M236.277,33.9073a13.3647,13.3647,0,0,0-4.553-5.1358A11.0252,11.0252,0,0,0,225.8,27a11.5335,11.5335,0,0,0-9.298,5.0057A11.5586,11.5586,0,0,0,207.204,27a10.9722,10.9722,0,0,0-5.908,1.7715A14.7521,14.7521,0,0,0,195,41.2046a15.8629,15.8629,0,0,0,.597,4.3556,14.4155,14.4155,0,0,0,.824,2.73h3.68l2.631,4.0469,6.619-17.9264L216.341,55.1l.338,1.0077.372,1.1051v.0325l.016-.0487,6.037-16.805,3.422,8.5975h9.073a27.3845,27.3845,0,0,1-2.809,4.6319,62.0361,62.0361,0,0,1-6.893,7.7849,101.1244,101.1244,0,0,1-8.943,7.6874V57.5708a.3391.3391,0,0,1-.097.065.2047.2047,0,0,1-.097.0162.1477.1477,0,0,1-.081-.0162.3157.3157,0,0,1-.209-.1951l-.129-.3738L209.318,36.28l-6.424,17.4226-3.132-4.7945h-3.067a27.3806,27.3806,0,0,0,3.019,5.0545,60.9993,60.9993,0,0,0,6.973,7.8662,100.6776,100.6776,0,0,0,9.508,8.11.3383.3383,0,0,0,.178.0651.3388.3388,0,0,1,.29,0,.2429.2429,0,0,0,.178-.0651,102.34,102.34,0,0,0,9.492-8.11,62.2376,62.2376,0,0,0,6.973-7.8662,28.7562,28.7562,0,0,0,2.987-5.0057c.097-.2113.193-.4226.274-.6338a15.0632,15.0632,0,0,0,.856-2.7792,15.8641,15.8641,0,0,0,.597-4.3557A16.1811,16.1811,0,0,0,236.277,33.9073Zm.532,11.49V45.43a14.2805,14.2805,0,0,1-.92,2.9092h-8.927l-3.874-9.7352-6.118,17.065V32.4446a10.9344,10.9344,0,0,1,8.83-4.8108,10.2034,10.2034,0,0,1,5.569,1.674,14.1255,14.1255,0,0,1,6.005,11.8968A15.9748,15.9748,0,0,1,236.809,45.3977Z"
    ],
    background: "M232,103.1,285.7,60A160.2313,160.2313,0,0,0,160.5,0V68.8A91.5526,91.5526,0,0,1,232,103.1Z",
  },
  'fe-security': {
    icon: [
      "M309.625,140.922a.3092.3092,0,0,0-.016-.207l-8.99-20.115,5.566,1.163.13-.606-19.798-4.128v-.032a2.1972,2.1972,0,0,0-1.915-2.167V110h-.617v4.83a2.2584,2.2584,0,0,0-1.736,1.307l-19.814-4.145-.13.606,5.761,1.196-9.039,20.226a.3.3,0,0,0-.016.208.31.31,0,0,0,.113.318,16.7461,16.7461,0,0,0,2.727,1.626,14.0944,14.0944,0,0,0,13.144,0,16.7066,16.7066,0,0,0,2.726-1.626.3441.3441,0,0,0,.114-.318.312.312,0,0,0-.016-.208l-9.007-20.067,13.275,2.773a1.3739,1.3739,0,0,0-.017.271,2.1974,2.1974,0,0,0,1.915,2.168v29.424H266.492V152h35.604v-3.411H284.618V119.165a2.24,2.24,0,0,0,1.818-1.53l13.485,2.805-9.055,20.275a.2908.2908,0,0,0-.016.207.3113.3113,0,0,0,.113.319,16.7461,16.7461,0,0,0,2.727,1.626,14.0944,14.0944,0,0,0,13.144,0,16.7066,16.7066,0,0,0,2.726-1.626A.3639.3639,0,0,0,309.625,140.922Zm-32.601-7.077H259.79l8.617-19.27Zm7.27-15.286a1.5782,1.5782,0,1,1,1.606-1.578A1.5959,1.5959,0,0,1,284.294,118.559Zm7.335,21.981,8.617-19.271,8.617,19.271Z"
    ],
    background: "M285.7,60l-53.6,43a91.5159,91.5159,0,0,1,20.2,57.4,96.1631,96.1631,0,0,1-2.2,20.2l67.1,15.2a158.7144,158.7144,0,0,0,3.9-35.4A160.5587,160.5587,0,0,0,285.7,60Z",
  },
  'fe-learning': {
    icon: [
      "M283.687,239.187V228.009h-.016L258.844,211,234,228.332l8.044,6.09.049,7.35c0,3.279,1.76,6.364,4.959,8.674a21.5009,21.5009,0,0,0,23.681,0c3.198-2.31,4.959-5.395,4.959-8.674v-7.608l7.398-5.686v10.709a3.86,3.86,0,1,0,4.183,3.845A3.923,3.923,0,0,0,283.687,239.187Zm-8.642,2.601c0,6.397-7.252,11.598-16.169,11.598s-16.169-5.217-16.169-11.614l-.033-6.865,16.17,12.244,16.218-12.486v7.123Zm8.336,4.475a3.231,3.231,0,1,1,3.23-3.231A3.2406,3.2406,0,0,1,283.381,246.263Z"
    ],
    background: "M250,180.7A91.8619,91.8619,0,0,1,200.6,243l30.1,61.9a160.6341,160.6341,0,0,0,86.4-109Z",
  },
  'fe-work': {
    icon: [
      "M164.536,274.023a5.5115,5.5115,0,1,0-5.512-5.512A5.5152,5.5152,0,0,0,164.536,274.023Zm0-10.421a4.91,4.91,0,1,1-4.91,4.909A4.9131,4.9131,0,0,1,164.536,263.602Z",
      "M180.927,294.088c-.586,0-.902.634-.918,1.885v2.17a4.3249,4.3249,0,0,0,.222,1.568.74.74,0,0,0,1.393.031,4.0261,4.0261,0,0,0,.238-1.473v-2.122a4.2873,4.2873,0,0,0-.222-1.552A.77.77,0,0,0,180.927,294.088Z",
      "M173.754,294.088c-.586,0-.903.634-.919,1.885v2.17a4.3249,4.3249,0,0,0,.222,1.568.74.74,0,0,0,1.393.031,4.0261,4.0261,0,0,0,.238-1.473v-2.122a4.2873,4.2873,0,0,0-.222-1.552A.77.77,0,0,0,173.754,294.088Z",
      "M161.369,277.19a5.52,5.52,0,0,0-11.039,0H135v27.794h52.12V277.19Zm-15.948,25.197a6.9525,6.9525,0,1,1,6.952-6.953A6.9518,6.9518,0,0,1,145.421,302.387Zm15.33-25.197a4.91,4.91,0,1,1-4.909-4.909A4.9139,4.9139,0,0,1,160.751,277.19Zm7.159,24.326h-1.869v-6.652l-1.822.634v-1.41l3.5-1.298h.191v8.726Zm8.647-3.611a4.5088,4.5088,0,0,1-.729,2.771,2.702,2.702,0,0,1-4.102.016,4.3933,4.3933,0,0,1-.744-2.66v-1.647a4.5313,4.5313,0,0,1,.713-2.756,2.7052,2.7052,0,0,1,4.117,0,4.4244,4.4244,0,0,1,.729,2.692v1.584Zm7.174,0a4.5088,4.5088,0,0,1-.729,2.771,2.702,2.702,0,0,1-4.102.016,4.3933,4.3933,0,0,1-.744-2.66v-1.647a4.5381,4.5381,0,0,1,.713-2.756,2.7052,2.7052,0,0,1,4.117,0,4.4177,4.4177,0,0,1,.729,2.692v1.584Zm.079-10.294h-7.317v-7.317h7.317Z"
    ],
    background: "M160.5,252.2a91.6666,91.6666,0,0,1-39.9-9.1l-29.9,62a160.891,160.891,0,0,0,140.1-.1l-30.2-62A91.21,91.21,0,0,1,160.5,252.2Z",
  },
  'fe-living': {
    icon: [
      "M51.6668,214.569h21.61l9.3448,12.324H60.8656Zm31.2321,37.876H60.997V227.448H82.8989Zm.5549-25.289v-.059c0-.014-.0146-.029-.0146-.043v-.015c0-.015-.0146-.015-.0146-.029a.0146.0146,0,0,0-.0146-.015v-.014l-9.7829-12.864a.2775.2775,0,0,0-.219-.117H51.0973a.2863.2863,0,0,0-.2044.088L38.0876,226.893,38,227.097v25.626l.2774.277H83.1763a.2769.2769,0,0,0,.2775-.277V227.156Zm-30.02,15.375a.2773.2773,0,0,1-.2774.277H46.9652a.2769.2769,0,0,1-.2774-.277V236.34a.2766.2766,0,0,1,.2774-.277h6.1909a.2769.2769,0,0,1,.2774.277Z",
      "M75.19,236.062H68.9991a.2769.2769,0,0,0-.2774.277v6.191a.2766.2766,0,0,0,.2774.277H75.19a.277.277,0,0,0,.2775-.277v-6.191A.2869.2869,0,0,0,75.19,236.062Z"
    ],
    background: "M71,180.8,4,196A160.5568,160.5568,0,0,0,90.7,305l29.9-61.9A92.1316,92.1316,0,0,1,71,180.8Z",
  },
  'fe-influence': {
    icon: [
      "M38.1421,119.717V113H15.4143v19.333H38.1421v-6.411H51.0353Zm-6.4247,7.625-.8049.805-4.4782-4.493-4.4929,4.493-.8049-.805,4.4929-4.493-4.4929-4.478.8049-.805,4.4929,4.493,4.4929-4.493.8049.805-4.5075,4.493Z",
      "M56.0111,127.562a3.2345,3.2345,0,1,0-3.2342-3.234A3.2353,3.2353,0,0,0,56.0111,127.562Z",
      "M51.1381,128.528a45.8241,45.8241,0,0,1-8.7223-1.595l-1.0684,3.014a47.5523,47.5523,0,0,0,9.7029,2.518h.0585v19.391h3.2489v-9.63h3.2489v9.63h3.3221V128.528Z"
    ],
    background: "M68.8,160.5a91.5854,91.5854,0,0,1,20-57.2L35,60.4A160.3723,160.3723,0,0,0,4,196l67-15.2A96.3852,96.3852,0,0,1,68.8,160.5Z",
  },
  'fe-life': {
    icon: [
      "M89.6053,40.47a2.8181,2.8181,0,1,0-2.818,2.8181A2.8133,2.8133,0,0,0,89.6053,40.47Zm-4.9819,0a2.1639,2.1639,0,1,1,2.1639,2.1639A2.1692,2.1692,0,0,1,84.6234,40.47Z",
      "M105.088,43.0531a2.5,2.5,0,1,0,2.499-2.4994A2.5056,2.5056,0,0,0,105.088,43.0531Zm4.344,0a1.845,1.845,0,1,1-1.845-1.8452A1.84,1.84,0,0,1,109.432,43.0531Z",
      "M125.954,44.7807a1.845,1.845,0,1,0,1.845-1.8452A1.84,1.84,0,0,0,125.954,44.7807Zm3.036,0a1.191,1.191,0,1,1-1.191-1.191A1.1957,1.1957,0,0,1,128.99,44.7807Z",
      "M134.375,46.0892l-.302.1007a20.63,20.63,0,0,1-6.273.8387,32.2924,32.2924,0,0,1-5.435-.6374V38.4738h-.017a47.7775,47.7775,0,0,0,9.763-2.5329l-1.074-3.0361a64.0555,64.0555,0,0,1-13.704,1.8284c-3.556.1006-7.046.1509-9.713.1677-2.667-.0168-6.172-.0671-9.712-.1677a65.3676,65.3676,0,0,1-13.7043-1.8284L83.13,35.9409a48.1035,48.1035,0,0,0,9.7961,2.5329h.05V43.12a33.0135,33.0135,0,0,1-6.2064.4362,49.01,49.01,0,0,1-10.4-1.3923l-.2851-.0839-1.0065,2.902.2852.1174a37.8131,37.8131,0,0,0,7.3135,1.929V62.0247h3.1535v-7.431h1.862v7.431h3.1535V47.0286c.7213-.1174,1.4426-.2516,2.13-.4026V66H96.264V57.8982h3.288V66h3.288V48.9408c.369.0671.738.1342,1.124.2013V62.226h2.834V58.7705h1.544V62.226h2.835V49.1421c.452-.0671.905-.1509,1.341-.2348V66h3.288v-15.03h3.288V66h3.288V48.74a23.5669,23.5669,0,0,0,2.801.6374v9.1586h2.181V56.2544h.889v2.2812h2.18V49.377a24.3559,24.3559,0,0,0,4.378-1.1574l.302-.1175Zm-41.3816-.1341c-.7716.1845-1.5936.3354-2.4323.4529h-.3522V61.3537H88.3637V53.9228h-3.17v7.4309H83.3315V46.408h-.3523a37.2439,37.2439,0,0,1-7.0787-1.7949l.6207-1.7445A51.4366,51.4366,0,0,0,86.77,44.2105a33.5173,33.5173,0,0,0,6.2232-.4361v2.1807Zm19.5246,2.2812c-.536.1175-1.09.2181-1.643.302h-.336V61.5718h-1.543V58.1163h-2.835v3.4555h-1.543V48.5383h-.336c-.486-.0671-.973-.151-1.442-.2516V46.3073a44.9434,44.9434,0,0,0,4.73.3858,30.0186,30.0186,0,0,0,4.948-.3019Zm0-2.5161a27.9883,27.9883,0,0,1-4.931.3187,45.3286,45.3286,0,0,1-4.747-.4026V38.4738h9.662V45.72Zm17.563,3.0529h-.336v9.1251h-.889V55.6169h-2.18v2.2813h-.889V48.7731h-.336a20.3256,20.3256,0,0,1-3.12-.7045V47.0621a31.5768,31.5768,0,0,0,5.418.6207,21.6913,21.6913,0,0,0,6.19-.7716l.285.8219A25.5786,25.5786,0,0,1,130.081,48.7731Z",
      "M117.45,33.525a3.2542,3.2542,0,1,0-3.254-3.2542A3.257,3.257,0,0,0,117.45,33.525Z",
      "M97.925,33.5251a3.2626,3.2626,0,1,0-3.2707-3.2541A3.275,3.275,0,0,0,97.925,33.5251Z"
    ],
    background: "M160.5,68.8V0A160.2732,160.2732,0,0,0,35,60.4l53.8,42.9A91.6736,91.6736,0,0,1,160.5,68.8Z",
  },
}