import Vue from 'vue'
import Vuex from 'vuex'
import api from "../api/giz-jsonapi-client";
import apiSanitizer from "../api/giz-jsonapi-sanitizer";
import { mixesDomainRelations as mixesDomainRelationsByFeid } from '../config.js';

Vue.use(Vuex);

// default store fetched data logic
const storeData = (state, data, objectListName, ListName) => {
  let newObjectList = {},
      newList = [];
  data.forEach(obj => {
    newObjectList[obj.id] = obj;
    newList.push(obj.id)
  });
  state[objectListName] = Object.assign({}, newObjectList);
  state[ListName] = [ ...newList ];
}

// default fetch logic
const fetchData = (commit, apiFucName, storeMutationName, sanitizerFuncName) => {
  return new Promise(resolve => {
    commit('setLoading', true);
    api[apiFucName]()
      .then(data => {
        commit(storeMutationName, data.data.map( (value, index) => apiSanitizer[sanitizerFuncName](value, index, data.included) ) );
        commit('setLoading', false);
        resolve(true);
      })
      .catch(error => {
        console.error(error);
        commit('setLoading', false);
        resolve(false);
      });
  });
}

// map mix domain relations feid to real id
let mixesDomainRelationsById = {};
const mapMixDomainRelations = state => {
  if (state.mixesList.length > 0 && state.domainsList.length > 0) {
    state.mixesList.forEach(mixId => {
      let mixFeid = state.mixes[mixId].feid;
      if (
        Object.prototype.hasOwnProperty.call(mixesDomainRelationsByFeid, mixFeid) &&
        Array.isArray(mixesDomainRelationsByFeid[mixFeid]) &&
        mixesDomainRelationsByFeid[mixFeid].length > 0
      ) {
        mixesDomainRelationsById[mixId] = mixesDomainRelationsByFeid[mixFeid].map(domainFeid => {
          const domain = state.domains[state.domainsList.find(domainId => state.domains[domainId].feid === domainFeid)];
          if (domain) {
            return domain.id;
          }
          console.error('config error domain with feid: ' + domainFeid + ' not found!');
          return '';
        })
      }
    });
  }
}

// taxonomy modul
const taxonomiesModul = {

  state: {
    // mixes
    mixes: {},
    mixesList: [],

    // domains
    domains: {},
    domainsList: [],

    // sdgs
    sdgs: {},
    sdgsList: [],
  },

  mutations: {
    // store mixes
    storeMixes (state, mixes = []) {
      storeData(state, mixes, 'mixes', 'mixesList');
    },

    // store domains
    storeDomains (state, domains = []) {
      storeData(state, domains, 'domains', 'domainsList');
    },

    // store sdgs
    storeSdgs (state, sdgs = []) {
      storeData(state, sdgs, 'sdgs', 'sdgsList');
    },
  },

  actions: {
    fetchMixes({commit,state}) {
      fetchData(commit, 'getPolicyMixes', 'storeMixes', 'mix')
        .then(success => {
          if (success) {
            mapMixDomainRelations(state);
          }
        });
    },
    fetchDomains({commit, state}) {
      fetchData(commit, 'getDomains', 'storeDomains', 'domain')
        .then(success => {
          if (success) {
            mapMixDomainRelations(state);
          }
        });
    },
    fetchSdgs({commit}) {
      fetchData(commit, 'getSdgs', 'storeSdgs', 'sdg');
    }
  },

  getters: {
    mixes: state => state.mixesList.map( id => state.mixes[id] ),
    mix: (state) => (id) => state.mixes[id],
    domains: state => state.domainsList.map( id => state.domains[id] ),
    domain: (state) => (id) => state.domains[id],
    sdgs: state => state.sdgsList.map( id => state.sdgs[id] ),
    sdg: (state) => (id) => state.sdgs[id],
  }
}

// policy modul
const policiesModul = {

  state: {
    policies: {},
    policiesList: [],
  },

  mutations: {
    // store
    storePolicies (state, policies = []) {
      storeData(state, policies, 'policies', 'policiesList');
    },
  },

  actions: {
    fetchPolicies({commit}) {
      fetchData(commit, 'getPolicies', 'storePolicies', 'policy');
    },
  },

  getters: {
    policies: state => state.policiesList.map( id => state.policies[id] ),
    policiesBySdg: (state, getter) => (sdgId) => getter.policies.filter(policy => policy.sdgs.includes(sdgId)),
    policy: (state) => (id) => state.policies[id],
  }
}

// filter modul
const filterModul = {
  state: {
    selectedMixes: [],
    hoveredMixes: [],
    selectedDomains: [],
    hoveredDomains: [],
  },
  mutations: {
    storeSelectedMixes(state, ids = []) {
      state.selectedMixes = ids;
      // select related doamins
      let newDomains = [];
      ids.forEach(id => {
        if (
          Object.prototype.hasOwnProperty.call(mixesDomainRelationsById, id) &&
          Array.isArray(mixesDomainRelationsById[id])
        ) {
          newDomains.push(...mixesDomainRelationsById[id]);
        }
      });
      state.selectedDomains = [...newDomains];
    },
    storeHoveredMixes(state, ids = []) {
      state.hoveredMixes = ids;
    },
    storeSelectedDomains(state, ids = []) {
      state.selectedDomains = ids;
      state.selectedMixes = [];
    },
    storeHoveredDomains(state, ids = []) {
      state.hoveredDomains = ids;
    },
    resetFilter(state) {
      state.selectedMixes = [];
      state.hoveredMixes = [];
      state.selectedDomains = [];
      state.hoveredDomains = [];
    }
  },
  getters: {
    isMixSelected: state => id => {
      return state.selectedMixes.includes(id);
    },
    isMixHovered: state => id => {
      return state.hoveredMixes.includes(id);
    },
    isDomainSelected: state => id => {
      return state.selectedDomains.includes(id);
    },
    isDomainHovered: state => id => {
      return state.hoveredDomains.includes(id);
    },

    hasFilter: state => {
      return state.selectedMixes.length > 0 || state.selectedDomains.length > 0
    },
    hasDomainFilter: state => {
      return state.selectedDomains.length > 0;
    },
    hasMixFilter: state => {
      return state.selectedMixes.length > 0;
    },

    selectedMix (state, getters) {
      if (state.selectedMixes.length > 0) {
        return getters.mix(state.selectedMixes[0]);
      }
      return null;
    },
    selectedDomain: (state, getters) => {
      if (state.selectedDomains.length > 0) {
        return getters.domain(state.selectedDomains[0]);
      }
      return null;
    },
    filteredPolicies: (state, getters) => {
      if (state.selectedMixes.length > 0) {
        return getters.policies.filter(
          // if policy has one selected mix
          policy => state.selectedMixes.reduce(
            (eq, mixId) => policy.policyMixes.includes(mixId) && eq,
            true
          )
        );
      }
      else if (state.selectedDomains.length > 0) {
        return getters.policies.filter(
          // if policy has one selected domain
          policy => state.selectedDomains.reduce(
            (eq, mixId) => policy.domain.includes(mixId) && eq,
            true
          )
        );
      }

      return getters.policies;
    }
  }
}

const modals = [
  'Policy',
  'Domain',
  'Sdg',
  'How',
  'About',
  'Context',
]

const modalVisible = state => {
  return state.modalPolicyVisible ||
    state.modalDomainVisible ||
    state.modalSdgVisible ||
    state.modalHowVisible ||
    state.modalAboutVisible ||
    state.modalContextVisible;
}

const closeAllModal = state => {
  state.modalPolicyVisible = false;
  state.modalDomainVisible = false;
  state.modalSdgVisible = false;
  state.modalAboutVisible = false;
  state.modalHowVisible = false;
  state.modalContextVisible = false;
}

const scrollInOutModal = (state, out = false) => {
  let scrollTo = 0;
  if (out) {
    scrollTo = state.scrollTop;
  } else if(!modalVisible(state)) {
    state.scrollTop = window.pageYOffset || document.documentElement.scrollTop; 
  }
  // see: main.js
  // uses smoothscroll-polyfill
  window.scrollTo({
    top: scrollTo,
    behavior: 'smooth'
  });
}

// should fire after scrollTo window is finished
// const restorLastFocus = state => {
//   if(state.lastFocus !== null) {
//     setTimeout(() => state.lastFocus.focus(), 300);
//   }
//}

const modalModul = {
  state: {
    scrollTop: 0,
    lastFocus: null,

    modalPolicy: '',
    modalPolicyVisible: false,

    modalDomain: '',
    modalDomainVisible: false,

    modalSdg: '',
    modalSdgVisible: false,

    modalAboutVisible: false,

    modalHowVisible: false,
    
    modalContextVisible: false,
  },
  mutations: {
    storeLastFocus(state) {
      state.lastFocus = document.activeElement;
    },
    // close all
    modalCloseAll(state) {
      closeAllModal(state);
      scrollInOutModal(state, true);
      //restorLastFocus(state);
    },

    // generic mutations see below
    //
    // storeModal + MODAL
    // vissibleModal + MODAL
    // toggleModal + MODAL
  },
  getters: {
    isModalVisible: state => {
      return modalVisible(state);
    },

    // generic getters see below
    //
    // isModal + MODAL + Visible
    // modal+ MODAL
  }
}

// create modal mutations: store, visibility and toggle
modals.forEach(modal => {
  const prefixModal = 'modal' + modal;

  // store
  modalModul.mutations['storeModal' + modal] = (state, id = '') => {
    state[prefixModal] = id;
  };
  // visible
  modalModul.mutations['vissibleModal' + modal] = (state, visible = false) => {
    scrollInOutModal(state, !visible);
    closeAllModal(state);
    state[prefixModal + 'Visible'] = visible;
    // if (!visible) {
    //   restorLastFocus(state);
    // }
  };
  // toggle
  modalModul.mutations['toggleModal' + modal] = (state) => {
    const toggle = !state[prefixModal + 'Visible'];
    scrollInOutModal(state, !toggle);
    closeAllModal(state);
    if (toggle) {
      state[prefixModal + 'Visible'] = true;
    } 
    // else {
    //   restorLastFocus(state);
    // }
  };
});

// create modal getters: visibility and content
modals.forEach(modal => {
  const prefixModal = 'modal' + modal;

  // visibility
  modalModul.getters['isModal' + modal + 'Visible'] = state => {
    return state[prefixModal + 'Visible'];
  };

  // content if has
  if (Object.prototype.hasOwnProperty.call(modalModul.state, prefixModal)) {
    modalModul.getters[prefixModal] = (state, getter) => {
      if (state[prefixModal].length > 0) {
        return getter[modal.toLowerCase()](state[prefixModal]);
      }
      return null;
    };
  }
});

// store
export default new Vuex.Store({
  state: {
    loading: false,
    loadingCnt: 0,
  },
  mutations: {
    setLoading (state, value) {
      if (value) {
        state.loading = value;
        state.loadingCnt++;
      } else if (--state.loadingCnt === 0) {
        state.loading = value;
      }
    }
  },
  getters: {
    isLoading: state => {
      return state.loading;
    }
  },
  modules: {
    taxonomy: taxonomiesModul,
    policy: policiesModul,
    filter: filterModul,
    modal: modalModul
  }
})
